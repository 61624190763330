import { Button, Menu, MenuItem } from "@mui/material";
import * as React from "react";
import useTranslations from "../../../hooks/useTranslations";
import classes from "./ActionButtons.module.scss";

const ActionButtons = (props: IActionButtons) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { rcTranslate } = useTranslations();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleItemClick = (menuItem: IMenuItem) => {
    menuItem.onClick && menuItem.onClick();
    handleClose();
  };

  if (props.hide) return <div style={props.style} />;

  return (
    props.menuItems && (
      <div style={props.style}>
        <Button
          id="positioned-button"
          aria-controls={open ? "positioned-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <div className={classes.dots}></div>
          <div className={classes.dots}></div>
          <div className={classes.dots}></div>
        </Button>
        <Menu
          id="positioned-menu"
          aria-labelledby="positioned-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left"
          }}
        >
          {props?.menuItems?.map((menuItem) => {
            return (
              menuItem.show !== false && (
                <MenuItem
                  key={menuItem.label}
                  onClick={() => handleItemClick(menuItem)}
                  style={{ justifyContent: "flex-end" }}
                >
                  {rcTranslate(menuItem.label)}
                </MenuItem>
              )
            );
          })}
        </Menu>
      </div>
    )
  );
};

export default ActionButtons;

export interface IActionButtons {
  menuItems: IMenuItem[];
  style?: any;
  hide?: boolean;
}

export interface IMenuItem {
  label: string;
  onClick?: () => void;
  show?: boolean;
}
