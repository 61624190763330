import { useEffect } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";

import { useUIState } from "../../../hooks/useUIState";
import { IApiError } from "../../../models/api";
import { GeneralActions } from "../../../store/entities/general/general.actions";
import { AuthActions } from "../../../store/entities/auth/auth.actions";
import useTranslations from "../../../hooks/useTranslations";
import FirstLogin from "../../../pages/Account/FirstLogin/FirstLogin";
import Login from "../../../pages/Account/Login/Login";
import ForgotPassword from "../../../pages/Account/ForgotPassword/ForgotPassword";
import ChangePassword from "../../../pages/Account/ChangePassword/ChangePassword";
import ChangeExpiredPassword from "../../../pages/Account/ChangePassword/ChangeExpiredPassword";
import { useAppSelector } from "../../../store";

const Auth = () => {
  const { errorsArray } = useUIState();
  const { enqueueSnackbar } = useSnackbar();
  const { rcTranslate } = useTranslations();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {  error } = useAppSelector((state: any) => state.auth);

  // Show all errors from server
  useEffect(() => {
    let clearErrorsTimeout: any;

    if (errorsArray.length) {
      for (const i of errorsArray as IApiError[]) {
        enqueueSnackbar(
          `${i.message} ${i.code !== -1 ? (`${rcTranslate("errors.errorCode")} ${i.code}`) : ""}`,
          {
            preventDuplicate: true,
            variant: "error",
            autoHideDuration: 5000,
            anchorOrigin: {
              vertical: "top",
              horizontal: "center"
            },
            key: i.message
          }
        );

        clearErrorsTimeout = setTimeout(() => {
          dispatch(GeneralActions.clearErrors(i.message));
          dispatch(AuthActions.clearErrors(i.message));
        }, 5000);
      }
    } else {
      clearTimeout(clearErrorsTimeout);
    }
  }, [errorsArray, enqueueSnackbar, rcTranslate, dispatch]);


   ///Router to change the password component when the password has expired (error code = 9)
   useEffect(() => {

    if (error && error[0] && error[0].code ===9) {
      
      navigate(
        "/change-expired-password"
      );

    }
  }, [error])

  return (
    <Routes>
      <Route path="*" element={<Navigate replace to="/login" />} />
      <Route path="/login" element={<Login />} />
      <Route path="/first-login" element={<FirstLogin />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/change-password" element={<ChangePassword />} />
      <Route path="/change-expired-password" element={<ChangeExpiredPassword />} />
    </Routes>
  );
};
export default Auth;
