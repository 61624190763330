import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Delete, DragIndicator } from "@mui/icons-material";

import { ICell } from "../../../../models/cell";
import { FieldTypes, IFormFields } from "../../../../models/form";
import Form from "../../../../components/Container/Form/Form";
import useTranslations from "../../../../hooks/useTranslations";
import { useAppSelector } from "../../../../store";
import { ConfigurationActions } from "../../../../store/entities/configuration/configuration.actions";
import { cellsToClosetError, CellToCloset, ClosetDetails } from "../../../../store/entities/configuration/type";
import { cellToClosetValidation } from "../../../../utils/formValidation/cellToCloset";

import './ClosetDetails.scss'

const CellToColumn = (props: any) => {
    const { data, selectList, dataList, setData, setMessage, setShowMessage, columnNumber, dnd } = props

    const { rcTranslate } = useTranslations()
    const dispatch = useDispatch()

    const [formikProps, setFormikProps] = useState<any>({});

    const { cellFormations } = useAppSelector((state) => state.configuration);
    const { closetDetails } = useAppSelector((state) => state.configuration);
    const { selectedColumnId } = useAppSelector((state) => state.configuration);
    const { selectedCellId, cellsToClosetErrors } = useAppSelector((state) => state.configuration);

    useEffect(() => {
        if (cellsToClosetErrors?.length && formikProps) {
            const { setFieldError, setFieldTouched } = formikProps
            cellsToClosetErrors?.forEach((error: cellsToClosetError) => {
                if (error.rowNumber === data?.id && error?.setNumber === columnNumber) {
                    if (setFieldError && setFieldTouched) {

                        (async () => {
                            if (error.errors.position) {
                                await setFieldTouched("position", true)
                                await setFieldError("position", error.errors.position)
                            }
                            if (error.errors.title) {
                                await setFieldTouched("title", true)
                                await setFieldError("title", error.errors.title)
                            }
                        })()


                    }
                }
            })
            dispatch(ConfigurationActions.cellToClosetErrors([]))

        }
    }, [cellsToClosetErrors, formikProps.values])

    let fields: IFormFields = {
        initialValues: {
            id: data?.id ?? "",
            title: data?.title ?? "",
            position: data?.position ?? "",
            height: data?.height ?? "",
            width: data?.width ?? "",
        },
        formData: [
            {
                type: FieldTypes.AUTOCOMPLETE,
                id: "title",
                label: "configuration.closetConfiguration.children.fieldNames.title",
                items: selectList,
                className: "closettDetails__list",
                props: { multiple: false }
            },
            {
                type: FieldTypes.TEXT,
                id: "position",
                label: "configuration.closetConfiguration.children.fieldNames.position",
            },
            {
                type: FieldTypes.FORM_CONTROL,
                id: "height",
                label: "configuration.commonFieldNames.height",

            },
            {
                type: FieldTypes.FORM_CONTROL,
                id: "width",
                label: "configuration.commonFieldNames.width",
            },
        ]
    };


    //// update closet details
    useEffect(() => {

        if (formikProps?.values && closetDetails) {
            const { values } = formikProps
            let closet: ClosetDetails = { ...closetDetails }
            let cellsList: CellToCloset[] = [...closet?.cellFormations]
            let index = cellsList?.findIndex((cell: CellToCloset) => cell?.rowNumber === values?.id && cell?.setNumber === columnNumber)
            if (index !== -1) {
                cellsList[index] = {
                    cellFormationId: values.title,
                    setNumber: cellsList[index].setNumber,
                    rowNumber: cellsList[index].rowNumber,
                    columnNumber: 0,
                    position: values?.position,
                }
            }
            else {
                cellsList.push(
                    {
                        cellFormationId: values.title,
                        setNumber: columnNumber,
                        rowNumber: dataList?.length - 1,
                        columnNumber: 0,
                        position: values?.position,
                    }
                )
                closet.setCount = columnNumber === closet.setCount ? columnNumber + 1 : closet.setCount
            }
            closet.cellFormations = cellsList

            dispatch(ConfigurationActions.updateClosetDetails(closet));
        }

    }, [formikProps?.values?.title, formikProps?.values?.position])


    ///Check that there is no deviation from the height and width of the closet
    useEffect(() => {
        if (formikProps?.values?.title) {
            let totalWidth = 0, ExceptionInHeight = false, totalSetHeight = 0, totalSetWidth = 0
            if (closetDetails) {

                let closet: ClosetDetails = { ...closetDetails }

                for (let index = 0; index < closetDetails?.setCount; index++) {

                    /////Check the total width and height of the cells are the same as the closet
                    closet?.cellFormations?.forEach((cell: CellToCloset) => {
                        const cellDetails = cellFormations.find((c: ICell) => c?.id === cell?.cellFormationId)

                        if (cell?.setNumber === index) {
                            totalSetHeight += cellDetails?.height ?? 0
                            if (totalSetWidth === 0) {
                                totalSetWidth += cellDetails?.width ?? 0
                            }
                        }
                    })
                    if (!ExceptionInHeight) ExceptionInHeight = totalSetHeight > Number(closet?.height)
                    totalWidth += totalSetWidth
                    totalSetHeight = totalSetWidth = 0
                }
                if (ExceptionInHeight && totalWidth > Number(closet?.width)) {
                    setMessage(rcTranslate("configuration.closetConfiguration.children.exceedingHeightAndWidth"))
                    setShowMessage(true)
                }
                else {
                    if (totalWidth > Number(closet?.width)) {
                        setMessage(rcTranslate("configuration.closetConfiguration.children.exceedingWidth"))
                        setShowMessage(true)
                    }
                    else {
                        if (ExceptionInHeight) {
                            setMessage(rcTranslate("configuration.closetConfiguration.children.exceedingHeight"))
                            setShowMessage(true)
                        }
                        else
                            setShowMessage(false)
                    }
                }
            }

        }
    }, [closetDetails])

    const updateCellDetails = async (values: ICell, setSubmitting: (value: boolean) => void) => {
    };

    const getFormikProps = useCallback((values: any) => {
        setFormikProps(values);
    }, []);

    const deleteCell = () => {
        if (closetDetails) {
            let closet: ClosetDetails = { ...closetDetails }
            closet.cellFormations = closet?.cellFormations?.filter((cell: CellToCloset) => (cell?.rowNumber !== data?.id || cell?.setNumber !== columnNumber))
            closet.cellCount -= 1
            closet?.cellFormations?.forEach((cell: CellToCloset) => {
                if (data && (cell?.rowNumber > data?.id))
                    cell.rowNumber -= 1
            })
            dispatch(ConfigurationActions.updateClosetDetails(closet));
        }
    }

    //// Highlights the selected cell
    const showSelectedCell = () => {
        selectedColumnId && dispatch(ConfigurationActions.updateSelectedColumnId(null));
        if (selectedCellId?.columnNumber !== columnNumber || selectedCellId?.rowNumber !== data?.id)
            dispatch(ConfigurationActions.updateSelectedCellId({ columnNumber: columnNumber, rowNumber: data?.id }))
    }

    return (
        <div key={data?.id} onClick={showSelectedCell} className="closettDetails__closettWrapper">

            <div
                className={
                    dataList?.length === 1 ?
                        "closettDetails__disabledDnd closettDetails__dnd" :
                        "closettDetails__dnd"
                }>
                {dnd &&
                    <span {...dnd}>
                        <DragIndicator />
                    </span>
                }
            </div>

            <Form
                fields={fields}
                formValidation={cellToClosetValidation}
                onSubmit={updateCellDetails}
                submitButtonTitle="button.save"
                isDisabled={true}
                showSubmitButton={false}
                getFormikProps={getFormikProps}
                formClassName="closettDetails__form"
            />

            <Delete className="closettDetails__deleteCell" onClick={deleteCell} />

        </div>
    );
};

export default CellToColumn;