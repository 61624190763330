import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { ExpandMore } from "@mui/icons-material";

import { ITableRow } from "../../../models/table";
import { TableActions } from "../../../store/entities/table/table.actions";
import StatusDot from "../StatusDot/StatusDot";

import "./TableRow.scss";
import { OrdersActions } from "../../../store/entities/orders/orders.actions";

const TableRow = ({
  expandable = false,
  row,
  rowId,
  expanded,
  setExpanded,
  selectionModel = [],
  setSelectionModel,
  checkboxSelection = true,
  showDots = false,
  selected,
  tdWidths,
  rowsExpendedContent,
  hideFooter,
  ...props
}: ITableRow) => {
  const [isChecked, setIsChecked] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    selected !== undefined && setIsChecked(selected);
  }, [selected]);

  const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;

    setIsChecked(checked);

    let newSelectionModel = [...selectionModel];

    const selectedRowIndex = newSelectionModel.findIndex((i) => i === rowId);

    if (selectedRowIndex > -1) {
      newSelectionModel.splice(selectedRowIndex, 1);
    } else {
      newSelectionModel = [...newSelectionModel, rowId];
    }

    dispatch(TableActions.setSelectedList(newSelectionModel));
  };

  if (checkboxSelection && showDots) {
    const newRow = Object.entries(row);

    newRow.splice(2, 0, [
      "checkbox",
      <Checkbox checked={isChecked} onChange={handleCheckChange} />,
    ]);

    row = Object.fromEntries(newRow);
  } else if (checkboxSelection && !showDots) {
    row = {
      checkbox: <Checkbox checked={isChecked} onChange={handleCheckChange} />,
      ...row,
    };
  }

  delete row.id;

  const handleChange = (_event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded && setExpanded(isExpanded ? `row-${rowId}` : false);

    if (row.Id) {
      dispatch(OrdersActions.setOrdersData({ orderExtendedDetails: null }));

      dispatch(
        TableActions.setTableData({
          expendedRowId: isExpanded ? row?.Id : null,
        })
      );
    }
  };

  if (!expandable) {
    return (
      <div
        className={`tableRow ${
          checkboxSelection && showDots
            ? "tableRow__checkboxVisible__showDots"
            : checkboxSelection
            ? "tableRow__checkboxVisible"
            : showDots
            ? "tableRow__showDots"
            : ""
        }`}
        id={rowId.toString()}
      >
        {Object.keys(row)?.map((item: any, index: number) => (
          <div
            key={index}
            style={{ flex: tdWidths && tdWidths[index] ? tdWidths[index] : "" }}
          >
            {row[item]}
          </div>
        ))}
      </div>
    );
  }

  return (
    <Accordion
      className={`tableRowExpandable ${
        checkboxSelection && showDots
          ? "tableRowExpandable__checkboxVisible__showDots"
          : checkboxSelection
          ? "tableRowExpandable__checkboxVisible"
          : showDots
          ? "tableRowExpandable__showDots"
          : ""
      }`}
      expanded={expanded === `row-${rowId}`}
      onChange={handleChange}
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        {Object.keys(row)?.map((item: any, index: number) => (
          <div
            key={index}
            style={{ flex: tdWidths && tdWidths[index] ? tdWidths[index] : "" }}
            className=""
          >
            {row[item]}
          </div>
        ))}
      </AccordionSummary>
      <AccordionDetails>
        {rowsExpendedContent &&
          rowsExpendedContent.length &&
          rowsExpendedContent[rowId]}
      </AccordionDetails>
    </Accordion>
  );
};

export default TableRow;
