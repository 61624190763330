import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import { useAppSelector } from "./store";
import { useUIState } from "./hooks/useUIState";
import { useRefreshToken } from "./hooks/useRefreshToken";
import MainPage from "./components/UI/MainPage/MainPage";
import Loader from "./components/UI/Loader/Loader";
import AlertStack from "./components/UI/AlertStack/AlertStack";
import Auth from "./components/Container/Auth/Auth";

import "./assets/styles/index.scss";
import "./App.css";



function App() {
  const { isLoading } = useUIState();
  const { checkRefreshTokenExp } = useRefreshToken();

  const { isLoggedIn } = useAppSelector((state: any) => state.auth);

  useEffect(() => {
    let refreshTokenInterval: any;

    // Check if token is valid every 10 seconds
    refreshTokenInterval = setInterval(() => checkRefreshTokenExp(), 10000);

    return () => {
      clearInterval(refreshTokenInterval);
    };
  }, [checkRefreshTokenExp]);


 

  return (
    <AlertStack>
      <div className="App">
        {isLoading && <Loader />}
        <Routes>

          {isLoggedIn && <Route path={"*"} element={<MainPage />} />}
          {!isLoggedIn &&<Route path={"*"} element={<Auth />} />}
        </Routes>
      </div>
    </AlertStack>
  );
}

export default App;
