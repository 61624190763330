import { mixed, string, object, number,boolean } from "yup";


export const LockerSearchValidation = object().shape({
    siteId:number().nullable(true) ,
    compoundId:number().nullable(true) ,
    cellNumber:number().typeError("יש להזין מספרים בלבד") ,
    cellNickName:string() ,
    closetNumber: number().typeError("יש להזין מספרים בלבד"),
    cellStatus: number().nullable(true),
    cellFormationId: number().nullable(true),
    isAccessible: boolean(),
    fromPairingDate:mixed().test(
        "check fromPairingDate value",
        function (value) {
          const toPairingDate = this.parent.toPairingDate;
    
          if (!value) return true;
    
          return new Date(value) > new Date(toPairingDate)
            ? this.createError({
                message: "טווח התאריכים שגוי",
                path: "fromPairingDate"
              })
            : true;
        }
      ),
    toPairingDate:mixed().test(
        "check toPairingDate value",
        function (value) {
          const fromPairingDate = this.parent.fromPairingDate;
    
          if (!value) return true;
    
          if (fromPairingDate) {
            return new Date(value) < new Date(fromPairingDate)
              ? this.createError({
                  message: "טווח התאריכים שגוי",
                  path: "toPairingDate"
                })
              : true;
          }
    
          return true;
        })
})
