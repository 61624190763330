import { useState, useEffect, useCallback, ChangeEvent } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import bwipjs from "bwip-js";

import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { useAppSelector } from "../../../store";
import { SitesActions } from "../../../store/entities/sites/sites.actions";
import { LockersActions } from "../../../store/entities/lockers/lockers.actions";
import Dialog from "../../../components/UI/Dialog/Dialog";
import useTranslations from "../../../hooks/useTranslations";
import useGetGridData from "../../../hooks/useGetGridData";
import RcTable from "../../../components/UI/RcTable/RcTable";
import { GridColDef, GridRowsProp } from "@mui/x-data-grid";
import ActionButtons from "../../../components/UI/ActionButtons/ActionButtons";
import { IGridMode, ISortItem } from "../../../models/table";
import {
  getDateAndTime,
  getHebrewDate,
  sortDataByDate,
} from "../../../utils/formatters";
import useUrlSearchParam from "../../../hooks/useUrlSearchParams";
import { FieldTypes, IFormFields } from "../../../models/form";
import {
  IAutoCompleteItem,
  IAutoCompleteValue,
} from "../../../models/autoComplete";
import { ISite } from "../../../models/site";
import {
  ICity,
  ICompound,
  IDynamicList,
} from "../../../store/entities/general/type";
import Filters from "../../../components/Container/Filters/Filters";
import { CONSTANTS } from "../../../utils/constants";
import Title from "../../../components/UI/Title/Title";
import { GeneralActions } from "../../../store/entities/general/general.actions";
import { ConfigurationActions } from "../../../store/entities/configuration/configuration.actions";
import { ILocker } from "../../../store/entities/lockers/type";
import { ICell } from "../../../models/cell";
import TableButtons from "../../../components/UI/TableButtons/TableButtons";
import AutoComplete from "../../../components/Container/InputFields/AutoComplete/AutoComplete";
import Alert from "../../../components/UI/Alert/Alert";
import TextInput from "../../../components/UI/TextInput/TextInput";
import { LockerSearchValidation } from "../../../utils/formValidation/lockersManagement";
import { IPage } from "../../../models/page";

import "./ManageLockers.scss";
import { TableActions } from "../../../store/entities/table/table.actions";

const ManageLockers = ({ permission, nextRoute }: IPage) => {
  const [rows, setRows] = useState<GridRowsProp>([]);
  const [rowsExpendedContent, setRowsExpendedContent] = useState<any[]>([]);
  const [printIndexes, setPrintIndexes] = useState<number[]>([]);
  const [siteList, setSiteList] = useState<IAutoCompleteItem[]>([]);
  const [siteCompoundList, setSiteCompoundList] = useState<IAutoCompleteItem[]>(
    []
  );
  const [statusList, setStatusList] = useState<IAutoCompleteItem[]>([]);
  const [cellList, setCellList] = useState<IAutoCompleteItem[]>([]);
  const [formikProps, setFormikProps] = useState<any>({});
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [showNote, setShowNote] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [selectedStatus, setSelectedStatus] =
    useState<IAutoCompleteValue>(null);
  const [descriptionValue, setDescriptionValue] = useState<string>("");

  const dispatch = useDispatch();
  const { rcTranslate } = useTranslations();
  const { onPageChange } = useGetGridData();
  const { getValueFromQuery, updateParams, params } = useUrlSearchParam();
  const navigate = useNavigate();

  const { queryParams, selectedList, nextPageNumber ,currentPage} = useAppSelector(
    (state) => state.table
  );
  const { siteListDetails } = useAppSelector((state) => state.sites);
  const { lockers, isLoading } = useAppSelector((state) => state.lockers);

  const {
    appLanguage,
    cities,
    siteCompounds,
    compoundNames,
    compoundsDetails,
    lockerStatuses,
  } = useAppSelector((state) => state.general);
  const { cellFormations } = useAppSelector((state) => state.configuration);

  let showAlertTimeout: any;

  useEffect(() => {
    if (permission !== undefined && !permission) {
      navigate(nextRoute);
    }
  }, [permission, nextRoute, navigate]);

  useEffect(() => {
    return () => {
      clearTimeout(showAlertTimeout);
      setShow(false);
      setShowDialog(false);
    };
  }, [showAlertTimeout, dispatch]);

  useEffect(() => {
    if (lockers?.length) {
      let compoundsIds = lockers.map((locker: ILocker) => {
        return locker?.compoundId;
      });
      dispatch(
        GeneralActions.getCompoundsByIds(Array.from(new Set(compoundsIds)))
      );
    }
  }, [lockers]);

  useEffect(() => {
    if (selectedList?.length && showNote) {
      setShowNote(false);
    }
  }, [selectedList]);

  useEffect(() => {
    dispatch(LockersActions.getLockers(params));
    if (currentPage !== 1) {
      dispatch(TableActions.setTableData({ currentPage: 1 }));
    }
  }, [dispatch, params]);

  useEffect(() => {
    if (nextPageNumber) {
      const getData = async () => {
        await dispatch(
          LockersActions.getLockers(undefined, undefined, false, nextPageNumber)
        );
        await dispatch(TableActions.setTableData({ nextPageNumber: null }));
      };
      getData();
    }
  }, [nextPageNumber]);

  useEffect(() => {
    dispatch(SitesActions.getSiteListDetails());
    dispatch(GeneralActions.getCities());
    dispatch(GeneralActions.getLockerStatuses());
    dispatch(ConfigurationActions.getCellsLists());
  }, [dispatch]);

  useEffect(() => {
    if (lockerStatuses.length) {
      const newStatuses: IAutoCompleteItem[] = lockerStatuses.map(
        (status: IDynamicList) => ({
          id: status.id,
          label: appLanguage === "hebrew" ? status.nameHE : status.nameEN ?? "",
        })
      );

      setStatusList(newStatuses);
    }
  }, [appLanguage, lockerStatuses]);

  useEffect(() => {
    if (cellFormations.length) {
      const newCells: IAutoCompleteItem[] = cellFormations.map(
        (cell: ICell) => ({
          id: Number(cell.id),
          label: appLanguage === "hebrew" ? cell.title : "",
        })
      );

      setCellList(newCells);
    }
  }, [appLanguage, cellFormations]);

  // Get extended row data
  useEffect(() => {
    if (!lockers.length) return setRowsExpendedContent([]);

    const extendedContent = lockers?.map((locker: ILocker, index: number) => {
      const cellName = () => {
        let cell = "";

        if (cellFormations.length && locker) {
          const foundCell = cellFormations?.find(
            (cell: ICell) => locker.cellFormationId === cell.id
          );
          if (foundCell) {
            return appLanguage === "hebrew" ? foundCell.title : "";
          }
        }
        return cell;
      };

      const contentData = [
        {
          title: "lockers.manageLockers.cell",
          data: [{ value: cellName() }],
        },
        {
          title: "lockers.manageLockers.filters.isAccessible.label",
          data: [
            { value: locker?.isAccessible ? <CheckIcon /> : <CloseIcon /> },
          ],
        },
        {
          title: "lockers.manageLockers.reason",
          data: [{ value: "" }],
        },
      ];
      return (
        <div key={index} className="manageOrders__extendedContentWrapper">
          {contentData.map(
            ({
              title,
              data,
              show,
            }: {
              title?: string;
              data: { label?: string; value: any }[];
              show?: boolean;
            }) => (
              <div key={title}>
                {/* Check on every item if there is a show condition, and if it returns true then show item */}
                {(show === undefined || show) && (
                  <div className="manageOrders__extendedContent">
                    <div className="manageOrders__extendedContentTitle">
                      {rcTranslate(title as string)}
                    </div>

                    {data.map(
                      (
                        { label, value }: { label?: string; value: any },
                        index: number
                      ) => (
                        <div
                          key={index}
                          className="manageOrders__extendedContentDataWrapper"
                        >
                          {/* Check if value is a function or not and give the correct className */}
                          {
                            <div
                              className={
                                ["number", "string"].includes(typeof value)
                                  ? "manageOrders__extendedContentValue"
                                  : "manageOrders__extendedContentValueLink"
                              }
                            >
                              {["number", "string"].includes(typeof value)
                                ? rcTranslate(value)
                                : value}
                            </div>
                          }
                        </div>
                      )
                    )}
                  </div>
                )}
              </div>
            )
          )}
        </div>
      );
    });

    setRowsExpendedContent(extendedContent);
  }, [appLanguage, lockers, cellFormations, rcTranslate, dispatch]);

  const columns: GridColDef[] = [
    {
      field: "cellNumber",
      headerName: rcTranslate("lockers.manageLockers.filters.cellNumber"),
    },
    {
      field: "nickName",
      headerName: rcTranslate("lockers.manageLockers.filters.cellNickName"),
    },
    {
      field: "closetNumber",
      headerName: rcTranslate("lockers.manageLockers.filters.closetNumber"),
    },
    {
      field: "cityId",
      headerName: rcTranslate("lockers.manageLockers.filters.cityId"),
    },
    {
      field: "siteId",
      headerName: rcTranslate("lockers.manageLockers.filters.siteId"),
    },
    {
      field: "compoundId",
      headerName: rcTranslate("lockers.manageLockers.filters.compoundId"),
    },
    {
      field: "lockCaseId",
      headerName: rcTranslate("lockers.manageLockers.filters.lockCaseId"),
    },
    {
      field: "pairingDate",
      headerName: rcTranslate("lockers.manageLockers.filters.pairingDate"),
      sortComparator: sortDataByDate,
    },
    {
      field: "cellStatus",
      headerName: rcTranslate("lockers.manageLockers.filters.cellStatus"),
    },
    {
      field: "actions",
      headerName: "",
      sortable: false,
      headerClassName: "hideColumnSeperator",
    },
  ];

  const navigateToOderManagement = useCallback(
    (cellNumber: string) => {
      updateParams({ cellNumber });

      navigate(
        "/" +
          CONSTANTS.ROUTES.ORDERS_MANAGEMENT.BASE +
          "/" +
          CONSTANTS.ROUTES.ORDERS_MANAGEMENT.CHILDREN.ORDERS_LIST +
          "/?cellNumber=" +
          cellNumber
      );
    },
    [navigate]
  );
  const navigateToChangeLock = useCallback(
    (siteId: string) => {
      // navigate(
      //   "/" +
      //     CONSTANTS.ROUTES.SITES_MANAGEMENT.BASE +
      //     "/" +
      //     CONSTANTS.ROUTES.SITES_MANAGEMENT.CHILDREN.site_DETAILS +
      //     "/" +
      //     siteId
      // );
    },
    [navigate]
  );
  const navigateToSendMessage = useCallback(
    (siteId: string) => {
      // navigate(
      //   "/" +
      //     CONSTANTS.ROUTES.SITES_MANAGEMENT.BASE +
      //     "/" +
      //     CONSTANTS.ROUTES.SITES_MANAGEMENT.CHILDREN.site_DETAILS +
      //     "/" +
      //     siteId
      // );
    },
    [navigate]
  );

  useEffect(() => {
    if (!lockers?.length) return setRows([]);

    const lockersRows = lockers.map((locker, index: number) => {
      const lockerCity = () => {
        let city = "";

        if (cities.length && locker) {
          const foundCity = cities?.find(
            (city: ICity) => locker.cityId === city.id
          );

          if (foundCity) {
            return appLanguage === "hebrew"
              ? foundCity.nameHE
              : foundCity.nameEN;
          }
        }

        return city;
      };

      const lockerSite = () => {
        let site = "";

        if (siteListDetails.length && locker) {
          const foundSite = siteListDetails?.find(
            (site: ISite) => locker.siteId === site.id
          );

          if (foundSite) {
            const siteName =
              appLanguage === "hebrew"
                ? foundSite.nameHE
                : foundSite.nameEN ?? "";

            return `${foundSite.id} - ${siteName}`;
          }
        }

        return site;
      };

      const lockerCompound = () => {
        if (!locker.compoundId) return "";

        if (compoundsDetails?.length && locker) {
          const foundCompound = compoundsDetails?.find(
            (item: IDynamicList) => locker.compoundId === item.id
          );

          if (foundCompound) {
            return appLanguage === "hebrew"
              ? foundCompound.nameHE
              : foundCompound.nameEN ?? "";
          }
        }

        return "";
      };

      const lockerStatus = () => {
        if (!locker.cellStatus) return "";

        if (lockerStatuses?.length && locker) {
          const foundStatus = lockerStatuses?.find(
            (item: IDynamicList) => locker.cellStatus === item.id
          );

          if (foundStatus) {
            return appLanguage === "hebrew"
              ? foundStatus.nameHE
              : foundStatus.nameEN ?? "";
          }
        }

        return "";
      };

      const lockerLock = () => {
        return locker?.lockCaseId ?? "";
      };

      let lockerObject = {
        id: index,
        cellNumber: locker?.cellNumber ?? "",
        nickName: locker.nickName ?? "",
        closetNumber: locker?.closetNumber,
        cityId: lockerCity(),
        siteId: lockerSite(),
        compoundId: lockerCompound(),
        lockCaseId: lockerLock(),
        pairingDate: locker.pairingDate && getDateAndTime(locker.pairingDate),
        cellStatus: lockerStatus(),
        actions: (
          <ActionButtons
            menuItems={[
              {
                label: "lockers.manageLockers.oderManagement",
                onClick: () =>
                  navigateToOderManagement(locker?.cellNumber?.toString()),
              },
              {
                label: "lockers.manageLockers.changeLock",
                onClick: () => navigateToChangeLock(locker?.id?.toString()),
              },
              {
                label: "lockers.manageLockers.sendMessage",
                onClick: () => navigateToSendMessage(locker?.id?.toString()),
              },
            ]}
          />
        ),
      };

      return lockerObject;
    });

    setRows(lockersRows);
  }, [
    appLanguage,
    cities,
    siteListDetails,
    lockers,
    lockerStatuses,
    compoundsDetails,
    navigateToOderManagement,
    navigateToChangeLock,
    navigateToSendMessage,
    rcTranslate,
  ]);

  useEffect(() => {
    if (siteListDetails?.length) {
      siteListDetails.sort((a, b) => (a.id > b.id ? 1 : -1));
      const newSites: IAutoCompleteItem[] = siteListDetails.map((site: ISite) => ({
        id: site.id,
        label: `${site.id} - ${
          appLanguage === "hebrew" ? site.nameHE : site.nameEN ?? ""
        }`,
      }));

      setSiteList(newSites);
    }
  }, [appLanguage, siteListDetails]);

  // Get compounds by site after choosing a site in the search
  useEffect(() => {
    const siteId = formikProps?.values?.siteId ?? params?.get("siteId");

    if (siteId) {
      dispatch(GeneralActions.getCompoundNames());
      dispatch(GeneralActions.getCompoundBySite(siteId));
    }
  }, [dispatch, formikProps?.values?.siteId, params?.get("siteId")]);

  // Get the compounds select items of a specific site in the search
  useEffect(() => {
    if (siteCompounds?.length && compoundNames?.length) {
      const foundCompounds: ICompound[] = compoundNames.filter(
        (item) =>
          siteCompounds.find(
            (siteCompound) => siteCompound.compoundNameId === item.id
          ) !== undefined
      );

      const newSiteCompounds: IAutoCompleteItem[] = foundCompounds.map(
        (item) => {
          let compoundId: number | string = "";

          const foundCompound = siteCompounds.find(
            (i) => i.compoundNameId === item.id
          );

          if (foundCompound) {
            compoundId = foundCompound.id;
          }

          return {
            id: compoundId as number,
            label: appLanguage === "hebrew" ? item.nameHE : item.nameEN ?? "",
          };
        }
      );

      setSiteCompoundList(newSiteCompounds);
    }
  }, [appLanguage, siteCompounds, compoundNames]);

  const searchFields: IFormFields = {
    initialValues: {
      siteId: getValueFromQuery("siteId", "number"),
      compoundId: getValueFromQuery("compoundId", "number"),
      cellNumber: getValueFromQuery("cellNumber"),
      cellNickName: getValueFromQuery("cellNickName"),
      closetNumber: getValueFromQuery("closetNumber", "number"),
      cellStatus: getValueFromQuery("cellStatus", "number"),
      cellFormationId: getValueFromQuery("cellFormationId", "number"),
      isAccessible: getValueFromQuery("isAccessible"),
      fromPairingDate: getValueFromQuery("fromPairingDate"),
      toPairingDate: getValueFromQuery("toPairingDate"),
    },
    formData: [
      {
        type: FieldTypes.CHILDREN,
        id: "externalFieldsWrapper",
        fields: [
          {
            type: FieldTypes.AUTOCOMPLETE,
            id: "siteId",
            label: "lockers.manageLockers.filters.siteId",
            items: siteList,
            props: { multiple: false },
          },
          {
            type: FieldTypes.AUTOCOMPLETE,
            id: "compoundId",
            label: "lockers.manageLockers.filters.compoundId",
            items: siteCompoundList,
            props: { multiple: false },
          },
          {
            type: FieldTypes.TEXT,
            id: "cellNumber",
            label: "lockers.manageLockers.filters.cellNumber",
          },
        ],
      },
      {
        type: FieldTypes.CHILDREN,
        id: "extendedFieldsWrapper",
        fields: [
          {
            type: FieldTypes.CHILDREN,
            id: "extendedFieldsContainer",
            fields: [
              {
                type: FieldTypes.CHILDREN,
                id: "extendedFields",
                className: "filters__extendedFields",
                fields: [
                  {
                    type: FieldTypes.TEXT,
                    id: "cellNickName",
                    label: "lockers.manageLockers.filters.cellNickName",
                  },
                  {
                    type: FieldTypes.TEXT,
                    id: "closetNumber",
                    label: "lockers.manageLockers.filters.closetNumber",
                  },
                  {
                    type: FieldTypes.AUTOCOMPLETE,
                    id: "cellStatus",
                    label: "lockers.manageLockers.filters.cellStatus",
                    items: statusList,
                    props: { multiple: false },
                  },
                  {
                    type: FieldTypes.AUTOCOMPLETE,
                    id: "cellFormationId",
                    label: "lockers.manageLockers.filters.cellFormationId",
                    items: cellList,
                    props: { multiple: false },
                  },
                  {
                    type: FieldTypes.SELECT,
                    id: "isAccessible",
                    label: "lockers.manageLockers.filters.isAccessible.label",
                    items: [
                      {
                        key: "true",
                        value: "lockers.manageLockers.filters.isAccessible.yes",
                      },
                      {
                        key: "false",
                        value: "lockers.manageLockers.filters.isAccessible.no",
                      },
                    ],
                  },
                  {
                    type: FieldTypes.DATE,
                    id: "fromPairingDate",
                    label: "lockers.manageLockers.filters.fromPairingDate",
                    props: { getStartOfDay: true },
                  },
                  {
                    type: FieldTypes.DATE,
                    id: "toPairingDate",
                    label: "lockers.manageLockers.filters.toPairingDate",
                    props: {
                      getFullDayTime: true,
                      numOfDays: 1,
                      minDate: new Date(formikProps?.values?.fromPairingDate),
                    },
                  },
                ],
              },
              {
                type: FieldTypes.CHILDREN,
                id: "actionsContainer",
                className: "filters__extendedActions",
                fields: [
                  {
                    type: FieldTypes.BUTTON,
                    id: "closeExtendedSearch",
                    label: "searchFields.closeExtendedSearch",
                  },
                  {
                    type: FieldTypes.CHILDREN,
                    id: "submitButtonWrapper",
                    className: "filters__submitButtonWrapper",
                    fields: [
                      {
                        type: FieldTypes.BUTTON,
                        id: "cleanSearch",
                        label: "searchFields.cleanSearch",
                      },
                      {
                        type: FieldTypes.SUBMIT_BUTTON,
                        id: "submitButton",
                        label: "",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  };

  const handleSortChange = (model: ISortItem[]) => {
    dispatch(LockersActions.getLockers(queryParams, model));
  };

  const getFormikProps = useCallback((values: any) => {
    setFormikProps(values);
  }, []);

  const updateStatus = () => {
    if (selectedList?.length > 0) {
      setShowNote(false);
      setShowDialog(true);
    } else {
      setShowNote(true);
    }
  };

  // Removes canvas wrapper elements before printing codes
  const removeCanvasWrapperChildren = () => {
    let canvasWrapperElements: any = document.getElementsByClassName(
      "manageLockers__cellToPrint"
    );
    canvasWrapperElements = Array.from(canvasWrapperElements);

    if (canvasWrapperElements.length) {
      for (let i = 0; i < canvasWrapperElements.length; i++) {
        canvasWrapperElements[i].parentNode.removeChild(
          canvasWrapperElements[i]
        );
      }
    }
  };

  // Inserts canvas wrapper children before printing codes
  const insertCanvasWrapperChildren = (itemsIndexes: number[]) => {
    if (!itemsIndexes.length) return;

    let canvasWrapperElement: any = document.getElementsByClassName(
      "manageLockers__printComponent"
    );

    if (!canvasWrapperElement) return;

    canvasWrapperElement = Array.from(canvasWrapperElement);

    for (let i = 0; i < itemsIndexes.length; i++) {
      const canvasItem = document.createElement("div");
      canvasItem.className = "manageLockers__cellToPrint";

      const canvasElement = document.createElement("canvas");
      canvasElement.className = "manageLockers__cellToPrint-canvas";
      canvasElement.id = `mycanvas${i}`;

      canvasItem.appendChild(canvasElement);

      canvasWrapperElement[0].appendChild(canvasItem);
    }
  };

  const createCanvasElementsAndPrintCodes = (itemNames: string[]) => {
    removeCanvasWrapperChildren();

    // We save items indexes in order to create DOM canvas elements to insert QR codes inside
    const itemsIndexes = Array.from(Array(itemNames.length).keys());

    insertCanvasWrapperChildren(itemsIndexes);

    setPrintIndexes(itemsIndexes);

    // Sort lockers by locker number
    itemNames.sort((a, b) => {
      const itemA: string = a.replace(/[-]/g, "");
      const itemB: string = b.replace(/[-]/g, "");

      return parseInt(itemA) - parseInt(itemB);
    });

    for (const [index, item] of (itemNames as any).entries()) {
      const bardCodeType = "qrcode";
      const scaleX = 4;
      const height = 20;

      // Text to show below qr code
      let codeText = item.split("-");
      codeText = codeText.length === 3 ? `${codeText[1]}${codeText[2]}` : "";

      try {
        bwipjs.toCanvas(`mycanvas${index}`, {
          bcid: bardCodeType, // Barcode type
          text: item, // Text to encode
          scaleX: scaleX, // 3x scalingX factor
          scaleY: 3, // 3x scalingY factor
          height: height, // Bar height, in millimeters
          textxalign: "center", // Always good to set this
        });
      } catch (err) {
        console.log(err);
      }

      const canvasElement: any = document.getElementById(`mycanvas${index}`);

      if (canvasElement !== null) {
        const txt = document.createElement("span");
        txt.innerHTML = codeText;
        txt.className = "manageClosetWithSites__cellToPrint-text";
        canvasElement.parentNode.appendChild(txt);
      }
    }

    // We need to check if last code was created to the DOM in order for all codes have text underneath
    const lastCanvasCreated = document.getElementById(
      `mycanvas${itemNames.length - 1}`
    );

    if (!lastCanvasCreated) return;

    window.print();
  };

  const printClosetCellsQR = async () => {
    if (!selectedList.length || !lockers.length || typeof window === undefined)
      return;

    let selectedLocksData: any = lockers.filter((_locker, index: number) =>
      (selectedList as number[]).includes(index)
    );

    if (!selectedLocksData.length) return;

    selectedLocksData = selectedLocksData.map((item: ILocker) => ({
      cellNumber: item.cellNumber,
      closetNumber: item.closetNumber,
    }));

    // Get cells names of every selected closet - returns an array of cells names
    let cellsNames: any = await Promise.all(
      selectedLocksData.map(
        (closetData: { cellNumber: number; closetNumber: number }) =>
          closetData.cellNumber
            ? `2-${closetData.closetNumber.toString()}-${closetData.cellNumber
                .toString()
                .slice(closetData.closetNumber.toString().length)}`
            : null
      )
    );

    cellsNames = cellsNames.filter((cellName: any) => cellName !== null);

    if (!cellsNames.length) return;

    cellsNames = [].concat.apply([], cellsNames);

    createCanvasElementsAndPrintCodes(cellsNames);
  };

  const generalButtons = [
    {
      title: "lockers.manageLockers.sendMessage",
      // onClick: () => addCloset(),
      // show: appPermissions.includes(PERMISSIONS.ADD_CLOSET)
    },
    {
      title: "dialogUpdateStatus.updateStatus",
      onClick: () => updateStatus(),
      // show: appPermissions.includes(PERMISSIONS.ADD_CLOSET)
    },
  ];

  const tableButtons = [
    {
      title: "sites.manageClosetsWithSite.printClosetCellsQR",
      onClick: () => printClosetCellsQR(),
      show: true,
      disabled: selectedList.length === 0,
    },
  ];

  const renderStatusDialog = () => {
    return (
      <>
        <Title
          text={
            selectedList?.length === 1
              ? "dialogUpdateStatus.updateStatusNote"
              : "dialogUpdateStatus.updateItemsStatusNote"
          }
          parameters={[{ label: "sumItems", value: selectedList?.length }]}
          className="manageUsers__title"
        />

        <AutoComplete
          items={statusList}
          multiple={false}
          onChange={handleSelectChange}
          label="dialogUpdateStatus.status"
          id="userStatusAutoComplete"
          value={selectedStatus}
        />
        <AutoComplete
          items={statusList}
          multiple={false}
          onChange={handleSelectChange}
          label="lockers.manageLockers.reason"
          id="userStatusAutoComplete"
          value={selectedStatus}
        />

        <TextInput
          id={"text"}
          label={rcTranslate("lockers.manageLockers.description")}
          onChange={handleValueChange}
          value={descriptionValue}
        />
      </>
    );
  };

  const handleSelectChange = (value: IAutoCompleteValue) => {
    setSelectedStatus(value as number);
  };
  const handleValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setDescriptionValue(value);
  };

  const saveUpdateStatus = async () => {};

  const closeDialog = () => {
    setShowDialog(false);
  };

  const shouldExtendFilter: () => boolean = () => {
    let extendedFields: any = searchFields?.formData?.find(
      (formItem) => formItem?.id === "extendedFieldsWrapper"
    );
    if (extendedFields && queryParams) {
      extendedFields = extendedFields.fields[0]?.fields[0]?.fields?.map(
        (field: any) => field.id
      );

      if (extendedFields.length) {
        const foundParams: string[] = extendedFields?.filter((i: string) =>
          Object.keys(queryParams).includes(i)
        );

        if (foundParams?.length) {
          return true;
        }
      }
    }
    return false;
  };

  return (
    <>
      <div className="manageLockers__wrapper">
        <Title text={rcTranslate("lockers.manageLockers.pageTitle")} />

        <Filters
          fields={searchFields}
          filtersValidation={LockerSearchValidation}
          getFormikProps={getFormikProps}
          isExtended={shouldExtendFilter()}
        />

        <TableButtons
          className="manageClosetWithSite_generalButtons"
          generalButtons={generalButtons}
          tableButtons={tableButtons}
        />
        {showNote && (
          <p className="manageLockers__note">
            {rcTranslate("dialogUpdateStatus.note")}
          </p>
        )}

        <RcTable
          rows={rows}
          columns={columns}
          onPageChange={onPageChange}
          handleSortChange={handleSortChange}
          paginationMode={IGridMode.server}
          checkboxSelection={true}
          expandableRows={true}
          rowsExpendedContent={rowsExpendedContent}
          tdWidths={[1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]}
        />

        {showDialog && (
          <Dialog
            title={"dialogUpdateStatus.updateStatus"}
            body={renderStatusDialog()}
            confirmTitle={"button.save"}
            onClose={closeDialog}
            onConfirm={saveUpdateStatus}
          />
        )}

        {show && (
          <Alert
            message={rcTranslate("lockers.manageLockers.updateSuccess")}
            color="success"
          />
        )}
      </div>

      <div id="canvasWrapper" className="manageLockers__printComponent"></div>
    </>
  );
};
export default ManageLockers;
