import { useState, useEffect, useCallback, ChangeEvent } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, Link, useLocation } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";

import { useAppSelector } from "../../../store";
import { OrdersActions } from "../../../store/entities/orders/orders.actions";
import { SitesActions } from "../../../store/entities/sites/sites.actions";
import { GeneralActions } from "../../../store/entities/general/general.actions";
import { CustomersActions } from "../../../store/entities/customers/customers.actions";
import useTranslations from "../../../hooks/useTranslations";
import useGetGridData from "../../../hooks/useGetGridData";
import RcTable from "../../../components/UI/RcTable/RcTable";
import { GridColDef, GridRowsProp } from "@mui/x-data-grid";
import ActionButtons from "../../../components/UI/ActionButtons/ActionButtons";
import StatusDot from "../../../components/UI/StatusDot/StatusDot";
import { IGridMode, ISortItem } from "../../../models/table";
import {
  getDateAndTime,
  getHebrewDate,
  sortDataByDate,
} from "../../../utils/formatters";
import useUrlSearchParam from "../../../hooks/useUrlSearchParams";
import {
  FieldTypes,
  IFormField,
  IFormFields,
  IFormFieldWithChildren,
} from "../../../models/form";
import { IAutoCompleteItem } from "../../../models/autoComplete";
import { ISite } from "../../../models/site";
import {
  ICity,
  ICompound,
  IDynamicList,
  IOrderRegistrationPlatforms,
} from "../../../store/entities/general/type";
import Filters from "../../../components/Container/Filters/Filters";
import { CONSTANTS } from "../../../utils/constants";
import {
  cancelOrderValidation,
  orderListSearchValidation,
} from "../../../utils/formValidation/orderManagment";
import { GetOrderExtendedDetailsPayload } from "../../../store/entities/orders/type";
import Title from "../../../components/UI/Title/Title";
import { PERMISSIONS } from "../../../utils/permissions";
import Dialog from "../../../components/UI/Dialog/Dialog";
import Alert from "../../../components/UI/Alert/Alert";
import Form from "../../../components/Container/Form/Form";
import { lockerReleaseValidation } from "../../../utils/formValidation/lockerReleaseValidation";
import { IPage } from "../../../models/page";
import { TableActions } from "../../../store/entities/table/table.actions";
import TextInput from "../../../components/UI/TextInput/TextInput";

import "./ManageOrders.scss";

const ManageOrders = ({ permission, nextRoute }: IPage) => {
  const [rows, setRows] = useState<GridRowsProp>([]);
  const [rowsExpendedContent, setRowsExpendedContent] = useState<any[]>([]);
  const [siteList, setSiteList] = useState<IAutoCompleteItem[]>([]);
  const [cityList, setCityList] = useState<IAutoCompleteItem[]>([]);
  const [statusList, setStatusList] = useState<IAutoCompleteItem[]>([]);
  const [orderRegistrationPlatformsList, setOrderRegistrationPlatformsList] =
    useState<IAutoCompleteItem[]>([]);
  const [siteCompoundList, setSiteCompoundList] = useState<IAutoCompleteItem[]>(
    []
  );
  const [formikProps, setFormikProps] = useState<any>({});
  const [showLockerReleaseDialog, setShowLockerReleaseDialog] =
    useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [selectedOrder, setSelectedOrder] = useState<any>({});
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [required, setRequired] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [succsessMessage, setSuccessMessage] = useState<string>("");
  const [showCancelOrderDialog, setShowCancelOrderDialog] =
    useState<boolean>(false);
  const [selectedOrderToCancel, setSelectedOrderToCancel] = useState<any>({});
  const [reasonLockerRelease, setReasonLockerRelease] = useState<string>("");
  const [reasonValue, setReasonValue] = useState<string>("");

  const dispatch = useDispatch();
  const { rcTranslate } = useTranslations();
  const { onPageChange } = useGetGridData();
  const { getValueFromQuery, params } = useUrlSearchParam();
  const navigate = useNavigate();
  const location: any = useLocation();

  const { queryParams, expendedRowId, nextPageNumber, currentPage } =
    useAppSelector((state) => state.table);
  const { siteListDetails } = useAppSelector((state) => state.sites);
  const { orders, orderExtendedDetails } = useAppSelector(
    (state) => state.orders
  );
  const { customer } = useAppSelector((state) => state.customers);
  const { appPermissions }: { appPermissions: string[] } = useAppSelector(
    (store) => store.auth
  );

  const {
    appLanguage,
    cities,
    siteCompounds,
    compoundNames,
    orderRegistrationPlatforms,
    compoundsDetails,
    orderStatuses,
  } = useAppSelector((state) => state.general);

  const customerId =
    location?.state?.customerId ?? getValueFromQuery("CustomerId");

  let showAlertTimeout: any;
  const userDetails: any = {};

  useEffect(() => {
    if (permission !== undefined && !permission) {
      navigate(nextRoute);
    }
  }, [permission, nextRoute, navigate]);

  useEffect(() => {
    if (permission) {
      if (currentPage !== 1) {
        dispatch(TableActions.setTableData({ currentPage: 1 }));
      }
      dispatch(OrdersActions.getOrders(params));
    }
  }, [customerId, dispatch, params, permission]);

  useEffect(() => {
    if (nextPageNumber) {
      const getData = async () => {
        await dispatch(
          OrdersActions.getOrders(undefined, undefined, false, nextPageNumber)
        );
        await dispatch(TableActions.setTableData({ nextPageNumber: null }));
      };
      getData();
    }
  }, [nextPageNumber]);

  useEffect(() => {
    if (permission) {
      dispatch(GeneralActions.getOrderRegistrationPlatforms());
      dispatch(SitesActions.getSiteListDetails());
      dispatch(GeneralActions.getCities());
      dispatch(GeneralActions.getOrderStatuses());
    }
  }, [dispatch, permission]);

  useEffect(() => {
    if (customerId) {
      dispatch(CustomersActions.getCustomerDetails(customerId));
    }
  }, [dispatch, customerId]);

  // Remove compound value if there is no site selected
  useEffect(() => {
    if (
      !formikProps?.values?.siteId &&
      formikProps?.values?.compoundId &&
      formikProps?.setFieldValue
    ) {
      formikProps?.setFieldValue("compoundId", null);
    }
  }, [formikProps]);

  const columns: GridColDef[] = [
    {
      field: "dotStatus",
      headerName: "",
      headerClassName:
        "hideColumnSeperator manageOrders__dotStatus manageOrders_columnHeader",
      sortable: false,
    },
    {
      field: "Id",
      headerName: rcTranslate("orders.filters.orderNumber"),
      headerClassName: "hideColumnSeperator manageOrders_columnHeader",
    },
    {
      field: "created",
      headerName: rcTranslate("orders.filters.orderDate"),
      headerClassName: "manageOrders__orderDate manageOrders_columnHeader",
      sortComparator: sortDataByDate,
    },
    {
      field: "cityId",
      headerName: rcTranslate("orders.filters.city"),
      headerClassName: "manageOrders_columnHeader",
    },
    {
      field: "siteId",
      headerName: rcTranslate("orders.filters.site"),
      headerClassName: "manageOrders_columnHeader",
    },
    {
      field: "startDate",
      headerName: rcTranslate("orders.filters.startDate"),
      headerClassName: "manageOrders_columnHeader",
      sortComparator: sortDataByDate,
    },
    {
      field: "endDate",
      headerName: rcTranslate("orders.filters.endDate"),
      headerClassName: "manageOrders_columnHeader",
      sortComparator: sortDataByDate,
    },
    {
      field: "price",
      headerName: rcTranslate("orders.filters.price"),
      headerClassName: "manageOrders_columnHeader",
    },
    {
      field: "lockFee",
      headerName: rcTranslate("orders.filters.orderFee"),
      headerClassName: "manageOrders_columnHeader",
    },
    {
      field: "totalPaid",
      headerName: rcTranslate("orders.filters.totalPrice"),
      headerClassName: "manageOrders_columnHeader",
    },
    {
      field: "orderStatus",
      headerName: rcTranslate("orders.filters.orderStatus"),
      headerClassName: "manageOrders_columnHeader",
    },
    {
      field: "firstAllocationDate",
      headerName: rcTranslate("orders.filters.firstAllocationDate"),
      headerClassName: "manageOrders_columnHeader",
      sortComparator: sortDataByDate,
    },
    {
      field: "orderRegistrationPlatform",
      headerName: rcTranslate("orders.filters.registration"),
      headerClassName: "manageOrders_columnHeader",
    },
    {
      field: "payer",
      headerName: rcTranslate("orders.filters.payer"),
      headerClassName: "manageOrders_columnHeader",
    },
    {
      field: "user",
      headerName: rcTranslate("orders.filters.user"),
      headerClassName: "manageOrders_columnHeader",
    },
    {
      field: "actions",
      headerName: "",
      sortable: false,
      headerClassName: "hideColumnSeperator manageOrders_columnHeader",
    },
  ];

  const columnVisibilityModel = {
    payer: customerId ? true : false,
    user: customerId ? true : false,
  };

  const navigateToShowInvoice = useCallback(
    (siteId: string) => {
      // navigate(
      //   "/" +
      //     CONSTANTS.ROUTES.SITES_MANAGEMENT.BASE +
      //     "/" +
      //     CONSTANTS.ROUTES.SITES_MANAGEMENT.CHILDREN.site_DETAILS +
      //     "/" +
      //     siteId
      // );
    },
    [navigate]
  );
  const navigateToOrderHistory = useCallback(
    (orderId: string, orderDate: string) => {
      navigate(
        "/" +
          CONSTANTS.ROUTES.ORDERS_MANAGEMENT.BASE +
          "/" +
          CONSTANTS.ROUTES.ORDERS_MANAGEMENT.CHILDREN.ORDER_HISTORY +
          "/" +
          orderId,
        { state: { orderDate } }
      );
    },
    [navigate]
  );

  const navigateToLockerDetails = useCallback(
    (siteId: string) => {
      // navigate(
      //   "/" +
      //     CONSTANTS.ROUTES.SITES_MANAGEMENT.BASE +
      //     "/" +
      //     CONSTANTS.ROUTES.SITES_MANAGEMENT.CHILDREN.site_DETAILS +
      //     "/" +
      //     siteId
      // );
    },
    [navigate]
  );
  const navigateToLockerFee = useCallback(
    (siteId: string) => {
      // navigate(
      //   "/" +
      //     CONSTANTS.ROUTES.SITES_MANAGEMENT.BASE +
      //     "/" +
      //     CONSTANTS.ROUTES.SITES_MANAGEMENT.CHILDREN.site_DETAILS +
      //     "/" +
      //     siteId
      // );
    },
    [navigate]
  );

  const orderCancellation = (orderId: number, endDate: string | Date) => {
    setSelectedOrderToCancel((prevState: any) => ({
      ...prevState,
      orderId,
      endDate,
    }));
    setRequired(false);
    setShowCancelOrderDialog(true);
  };

  useEffect(() => {
    if (!orders?.length) return setRows([]);

    const ordersRows = orders.map((order, index: number) => {
      const orderCity = () => {
        let city = "";

        if (cities.length && order) {
          const foundCity = cities?.find(
            (city: ICity) => order.cityId === city.id
          );

          if (foundCity) {
            return appLanguage === "hebrew"
              ? foundCity.nameHE
              : foundCity.nameEN;
          }
        }

        return city;
      };

      const orderSite = () => {
        let site = "";

        if (siteListDetails.length && order) {
          const foundSite = siteListDetails?.find(
            (site: ISite) => order.siteId === site.id
          );

          if (foundSite) {
            const siteName =
              appLanguage === "hebrew"
                ? foundSite.nameHE
                : foundSite.nameEN ?? "";

            return `${foundSite.id} - ${siteName}`;
          }
        }

        return site;
      };

      const orderStatus = () => {
        let status = "";

        if (orderStatuses?.length && order) {
          const foundStatus = orderStatuses?.find(
            (status: IDynamicList) => order.orderStatus === status.id
          );

          if (foundStatus) {
            return appLanguage === "hebrew"
              ? foundStatus.nameHE
              : foundStatus.nameEN ?? "";
          }
        }

        return status;
      };
      let orderObject = {
        id: index,
        status: (
          <StatusDot
            className="tableRow__statusDot"
            status={
              order.orderActivityStatus !== 1 && order.orderStatus === 13004
                ? order.orderStatus
                : order.orderActivityStatus
            }
          />
        ),
        Id: order.id,
        created: order.created && getDateAndTime(order.created),
        cityId: orderCity(),
        siteId: orderSite(),
        startDate: order.startDate && getDateAndTime(order.startDate),
        endDate: order.endDate && getDateAndTime(order.endDate),
        price: order.price,
        lockFee: order.lockFee,
        totalPaid: order.totalPaid,
        orderStatus: orderStatus(),
        firstAllocationDate:
          order.firstAllocationDate &&
          getDateAndTime(order.firstAllocationDate),
        orderRegistrationPlatform:
          order.orderRegistrationPlatform === 6001
            ? rcTranslate("searchFields.registration.website")
            : order.orderRegistrationPlatform === 6002
            ? rcTranslate("searchFields.registration.app")
            : order.orderRegistrationPlatform === 100000007 &&
              rcTranslate("searchFields.registration.migration"),
        actions: (
          <ActionButtons
            // hide={
            //   [PERMISSIONS.RELEASE_CELL].filter( // Add after all actions work
            //     (v: string) => appPermissions.includes(v)
            //   ).length === 0
            // }
            menuItems={[
              // {
              //   label: "orders.manageOrders.rowActions.show_invoice",
              //   onClick: () => navigateToShowInvoice(order?.id?.toString())  // Will be in future version
              // },
              {
                label: "orders.manageOrders.rowActions.order_history",
                onClick: () =>
                  navigateToOrderHistory(
                    order?.id?.toString(),
                    order?.created as string
                  ),
                show: appPermissions.includes(PERMISSIONS.GET_ORDER_HISTORY),
              },
              {
                label: "orders.manageOrders.rowActions.locker_release",
                onClick: () =>
                  lockerRelease(
                    order?.id,
                    order?.compoundId,
                    orderCity(),
                    orderSite(),
                    order?.endDate
                  ),
                show:
                  appPermissions.includes(PERMISSIONS.RELEASE_CELL) &&
                  order?.orderStatus === 13002 &&
                  order?.orderActivityStatus !== 1,
              },
              {
                label: "orders.manageOrders.rowActions.locker_details",
                onClick: () => navigateToLockerDetails(order?.id?.toString()),
              },
              {
                label: "orders.manageOrders.rowActions.update_locker_fee",
                onClick: () => navigateToLockerFee(order?.id?.toString()),
              },
              {
                label: "orders.manageOrders.rowActions.order_cancellation",
                onClick: () => orderCancellation(order?.id, order?.endDate),
                show:
                  appPermissions.includes(PERMISSIONS.ORDER_CANCELLATION) &&
                  order?.orderStatus !== 13004 &&
                  order?.orderActivityStatus !== 1,
              },
            ]}
          />
        ),
      };

      if (customerId) {
        const orderEntries: any = Object.entries(orderObject);

        orderEntries.splice(orderEntries.length - 1, 0, [
          "payer",
          order.payingCustomerId === customerId ? <CheckIcon /> : "",
        ]);
        orderEntries.splice(orderEntries.length - 1, 0, [
          "user",
          order.operatingCustomerId === customerId ? <CheckIcon /> : "",
        ]);

        orderObject = Object.fromEntries(orderEntries) as any;
      }

      return orderObject;
    });
    setRows(ordersRows);
  }, [
    appLanguage,
    cities,
    siteListDetails,
    orders,
    orderStatuses,
    navigateToOrderHistory,
    navigateToLockerDetails,
    navigateToLockerFee,
    rcTranslate,
    customerId,
    orderExtendedDetails,
  ]);

  // Get extended details for the opened order row
  useEffect(() => {
    if (expendedRowId) {
      dispatch(OrdersActions.getOrderExtendedDetails(expendedRowId.toString()));
    }
  }, [dispatch, expendedRowId]);

  // Get extended row data
  useEffect(() => {
    if (!orders.length) return setRowsExpendedContent([]);

    const extendedContent = orders.map((order: any, index: number) => {
      if (order.id !== expendedRowId) return null;

      if (!orderExtendedDetails) return null;

      const payingCustomerDetails = orderExtendedDetails.customersDetails?.find(
        (i) => i.customerId === orderExtendedDetails.payingCustomerId
      );

      const operatingCustomerDetails =
        orderExtendedDetails.customersDetails?.find(
          (i) => i.customerId === orderExtendedDetails.operatingCustomerId
        );
      if (orderExtendedDetails?.cellDetails?.cellNumber) {
        setSelectedOrder((prevState: any) => ({
          ...prevState,
          lockerNumber: orderExtendedDetails?.cellDetails?.cellNumber,
        }));
      }

      const contentData = [
        {
          title: "orders.manageOrders.extendedContent.locker_assignment",
          data: [
            {
              label: "orders.manageOrders.extendedContent.locker_number",
              value: orderExtendedDetails.cellDetails?.cellNumber ?? "—",
            },
            {
              label: "orders.manageOrders.extendedContent.nickname",
              value: orderExtendedDetails.cellDetails?.nickName ?? "—",
            },
          ],
        },
        {
          title: "orders.manageOrders.extendedContent.payment_details",
          data: [
            {
              label: "orders.manageOrders.extendedContent.payment_method",
              value: orderExtendedDetails.paymentDetails.paymentMethod ?? "—",
            },
            {
              label: "orders.manageOrders.extendedContent.token_number",
              value:
                orderExtendedDetails.paymentDetails.PaymentProviderToken ?? "—",
            },
            {
              label: "orders.manageOrders.extendedContent.discount",
              value: orderExtendedDetails.paymentDetails.discount ?? "—",
            },
            {
              label: "orders.manageOrders.extendedContent.discount_reason",
              value: orderExtendedDetails.paymentDetails.discountReason ?? "—",
            },
          ],
        },
        {
          title: "orders.manageOrders.extendedContent.payer_details",
          data: [
            {
              value:
                payingCustomerDetails?.firstName &&
                payingCustomerDetails?.lastName
                  ? payingCustomerDetails?.firstName +
                    " " +
                    payingCustomerDetails?.lastName
                  : payingCustomerDetails?.firstName ?? "—",
            },
            {
              value: payingCustomerDetails?.phoneNumber ?? "—",
            },
            {
              value: !payingCustomerDetails?.customerId ? (
                <Link
                  to={`/${CONSTANTS.ROUTES.CUSTOMERS.BASE}/${CONSTANTS.ROUTES.CUSTOMERS.CHILDREN.CUSTOMER_DETAILS}/${payingCustomerDetails?.customerId}`}
                  onClick={(event) => event.preventDefault()}
                  className="manageorders__disableMoreDetails"
                >
                  {rcTranslate(
                    "orders.manageOrders.extendedContent.more_details"
                  )}
                </Link>
              ) : (
                <Link
                  to={`/${CONSTANTS.ROUTES.CUSTOMERS.BASE}/${CONSTANTS.ROUTES.CUSTOMERS.CHILDREN.CUSTOMER_DETAILS}/${payingCustomerDetails?.customerId}`}
                >
                  {rcTranslate(
                    "orders.manageOrders.extendedContent.more_details"
                  )}
                </Link>
              ),
            },
          ],
          show:
            orderExtendedDetails?.payingCustomerId !== null &&
            orderExtendedDetails?.payingCustomerId !==
              orderExtendedDetails?.operatingCustomerId,
        },
        {
          title: "orders.manageOrders.extendedContent.user_details",
          data: [
            {
              value:
                operatingCustomerDetails?.firstName &&
                operatingCustomerDetails?.lastName
                  ? operatingCustomerDetails?.firstName +
                    " " +
                    operatingCustomerDetails?.lastName
                  : operatingCustomerDetails?.firstName ?? "—",
            },
            {
              value: operatingCustomerDetails?.phoneNumber ?? "—",
            },
            {
              value: !operatingCustomerDetails?.customerId ? (
                <Link
                  to={`/${CONSTANTS.ROUTES.CUSTOMERS.BASE}/${CONSTANTS.ROUTES.CUSTOMERS.CHILDREN.CUSTOMER_DETAILS}/${operatingCustomerDetails?.customerId}`}
                  onClick={(event) => event.preventDefault()}
                  className="manageorders__disableMoreDetails"
                >
                  {rcTranslate(
                    "orders.manageOrders.extendedContent.more_details"
                  )}
                </Link>
              ) : (
                <Link
                  to={`/${CONSTANTS.ROUTES.CUSTOMERS.BASE}/${CONSTANTS.ROUTES.CUSTOMERS.CHILDREN.CUSTOMER_DETAILS}/${operatingCustomerDetails?.customerId}`}
                >
                  {rcTranslate(
                    "orders.manageOrders.extendedContent.more_details"
                  )}
                </Link>
              ),
            },
          ],
          show:
            orderExtendedDetails?.operatingCustomerId !== null &&
            orderExtendedDetails?.payingCustomerId !==
              orderExtendedDetails?.operatingCustomerId,
        },
        {
          title: "orders.manageOrders.extendedContent.payer_and_user_details",
          data: [
            {
              value:
                operatingCustomerDetails?.firstName &&
                operatingCustomerDetails?.lastName
                  ? operatingCustomerDetails?.firstName +
                    " " +
                    operatingCustomerDetails?.lastName
                  : operatingCustomerDetails?.firstName ?? "—",
            },
            {
              value: operatingCustomerDetails?.phoneNumber ?? "—",
            },
            {
              value: !operatingCustomerDetails?.customerId ? (
                <Link
                  to={`/${CONSTANTS.ROUTES.CUSTOMERS.BASE}/${CONSTANTS.ROUTES.CUSTOMERS.CHILDREN.CUSTOMER_DETAILS}/${operatingCustomerDetails?.customerId}`}
                  onClick={(event) => event.preventDefault()}
                  className="manageorders__disableMoreDetails"
                >
                  {rcTranslate(
                    "orders.manageOrders.extendedContent.more_details"
                  )}
                </Link>
              ) : (
                <Link
                  to={`/${CONSTANTS.ROUTES.CUSTOMERS.BASE}/${CONSTANTS.ROUTES.CUSTOMERS.CHILDREN.CUSTOMER_DETAILS}/${operatingCustomerDetails?.customerId}`}
                >
                  {rcTranslate(
                    "orders.manageOrders.extendedContent.more_details"
                  )}
                </Link>
              ),
            },
          ],
          show:
            orderExtendedDetails?.payingCustomerId !== null &&
            orderExtendedDetails?.operatingCustomerId !== null &&
            orderExtendedDetails?.payingCustomerId ===
              orderExtendedDetails?.operatingCustomerId,
        },
        {
          title: "orders.manageOrders.extendedContent.comments",
          data: [
            {
              value: orderExtendedDetails.notes ?? "—",
            },
          ],
        },
      ];

      return (
        <div key={index} className="manageOrders__extendedContentWrapper">
          {contentData.map(
            ({
              title,
              data,
              show,
            }: {
              title?: string;
              data: { label?: string; value: any }[];
              show?: boolean;
            }) => (
              <div key={title}>
                {/* Check on every item if there is a show condition, and if it returns true then show item */}
                {(show === undefined || show) && (
                  <div className="manageOrders__extendedContent">
                    <div className="manageOrders__extendedContentTitle">
                      {rcTranslate(title as string)}
                    </div>

                    {data.map(
                      (
                        { label, value }: { label?: string; value: any },
                        index: number
                      ) => (
                        <div
                          key={index}
                          className="manageOrders__extendedContentDataWrapper"
                        >
                          {label && (
                            <div className="manageOrders__extendedContentLabel">
                              {rcTranslate(label)}
                            </div>
                          )}

                          {/* Check if value is a function or not and give the correct className */}
                          {
                            <div
                              className={
                                ["number", "string"].includes(typeof value)
                                  ? "manageOrders__extendedContentValue"
                                  : "manageOrders__extendedContentValueLink"
                              }
                            >
                              {["number", "string"].includes(typeof value)
                                ? rcTranslate(value)
                                : value}
                            </div>
                          }
                        </div>
                      )
                    )}
                  </div>
                )}
              </div>
            )
          )}
        </div>
      );
    });
    setRowsExpendedContent(extendedContent);
  }, [
    appLanguage,
    dispatch,
    orders,
    rcTranslate,
    expendedRowId,
    orderExtendedDetails,
  ]);

  useEffect(() => {
    if (siteListDetails.length) {
      siteListDetails.sort((a, b) => (a.id > b.id ? 1 : -1));
      const newSites: IAutoCompleteItem[] = siteListDetails.map((site: ISite) => ({
        id: site.id,
        label: `${site.id} - ${
          appLanguage === "hebrew" ? site.nameHE : site.nameEN ?? ""
        }`,
      }));

      setSiteList(newSites);
    }
  }, [appLanguage, siteListDetails]);

  useEffect(() => {
    if (orderRegistrationPlatforms?.length) {
      const newList: IAutoCompleteItem[] = orderRegistrationPlatforms.map(
        (item: IOrderRegistrationPlatforms) => ({
          id: item.id,
          label: appLanguage === "hebrew" ? item.nameHE : item.nameEN ?? "",
        })
      );

      setOrderRegistrationPlatformsList(newList);
    }
  }, [appLanguage, orderRegistrationPlatforms]);

  useEffect(() => {
    if (orderStatuses?.length) {
      const newStatuses = orderStatuses.map((status: IDynamicList) => ({
        id: status.id,
        label: appLanguage === "hebrew" ? status.nameHE : status.nameEN ?? "",
      }));

      setStatusList(newStatuses);
    }
  }, [appLanguage, orderStatuses]);

  useEffect(() => {
    if (cities.length) {
      let newCities: IAutoCompleteItem[] = cities.filter(
        (city: ICity) => city.isActive
      );

      newCities = cities.map((city: ICity) => ({
        id: city.id,
        label: appLanguage === "hebrew" ? city.nameHE : city.nameEN ?? "",
      }));

      setCityList(newCities);
    }
  }, [appLanguage, cities]);

  // Get compounds by site after choosing a site in the search
  useEffect(() => {
    const siteId = formikProps?.values?.siteId ?? params?.get("siteId");

    if (siteId) {
      dispatch(GeneralActions.getCompoundNames());
      dispatch(GeneralActions.getCompoundBySite(siteId));
    }
  }, [dispatch, formikProps?.values?.siteId, params?.get("siteId")]);

  // Get the compounds select items of a specific site in the search
  useEffect(() => {
    if (siteCompounds?.length && compoundNames?.length) {
      const foundCompounds: ICompound[] = compoundNames.filter(
        (item) =>
          siteCompounds.find(
            (siteCompound) => siteCompound.compoundNameId === item.id
          ) !== undefined
      );

      const newSiteCompounds: IAutoCompleteItem[] = foundCompounds.map(
        (item) => {
          let compoundId: number | string = "";

          const foundCompound = siteCompounds.find(
            (i) => i.compoundNameId === item.id
          );

          if (foundCompound) {
            compoundId = foundCompound.id;
          }

          return {
            id: compoundId as number,
            label: appLanguage === "hebrew" ? item.nameHE : item.nameEN ?? "",
          };
        }
      );

      setSiteCompoundList(newSiteCompounds);
    }
  }, [appLanguage, siteCompounds, compoundNames]);

  const searchFields: IFormFields = {
    initialValues: {
      siteId: getValueFromQuery("siteId", "number"),
      compoundId: getValueFromQuery("compoundId", "number"),
      cellNumber: getValueFromQuery("cellNumber"),
      orderId: getValueFromQuery("orderId", "number"),
      cityId: getValueFromQuery("cityId", "number"),
      closetNumber: getValueFromQuery("closetNumber"),
      tokenNumber: getValueFromQuery("tokenNumber"),
      orderRegistrationPlatform: getValueFromQuery(
        "orderRegistrationPlatform",
        "number"
      ),
      dateRangeBy: getValueFromQuery("dateRangeBy") ?? "realizedDate",
      fromDate: getValueFromQuery("fromDate"),
      toDate: getValueFromQuery("toDate"),
      orderStatus: getValueFromQuery("orderStatus", "number"),
      CustomerId: customerId ?? "",
    },
    formData: [
      {
        type: FieldTypes.CHILDREN,
        id: "externalFieldsWrapper",
        fields: [
          {
            type: FieldTypes.AUTOCOMPLETE,
            id: "siteId",
            label: "searchFields.site",
            items: siteList,
            props: { multiple: false },
          },
          {
            type: FieldTypes.AUTOCOMPLETE,
            id: "compoundId",
            label: "searchFields.area",
            items: siteCompoundList,
            props: { multiple: false },
          },
          {
            type: FieldTypes.TEXT,
            id: "cellNumber",
            label: "searchFields.locker_number",
          },
        ],
      },
      {
        type: FieldTypes.CHILDREN,
        id: "extendedFieldsWrapper",
        fields: [
          {
            type: FieldTypes.CHILDREN,
            id: "extendedFieldsContainer",
            fields: [
              {
                type: FieldTypes.CHILDREN,
                id: "extendedFields",
                className: "filters__extendedFields",
                fields: [
                  {
                    type: FieldTypes.TEXT,
                    id: "orderId",
                    label: "searchFields.orderNumber",
                  },
                  {
                    type: FieldTypes.AUTOCOMPLETE,
                    id: "cityId",
                    label: "searchFields.city",
                    items: cityList,
                    props: { multiple: false },
                  },
                  {
                    type: FieldTypes.TEXT,
                    id: "closetNumber",
                    label: "searchFields.lockerNumber",
                  },
                  {
                    type: FieldTypes.TEXT,
                    id: "tokenNumber",
                    label: "searchFields.tokenNumber",
                  },
                  {
                    type: FieldTypes.AUTOCOMPLETE,
                    id: "orderStatus",
                    label: "searchFields.orderStatus",
                    items: statusList,
                    props: { multiple: false },
                  },
                  {
                    type: FieldTypes.AUTOCOMPLETE,
                    id: "orderRegistrationPlatform",
                    label: "searchFields.registration.label",
                    items: orderRegistrationPlatformsList,
                    props: { multiple: false },
                  },
                  {
                    type: FieldTypes.SELECT,
                    id: "dateRangeBy",
                    label: "searchFields.dateRange.label",
                    items: [
                      {
                        key: "0",
                        value: "searchFields.dateRange.firstAllocationDate",
                      },
                      {
                        key: "1",
                        value: "searchFields.dateRange.created",
                      },
                      {
                        key: "2",
                        value: "searchFields.dateRange.expirationDate",
                      },
                    ],
                  },
                  {
                    type: FieldTypes.DATE,
                    id: "fromDate",
                    label: "searchFields.fromCreatedDate",
                    props: { getStartOfDay: true },
                  },
                  {
                    type: FieldTypes.DATE,
                    id: "toDate",
                    label: "searchFields.toCreatedDate.label",
                    props: {
                      getFullDayTime: true,
                      numOfDays: 1,
                      minDate: new Date(formikProps?.values?.fromDate),
                    },
                  },
                ],
              },
              {
                type: FieldTypes.CHILDREN,
                id: "actionsContainer",
                className: "filters__extendedActions",
                fields: [
                  {
                    type: FieldTypes.BUTTON,
                    id: "closeExtendedSearch",
                    label: "searchFields.closeExtendedSearch",
                  },
                  {
                    type: FieldTypes.CHILDREN,
                    id: "submitButtonWrapper",
                    className: "filters__submitButtonWrapper",
                    fields: [
                      {
                        type: FieldTypes.BUTTON,
                        id: "cleanSearch",
                        label: "searchFields.cleanSearch",
                      },
                      {
                        type: FieldTypes.SUBMIT_BUTTON,
                        id: "submitButton",
                        label: "",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  };

  const handleSortChange = (model: ISortItem[]) => {
    dispatch(OrdersActions.getOrders(queryParams, model));
  };

  const getFormikProps = useCallback((values: any) => {
    setFormikProps(values);
  }, []);

  const lockerRelease = async (
    orderId: number,
    compoundId: number,
    cityName: string,
    siteName: string,
    endDate: string | Date
  ) => {
    showMessage && setShowMessage(false);
    await dispatch(GeneralActions.getCompoundsByIds([compoundId]));
    setSelectedOrder((prevState: any) => ({
      ...prevState,
      orderId,
      cityName,
      siteName,
      endDate,
    }));
    setRequired(false);
    setShowLockerReleaseDialog(true);
  };

  const handleValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setReasonLockerRelease(value);
  };

  const renderLockerReleaseDialog = () => {
    let compoundName = "";
    if (compoundsDetails && compoundsDetails[0]) {
      compoundName =
        appLanguage === "hebrew"
          ? compoundsDetails[0].nameHE
          : compoundsDetails[0].nameEN ?? "";
    }

    return (
      <>
        <Title
          text={"orders.lockerRelease.subTitle"}
          parameters={[
            { label: "lockerNumber", value: selectedOrder?.lockerNumber },
          ]}
          className="manageorders__wrapper"
        />

        <div className="manageorders__details">
          <div className="manageorders__city">
            {rcTranslate("orders.lockerRelease.city", [
              { label: "cityName", value: selectedOrder.cityName ?? "" },
            ])}
          </div>
          <div className="manageorders__site">
            {rcTranslate("orders.lockerRelease.site", [
              { label: "siteName", value: selectedOrder.siteName ?? "" },
            ])}
          </div>
          <div className="manageorders__compound">
            {rcTranslate("orders.lockerRelease.compound", [
              { label: "compoundName", value: compoundName ?? "" },
            ])}
          </div>
        </div>

        <TextInput
          id={"text"}
          label={rcTranslate("orders.lockerRelease.reason")}
          onChange={handleValueChange}
          value={reasonLockerRelease}
        />
        {required && (
          <p className="manageorders__requiredFiels">
            {rcTranslate("orders.lockerRelease.required")}
          </p>
        )}

        {showMessage && (
          <p className="manageorders__noteInactiveOrder">{message}</p>
        )}
      </>
    );
  };

  const saveLockerRelease = async () => {
    const currentDate = new Date();
    const end = new Date(selectedOrder?.endDate);

    if (reasonLockerRelease !== "") {
      ///Order is inactive when current date bigger than order end date
      if (!(end < currentDate)) {
        const res: any = await dispatch(
          OrdersActions.releaseLocker(selectedOrder?.orderId, {
            notes: reasonLockerRelease,
          })
        );

        if (res?.isSucceeded) {
          setShow(true);
          setSuccessMessage(rcTranslate("orders.lockerRelease.success"));
          await dispatch(OrdersActions.getOrders(queryParams));
          showAlertTimeout = setTimeout(() => {
            setShow(false);
          }, 5000);

          setShowLockerReleaseDialog(false);
        }
      } else {
        setMessage(rcTranslate("orders.manageOrders.inactiveOrder"));
        setShowMessage(true);
      }
    } else {
      setRequired(true);
    }
  };
  const shouldExtendFilter: () => boolean = () => {
    let extendedFields: any = searchFields?.formData?.find(
      (formItem) => formItem?.id === "extendedFieldsWrapper"
    );
    if (extendedFields && queryParams) {
      extendedFields = extendedFields.fields[0]?.fields[0]?.fields?.map(
        (field: any) => field.id
      );

      if (extendedFields.length) {
        const foundParams: string[] = extendedFields?.filter((i: string) =>
          Object.keys(queryParams).includes(i)
        );

        if (foundParams?.length) {
          return true;
        }
      }
    }
    return false;
  };

  const handleReasonChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setReasonValue(value);
  };

  const renderCancelOrder = () => {
    return (
      <>
        <Title
          text={"orders.orderCancellation.subTitle"}
          parameters={[
            { label: "orderId", value: selectedOrderToCancel?.orderId },
          ]}
          className="manageorders__wrapper"
        />

        <TextInput
          id={"text"}
          label={rcTranslate("orders.orderCancellation.reason")}
          onChange={handleReasonChange}
          value={reasonValue}
        />
        {required && (
          <p className="manageorders__requiredFiels">
            {rcTranslate("orders.lockerRelease.required")}
          </p>
        )}

        {showMessage && (
          <p className="manageorders__noteInactiveOrder">{message}</p>
        )}
      </>
    );
  };
  const saveOrderCancellation = async () => {
    if (!selectedOrderToCancel?.orderId) return;
    const currentDate = new Date();
    const end = new Date(selectedOrderToCancel?.endDate);

    ///Order is inactive when current date bigger than order end date
    if (reasonValue !== "") {
      if (!(end < currentDate)) {
        const res: any = await dispatch(
          OrdersActions.orderCancellation(selectedOrderToCancel?.orderId, {
            notes: reasonValue,
          })
        );

        if (res?.isSucceeded) {
          setShow(true);
          setSuccessMessage(rcTranslate("orders.orderCancellation.success"));

          await dispatch(OrdersActions.getOrders(queryParams));

          showAlertTimeout = setTimeout(() => {
            setShow(false);
            setSuccessMessage("");
          }, 5000);
        }

        setShowCancelOrderDialog(false);
      } else {
        setMessage(rcTranslate("orders.manageOrders.inactiveOrder"));
        setShowMessage(true);
      }
    } else {
      setRequired(true);
    }
  };

  return (
    <>
      <Title
        text={
          customerId && customer
            ? `${rcTranslate("orders.manageOrders.pageTitle")} ${
                customer.firstName && customer.lastName
                  ? customer.firstName + " " + customer.lastName
                  : customer.firstName ?? ""
              }`
            : rcTranslate("orders.manageOrders.pageTitle")
        }
      />

      <Filters
        fields={searchFields}
        filtersValidation={orderListSearchValidation}
        getFormikProps={getFormikProps}
        isExtended={shouldExtendFilter()}
      />

      <RcTable
        rows={rows}
        columns={columns}
        onPageChange={onPageChange}
        handleSortChange={handleSortChange}
        paginationMode={IGridMode.server}
        checkboxSelection={false}
        expandableRows={appPermissions.includes(
          PERMISSIONS.GET_ORDER_EXTENDED_DETAILS
        )}
        rowsExpendedContent={rowsExpendedContent}
        showDots
        tdWidths={
          customerId
            ? [1, 1, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]
            : [1, 1, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]
        }
        columnVisibilityModel={columnVisibilityModel}
      />

      {show && <Alert message={succsessMessage} color="success" />}

      {showLockerReleaseDialog && (
        <Dialog
          className="manageorders__dialog"
          title={"orders.lockerRelease.title"}
          body={renderLockerReleaseDialog()}
          onConfirm={saveLockerRelease}
          onClose={() => setShowLockerReleaseDialog(false)}
        />
      )}

      {showCancelOrderDialog && (
        <Dialog
          className="manageorders__dialog"
          title={rcTranslate("orders.orderCancellation.title")}
          body={renderCancelOrder()}
          confirmTitle={"button.save"}
          onConfirm={saveOrderCancellation}
          onClose={() => setShowCancelOrderDialog(false)}
        />
      )}
    </>
  );
};

export default ManageOrders;
