import React, {useCallback, useEffect} from 'react';
import {useDispatch} from "react-redux";
import {ConfigurationActions} from "../../../store/entities/configuration/configuration.actions";
import {useAppSelector} from "../../../store";
import classes from './ListManagement.module.scss';
import useTranslations from "../../../hooks/useTranslations";
import {Link, useNavigate} from "react-router-dom";
import {IUser} from "../../../models/user";
import {CONSTANTS} from "../../../utils/constants";
import {IDynamicLists} from "../../../models/dynamic-lists";
import Title from "../../../components/UI/Title/Title";
import { IPage } from '../../../models/page';

const ListManagement = ({
    permission,
    nextRoute
  }: IPage) => {
    const dispatch = useDispatch();
    const {rcTranslate} = useTranslations();
    const { dynamicLists } = useAppSelector((state) => state.configuration);
    const navigate = useNavigate();

    useEffect(() => {
        if (permission !== undefined && !permission) {
          navigate(nextRoute);
        }
      }, [permission, nextRoute, navigate]);
    
    useEffect(() => {
        dispatch(ConfigurationActions.getDynamicLists());
    }, [dispatch]);

    const goToDynamicListOptions = useCallback(
        (list: IDynamicLists) => {
            navigate(
                "/" +
                CONSTANTS.ROUTES.CONFIGURATION.BASE +
                "/" +
                CONSTANTS.ROUTES.CONFIGURATION.CHILDREN.LIST_MANAGEMENT +
                "/" +
                list.id,
                {state: {listId: list.id}}
            );
        },
        [navigate]
    );
    return (

        <div className={classes.listManagementWrapper}>
            <Title text="configuration.listManagement.title" />
            <p>{rcTranslate("configuration.listManagement.listName")}</p>
            {dynamicLists?.map(el =>
                <div key={el.id} className={classes.listNameWrapper}>
                    <p className={classes.listName}>{el.nameHE}</p>
                    <button className={classes.goToListOptionButton} onClick={e => {goToDynamicListOptions(el)}}>{rcTranslate("configuration.listManagement.linkViewList")}</button>
                </div>)}
        </div>
    );
};

export default ListManagement;
