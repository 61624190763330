import React, { useState, useEffect } from "react";

import { ISelectInput } from "../../../../models/Input";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText
} from "@mui/material";
import useTranslations from "../../../../hooks/useTranslations";

import "./SelectInput.scss";

const SelectInput: React.FC<ISelectInput> = (props) => {
  const [selected, setSelected] = useState<string | boolean>("");

  const { rcTranslate } = useTranslations();

  useEffect(() => {
    if (props.value !== undefined && props.value !== null) {
      setSelected(props.value as string | boolean);
    }
  }, [props.value]);

  return (
    <FormControl
      variant="standard"
      className={`w-100 text-right ${props.className ?? ""}`}
      sx={{ minWidth: 120 }}
      error={props.error ? true : false}
    >
      <InputLabel id={`select-label` + props.id}>{props.label}</InputLabel>

      <Select
        value={props?.items && props?.items?.length ? selected : ""}
        labelId={`select-label` + props.id}
        id={props.id}
        label={props.label}
        className={"selectInput justify-content-end"}
        onChange={props.onChange}
        disabled={props.disabled}
      >
        <MenuItem
          className={"justify-content-end d-none"}
          value={""}
          disabled
        />

        {props?.items?.map((item) => (
          <MenuItem
            className={"justify-content-end"}
            key={item.key}
            value={item.key}
          >
            {rcTranslate(item.value)}
          </MenuItem>
        ))}
      </Select>

      <FormHelperText>{props.error ?? " "}</FormHelperText>
    </FormControl>
  );
};

export default SelectInput;
