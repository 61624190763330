import { IButton } from "../../../models/button";
import { ITableButtons } from "../../../models/table";
import Button from "../Button/Button";

import "./TableButtons.scss";

const TableButtons = ({
  className = "",
  generalButtons = [],
  tableButtons = []
}: ITableButtons) => {
  return (
    <div className={`tableButtons___wrapper ${className}`}>
      <div className="tableButtons__tableButtons">
        {tableButtons.map(
          (button: IButton, index: number) =>
            button.show !== false && <Button key={index} {...button} outlined />
        )}
      </div>

      <div className="tableButtons__generalButtons">
        {generalButtons.map(
          (button: IButton, index: number) =>
            button.show !== false && <Button key={index} {...button} />
        )}
      </div>
    </div>
  );
};

export default TableButtons;
