import { getNewErrorsArray } from "../../../utils/formatters";
import { GeneralActionsNames } from "../general/general.actions";
import { SitesActionsNames } from "./sites.actions";
import { SitesState, SitesAction } from "./type";

const initialState: SitesState = {
  isLoading: false,
  error: null,
  sites: [],
  siteListDetails:[],
  siteDetails: null,
  siteTypes: [],
  subSiteTypes: [],
  subSiteTypesById: [],
  closets: [],
  closetDetailsWithSite: null,
};

const usersReducer = (
  state: SitesState = initialState,
  action: SitesAction
): SitesState => {
  switch (action.type) {
    case SitesActionsNames.GET_SITES.START:
      return {
        ...state,
        error: null,
        isLoading: true,
        sites: [],
      };
    case SitesActionsNames.GET_SITES.FULFILLED:
      return {
        ...state,
        isLoading: false,
        sites: action.payload,
      };
    case SitesActionsNames.GET_SITES.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case SitesActionsNames.ADD_SITE.START:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case SitesActionsNames.ADD_SITE.FULFILLED:
      return {
        ...state,
        isLoading: false,
      };
    case SitesActionsNames.ADD_SITE.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case SitesActionsNames.GET_SITE_DETAILS.START:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case SitesActionsNames.GET_SITE_DETAILS.FULFILLED:
      return {
        ...state,
        isLoading: false,
        siteDetails: action.payload,
      };
    case SitesActionsNames.GET_SITE_DETAILS.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case SitesActionsNames.UPDATE_SITE_DETAILS.START:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case SitesActionsNames.UPDATE_SITE_DETAILS.FULFILLED:
      return {
        ...state,
        isLoading: false,
      };
    case SitesActionsNames.UPDATE_SITE_DETAILS.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case SitesActionsNames.GET_SITE_TYPES.START:
      return {
        ...state,
        error: null,
        isLoading: true,
        siteTypes: [],
      };
    case SitesActionsNames.GET_SITE_TYPES.FULFILLED:
      return {
        ...state,
        isLoading: false,
        siteTypes: action.payload,
      };
    case SitesActionsNames.GET_SITE_TYPES.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case SitesActionsNames.GET_SUB_SITE_TYPES.START:
      return {
        ...state,
        error: null,
        isLoading: true,
        subSiteTypes: [],
      };
    case SitesActionsNames.GET_SUB_SITE_TYPES.FULFILLED:
      return {
        ...state,
        isLoading: false,
        subSiteTypes: action.payload,
      };
    case SitesActionsNames.GET_SUB_SITE_TYPES.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case SitesActionsNames.GET_SUB_SITE_TYPES_BY_ID.START:
      return {
        ...state,
        error: null,
        isLoading: true,
        subSiteTypesById: [],
      };
    case SitesActionsNames.GET_SUB_SITE_TYPES_BY_ID.FULFILLED:
      return {
        ...state,
        isLoading: false,
        subSiteTypesById: action.payload,
      };
    case SitesActionsNames.GET_SUB_SITE_TYPES_BY_ID.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case SitesActionsNames.SET_SITES_DATA.START:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case SitesActionsNames.SET_SITES_DATA.FULFILLED:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    case SitesActionsNames.SET_SITES_DATA.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case SitesActionsNames.UPDATE_SITE_OPEN_FOR_ORDER.START:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case SitesActionsNames.UPDATE_SITE_OPEN_FOR_ORDER.FULFILLED:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    case SitesActionsNames.UPDATE_SITE_OPEN_FOR_ORDER.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case SitesActionsNames.GET_CLOSETS.START:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case SitesActionsNames.GET_CLOSETS.FULFILLED:
      return {
        ...state,
        isLoading: false,
        closets: action.payload,
      };
    case SitesActionsNames.GET_CLOSETS.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case SitesActionsNames.GET_CLOSET_WITH_SITE_DETAILS.START:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case SitesActionsNames.GET_CLOSET_WITH_SITE_DETAILS.FULFILLED:
      return {
        ...state,
        isLoading: false,
        closetDetailsWithSite: action.payload,
      };
    case SitesActionsNames.GET_CLOSET_WITH_SITE_DETAILS.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case SitesActionsNames.ADD_CLOSETS.START:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case SitesActionsNames.ADD_CLOSETS.FULFILLED:
      return {
        ...state,
        isLoading: false,
      };
    case SitesActionsNames.ADD_CLOSETS.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case SitesActionsNames.UPDATE_CLOSET.START:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case SitesActionsNames.UPDATE_CLOSET.FULFILLED:
      return {
        ...state,
        isLoading: false,
      };
    case SitesActionsNames.UPDATE_CLOSET.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case SitesActionsNames.UPDATE_SITES_STATUS.START:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case SitesActionsNames.UPDATE_SITES_STATUS.FULFILLED:
      return {
        ...state,
        isLoading: false,
      };
    case SitesActionsNames.UPDATE_SITES_STATUS.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
      case SitesActionsNames.GET_SITE_LIST.START:
      return {
        ...state,
        error: null,
        isLoading: true,
        siteListDetails: [],
      };
    case SitesActionsNames.GET_SITE_LIST.FULFILLED:
      return {
        ...state,
        isLoading: false,
        siteListDetails: action.payload,
      };
    case SitesActionsNames.GET_SITE_LIST.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case GeneralActionsNames.CLEAR_ERRORS.FULFILLED:
      return {
        ...state,
        error: getNewErrorsArray(state.error, action.payload),
      };
  }
  return state;
};

export default usersReducer;
