import * as React from "react";
import { useMemo, useState, useEffect, useCallback } from "react";
import { useAppSelector } from "../../../store";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import useTranslations from "../../../hooks/useTranslations";
import useGetGridData from "../../../hooks/useGetGridData";
import RcTable from "../../../components/UI/RcTable/RcTable";
import { GridColDef, GridRowsProp } from "@mui/x-data-grid";
import CheckIcon from "@mui/icons-material/Check";
import ActionButtons from "../../../components/UI/ActionButtons/ActionButtons";
import { IGridMode, ISortItem } from "../../../models/table";
import { getHebrewDate, sortDataByDate } from "../../../utils/formatters";
import useUrlSearchParam from "../../../hooks/useUrlSearchParams";
import { FieldTypes, IFormFields } from "../../../models/form";
import Filters from "../../../components/Container/Filters/Filters";
import { customerListSearchValidation } from "../../../utils/formValidation/customerList";
import { CustomersActions } from "../../../store/entities/customers/customers.actions";
import Title from "../../../components/UI/Title/Title";
import { CONSTANTS } from "../../../utils/constants";
import { PERMISSIONS } from "../../../utils/permissions";
import { IPage } from "../../../models/page";

import "./CustomerList.scss";
import { TableActions } from "../../../store/entities/table/table.actions";

const CustomerList = ({ permission, nextRoute }: IPage) => {
  const [rows, setRows] = useState<GridRowsProp>([]);
  const [formikProps, setFormikProps] = useState<any>({});

  const dispatch = useDispatch();
  const { rcTranslate } = useTranslations();
  const { onPageChange } = useGetGridData();
  const navigate = useNavigate();
  const { getValueFromQuery, params } = useUrlSearchParam();

  const { queryParams, nextPageNumber, currentPage } = useAppSelector(
    (state) => state.table
  );
  const { customers } = useAppSelector((state) => state.customers);
  const { appPermissions }: { appPermissions: string[] } = useAppSelector(
    (store) => store.auth
  );

  useEffect(() => {
    if (permission !== undefined && !permission) {
      navigate(nextRoute);
    }
  }, [permission, nextRoute, navigate]);

  useEffect(() => {
    if (permission) {
      if (currentPage !== 1) {
        dispatch(TableActions.setTableData({ currentPage: 1 }));
      }
      dispatch(CustomersActions.getCustomers(params));
    }
  }, [dispatch, params, permission]);

  useEffect(() => {
    if (nextPageNumber) {
      const getData = async () => {
        dispatch(
          CustomersActions.getCustomers(
            undefined,
            undefined,
            false,
            nextPageNumber
          )
        );
        await dispatch(TableActions.setTableData({ nextPageNumber: null }));
      };
      getData();
    }
  }, [nextPageNumber]);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: "firstName",
        headerName: rcTranslate("customers.filters.firstName"),
      },
      {
        field: "lastName",
        headerName: rcTranslate("customers.filters.lastName"),
      },
      {
        field: "phoneNumber",
        headerName: rcTranslate("customers.filters.phoneNumber"),
      },
      {
        field: "identificationNumber",
        headerName: rcTranslate("customers.filters.identity"),
      },
      {
        field: "email",
        headerName: rcTranslate("customers.filters.email"),
        headerClassName: "customerList__eMail",
      },
      {
        field: "mailSubscription",
        headerName: rcTranslate("customers.filters.mailing"),
      },
      {
        field: "created",
        headerName: rcTranslate("customers.filters.registrationDate"),
        sortComparator: sortDataByDate,
      },
      {
        field: "actions",
        headerName: "",
        sortable: false,
        headerClassName: "hideColumnSeperator",
      },
    ],
    [rcTranslate]
  );

  const naviagteToCustomerDetails = useCallback(
    (customerId: string) => {
      navigate(
        "/" +
          CONSTANTS.ROUTES.CUSTOMERS.BASE +
          "/" +
          CONSTANTS.ROUTES.CUSTOMERS.CHILDREN.CUSTOMER_DETAILS +
          `/${customerId}`
      );
    },
    [navigate]
  );

  const naviagteToCustomerOrders = useCallback(
    (customerId: string) => {
      navigate(
        "/" +
          CONSTANTS.ROUTES.ORDERS_MANAGEMENT.BASE +
          "/" +
          CONSTANTS.ROUTES.ORDERS_MANAGEMENT.CHILDREN.ORDERS_LIST +
          `/?CustomerId=${customerId}`,
        { state: { customerId } }
      );
    },
    [navigate]
  );

  const sendMessage = () => {};

  useEffect(() => {
    if (!customers?.length) return setRows([]);

    const customersRows = customers.map((customer, index) => {
      const customerObject: any = {
        id: index,
        firstName: customer.firstName,
        lastName: customer.lastName,
        phoneNumber: customer.phoneNumber,
        identificationNumber: customer.identificationNumber,
        email: customer.email,
        mailSubscription: customer.mailSubscription ? <CheckIcon /> : "",
        created: getHebrewDate(customer.created),
        actions: (
          <ActionButtons
            style={{ marginRight: "auto" }}
            hide={
              [PERMISSIONS.GET_CUSTOMER_DETAILS, PERMISSIONS.GET_ORDERS].filter(
                (v: string) => appPermissions.includes(v)
              ).length === 0
            }
            menuItems={[
              {
                label: "customers.customerList.actions.customerDetails",
                onClick: () =>
                  naviagteToCustomerDetails(customer?.customerId?.toString()),
                show: appPermissions.includes(PERMISSIONS.GET_CUSTOMER_DETAILS),
              },
              {
                label: "customers.customerList.actions.orderManagement",
                onClick: () =>
                  naviagteToCustomerOrders(customer?.customerId?.toString()),
                show: appPermissions.includes(PERMISSIONS.GET_ORDERS),
              },
              {
                label: "customers.customerList.actions.sendMessage",
                onClick: sendMessage,
                // show: !appPermissions.includes(PERMISSIONS.GET_CUSTOMER_DETAILS)
              },
            ]}
          />
        ),
      };

      return customerObject;
    });
   
    setRows(customersRows);
  }, [
    appPermissions,
    customers,
    naviagteToCustomerDetails,
    naviagteToCustomerOrders,
  ]);

  const searchFields: IFormFields = {
    initialValues: {
      firstName: getValueFromQuery("firstName"),
      lastName: getValueFromQuery("lastName"),
      identificationNumber: getValueFromQuery("identificationNumber"),
      phoneNumber: getValueFromQuery("phoneNumber"),
      email: getValueFromQuery("email"),
      mailSubscription: getValueFromQuery("mailSubscription"),
      fromCreatedDate: getValueFromQuery("fromCreatedDate"),
      toCreatedDate: getValueFromQuery("toCreatedDate"),
    },
    formData: [
      {
        type: FieldTypes.CHILDREN,
        id: "externalFieldsWrapper",
        fields: [
          {
            type: FieldTypes.TEXT,
            id: "firstName",
            label: "searchFields.firstName",
          },
          {
            type: FieldTypes.TEXT,
            id: "lastName",
            label: "searchFields.lastName",
          },
          {
            type: FieldTypes.TEXT,
            id: "identificationNumber",
            props: { inputProps: { maxLength: 9 } },
            label: "searchFields.identity",
          },
          {
            type: FieldTypes.TEL,
            id: "phoneNumber",
            label: "searchFields.phoneNumber",
            props: { inputProps: { maxLength: 10 } },
          },
        ],
      },
      {
        type: FieldTypes.CHILDREN,
        id: "extendedFieldsWrapper",
        fields: [
          {
            type: FieldTypes.CHILDREN,
            id: "extendedFieldsContainer",
            fields: [
              {
                type: FieldTypes.CHILDREN,
                id: "extendedFields",
                className: "filters__extendedFields",
                fields: [
                  {
                    type: FieldTypes.TEXT,
                    id: "email",
                    label: "searchFields.email",
                  },
                  {
                    type: FieldTypes.SELECT,
                    id: "mailSubscription",
                    label: "searchFields.mailing.label",
                    items: [
                      {
                        key: "true",
                        value: rcTranslate("searchFields.mailing.items.yes"),
                      },
                      {
                        key: "false",
                        value: rcTranslate("searchFields.mailing.items.no"),
                      },
                    ],
                  },
                  {
                    type: FieldTypes.DATE,
                    id: "fromCreatedDate",
                    label: "searchFields.fromCreatedDate",
                    props: { getStartOfDay: true },
                  },
                  {
                    type: FieldTypes.DATE,
                    id: "toCreatedDate",
                    label: "searchFields.toCreatedDate.label",
                    props: {
                      getFullDayTime: true,
                      numOfDays: 1,
                      minDate: new Date(formikProps?.values?.fromCreatedDate),
                    },
                  },
                ],
              },
              {
                type: FieldTypes.CHILDREN,
                id: "actionsContainer",
                className: "filters__extendedActions",
                fields: [
                  {
                    type: FieldTypes.BUTTON,
                    id: "closeExtendedSearch",
                    label: "searchFields.closeExtendedSearch",
                  },
                  {
                    type: FieldTypes.CHILDREN,
                    id: "submitButtonWrapper",
                    className: "filters__submitButtonWrapper",
                    fields: [
                      {
                        type: FieldTypes.SUBMIT_BUTTON,
                        id: "submitButton",
                        label: "",
                      },
                      {
                        type: FieldTypes.BUTTON,
                        id: "cleanSearch",
                        label: "searchFields.cleanSearch",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  };

  const handleSortChange = (model: ISortItem[]) => {
    dispatch(CustomersActions.getCustomers(queryParams, model));
  };

  const getFormikProps = useCallback((values: any) => {
    setFormikProps(values);
  }, []);

  return (
    <>
      <Title text="customers.pageTitle" />

      <Filters
        fields={searchFields}
        filtersValidation={customerListSearchValidation}
        getFormikProps={getFormikProps}
      />

      <RcTable
        rows={rows}
        columns={columns}
        onPageChange={onPageChange}
        handleSortChange={handleSortChange}
        paginationMode={IGridMode.server}
        checkboxSelection={true}
        expandableRows={false}
        tdWidths={[1, 1, 1, 1, 1, 2, 1, 1]}
      />
    </>
  );
};

export default CustomerList;
