import moment from "moment";
import useTranslations from "../hooks/useTranslations";

import { IApiError } from "../models/api";

export const getHebrewDate = (date: Date | string) =>
  moment(date).format("DD/MM/YYYY");

export const getDateAndTime = (value: Date | string) => {
  const date = new Date(value)
    ?.toLocaleDateString("he-IL")
    ?.replace(/[.]/g, "/");
  const time = moment(value).format("H:mm");

  return `${time} ${date}`;
};

export const getOnlyDate = (date: Date | string) => new Date(new Date(date));

export const getFutureDateByNumOfDays = (date: Date, numOfDays: number) => {
  date = getOnlyDate(date);
  return new Date(new Date(date).setDate(new Date(date).getDate() + numOfDays));
};

export const getCorrectDate = (
  date: Date | any = new Date(),
  getFullDate: boolean = false,
  getStartOfDay: boolean = false,
  numOfDays: number
) => {
  if (getStartOfDay) {
    date = getOnlyDate(date);
  }

  date = moment(date, "DD/MM/YYYY").toDate();

  if ((getFullDate && numOfDays) || numOfDays) {
    date = getFutureDateByNumOfDays(date, numOfDays);

    date = new Date(new Date(date).getTime() - 1000);
  }

  const dateString = moment(date).format().split("+")[0];
  const formattedDate = `${dateString}Z`;

  return formattedDate;
};

export const sortDataByDate: any = (value1: any, value2: any) => {
  if (value1 && value2) {
    const format = value1.includes(":") ? "DD/MM/YYYY k:mm" : "DD/MM/YYYY";

    value1 = moment(value1, format).toDate();
    value2 = moment(value2, format).toDate();

    return value1 - value2;
  }

  return value1 - value2;
};

export const getCorrectPhoneNumber = (phoneNumber: string | undefined) => {
  if (!phoneNumber) return "";

  phoneNumber = phoneNumber.replace("+9720", "0");
  phoneNumber = phoneNumber.replace("+972", "");
  phoneNumber = phoneNumber.replace("-", "");

  return phoneNumber;
};

export const tryParseValue = (value: any) => {
  try {
    return JSON.parse(value);
  } catch (e) {
    return value;
  }
};

export const getErrorMessages = (error: any) => {
  const status = error?.response?.status;
  if (status && [404, 401].includes(status)) {
    return null;
  }
  const { errors = [] }: { errors: IApiError[] | [] } =
    error?.response?.data ?? {};

  if (errors.length) {
    return errors;
  }

  let parsedError: string | IApiError[] = "";

  if (error.message) {
    parsedError = tryParseValue(error.message);
  }

  if (status === 400) {
    let obj = error?.response?.data.errors;
    let errors: IApiError[] = [];

    if (process.env.REACT_APP_ENV === "dev") {
      for (let key in obj[Object.keys(obj)[0]]) {
        errors.push({ code: -1, message: obj[Object.keys(obj)[0]][key] });
      }
    } else {
      errors.push({ code: -1, message: "שגיאה כללית" });
    }

    parsedError = errors;
  }

  if (Array.isArray(parsedError) && parsedError.length) {
    if (
      typeof parsedError[0] === "object" &&
      parsedError[0].hasOwnProperty("code") &&
      parsedError[0].hasOwnProperty("message")
    ) {
      return parsedError;
    }
  }

  return null;
};

export const getNewErrorsArray = (
  errors: IApiError[] | null,
  message: string
) => {
  let newErrors = errors ? [...errors] : null;

  if (newErrors) {
    const foundErrorMessageIndex = newErrors.findIndex(
      (err) => err.message === message
    );

    if (foundErrorMessageIndex > -1) {
      newErrors.splice(foundErrorMessageIndex, 1);

      if (!newErrors.length) {
        newErrors = null;
      }
    }
  }

  return newErrors;
};
