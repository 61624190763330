import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import MUIAutocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import {
  IAutoCompleteValue,
  IAutoComplete,
  IAutoCompleteItem
} from "../../../../models/autoComplete";
import FieldError from "../../../FieldError/FieldError";
import useTranslations from "../../../../hooks/useTranslations";

import "./AutoComplete.scss";
import {  useAppSelector } from "../../../../store";
import useUrlSearchParam from "../../../../hooks/useUrlSearchParams";
import { TableActions } from "../../../../store/entities/table/table.actions";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const AutoComplete = ({
  items = [],
  onChange,
  label,
  className = "",
  id,
  error,
  value,
  multiple = true,
  disabled = false
}: IAutoComplete) => {
  const [selectedItems, setSelectedItems] = useState<IAutoCompleteValue>(
    multiple ? [] : null
  );

  const { appLanguage } = useAppSelector((store) => store.general);

  const { queryParams } = useAppSelector((state) => state.table);

  const { updateParams } = useUrlSearchParam();

  const dispatch = useDispatch();


  const { rcTranslate } = useTranslations();

  useEffect(() => {
    setSelectedItems(value);
  }, [value]);

  const handleChange = (
    _event: React.SyntheticEvent,
    value: IAutoCompleteItem[] | IAutoCompleteItem | null
  ) => {

    if (value === null) {
      // Update search after clearing a value in the AutoComplete field
      if (queryParams && id) {
        let newQuery={...queryParams}
        delete newQuery[id]
        localStorage.setItem(
          "lockerim_queryParams",
          JSON.stringify(newQuery)
        );
      }
      return onChange && onChange(multiple ? [] : null);
    }

    let items: IAutoCompleteValue = [];

    if (Array.isArray(value)) {
      items = (value as IAutoCompleteItem[])?.map((item) => item.id);
    } else {
      items = value.id;
    }

    onChange && onChange(items);
  };

  const getFieldValue = () => {
    if (items?.length && selectedItems) {
      if (Array.isArray(selectedItems)) {
        const foundValue: IAutoCompleteItem[] = items.filter((item) =>
          (selectedItems as number[]).includes(item.id)
        );

        return foundValue;
      } else {
        const foundValue: IAutoCompleteItem | undefined = items.find(
          (item) => item.id === (selectedItems as number)
        );

        if (foundValue) {
          return foundValue;
        }
      }
    }

    if (multiple) {
      return [];
    }

    return null;
  };

  return (
    <div className="autoCompleteWrapper">
      <MUIAutocomplete
        multiple={multiple}
        className={`autoComplete ${className}`}
        id={id}
        options={items}
        value={getFieldValue()}
        disableCloseOnSelect={multiple}
        disabled={disabled}
        onChange={handleChange}
        getOptionLabel={(option) => rcTranslate(option.label)}
        renderOption={(props, option, { selected }) => (
          <li
            style={{ direction: appLanguage === "hebrew" ? "rtl" : "ltr" ,textAlign:"start"}}
            {...props}
          >
            {multiple && (
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={(selectedItems as number[]).includes(option.id)}
              />
            )}

            {rcTranslate(option.label)}
          </li>
        )}
        style={{ width: "100%" }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={rcTranslate(label)}
            variant="standard"
            error={!!error}
          />
        )}
      />

      <FieldError error={error as string} />
    </div>
  );
};

export default AutoComplete;
