import { GridColDef, GridRowsProp } from "@mui/x-data-grid";
import { IButton } from "./button";

export interface ITable {
  rows: GridRowsProp;
  columns: GridColDef[];
  checkboxSelection?: boolean;
  loading?: boolean;
  paginationMode?: IGridMode;
  sortingMode?: IGridMode;
  onPageChange?: (page: number) => void;
  handleSortChange?: (model: ISortItem[]) => void;
  expandableRows?: boolean;
  rowsExpendedContent?: any;
  showDots?: boolean;
  tdWidths: number[];
  columnVisibilityModel?: any;
  className?: string;
  hideFooter?: boolean
}

export interface IActionButtonsItems {
  label: string;
  onClick?: () => any;
}

export enum IGridMode {
  server = "server",
  client = "client"
}

export interface ITableRow {
  expandable: boolean;
  expanded?: boolean | string;
  setExpanded?: (value: string | boolean) => void;
  rowsExpendedContent?: any;
  selectionModel?: number[];
  setSelectionModel?: (value: number[]) => void;
  row: any;
  rowId: number;
  checkboxSelection?: boolean;
  showDots?: boolean;
  selected?: boolean;
  tdWidths?: number[];
  hideFooter?: boolean
}

export interface ISortItem {
  Field: string;
  IsDescending: boolean;
}

export interface ITableButtons {
  className?: string;
  generalButtons: IButton[] | [];
  tableButtons: IButton[] | [];
}
