export const generateAsyncActionNames = (name: string) => ({
  START: `${name}#START`,
  FULFILLED: `${name}#FULFILLED`,
  REJECTED: `${name}#REJECTED`
});

export const checkArraysAreTheSame = (arr1: any[], arr2: any[]) =>
  arr1.sort().join(",") === arr2.sort().join(",");

export const checkArrayHasAllElementsOfAnotherArray = (
  arr1: any[],
  arr2: any[]
) => arr2.every((v) => arr1.includes(v));

export const checkIsraeliPhone = (num: string) => {
  return /^05\d([-]{0,1})\d{7}$/.test(num);
};

export const checkOnlyDigits = (value: string) => /^[0-9]*$/.test(value);

export const nameENRegex = /^[.a-zA-Z ]+$/;

export const namHERegex = /^['",.\u0590-\u05FF\uFB2A-\uFB4E ]+$/;

export const emailRegex = /^[_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/