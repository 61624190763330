import React, { useCallback, useState } from "react";
import { TextField, FormHelperText } from "@mui/material";
import { DatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

import useTranslations from "../../../../hooks/useTranslations";
import { useEffect } from "react";

import { getCorrectDate, getHebrewDate } from "../../../../utils/formatters";
import { IDateInput } from "../../../../models/form";

import "./DatePickerInput.scss";
import { useAppSelector } from "../../../../store";
import { Key } from "@mui/icons-material";

const DatePickerInput: React.FC<IDateInput> = ({
  onChange,
  label,
  helperText = "",
  value,
  error,
  minDate,
  maxDate,
  disabled,
  getFullDayTime = false,
  getStartOfDay = false,
  showHours = false,
  numOfDays = null,
  id
}) => {
  const [dateValue, setDateValue] = useState<Date | null>();
  const { rcTranslate } = useTranslations();

  const { queryParams } = useAppSelector((state) => state.table);


  useEffect(() => {
    if (value) {
      setDateValue(new Date(value));
    }
    else {
      dateValue && setDateValue(null);
    }
  }, [value]);

  const onChangeHandler = useCallback(
    (date: Date | string | null) => {
      if (!date) {
        onChange && onChange("");
        return;
      }
      setDateValue(date as Date);

      if (!showHours) {
        date = getCorrectDate(
          date as Date,
          getFullDayTime,
          getStartOfDay,
          numOfDays as any
        );
      }

      onChange && onChange(date);
    },
    [getFullDayTime, getStartOfDay, numOfDays, onChange, showHours]
  );

  return (
    <div className={"datePicker"}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label={label}
          value={dateValue || null}
          inputFormat={"dd/MM/yyyy"}
          onChange={onChangeHandler}
          renderInput={(params: any) => (
            <TextField
              {...params}
              variant="standard"
              error={error ? true : false}
            />
          )}
          minDate={minDate}
          maxDate={maxDate}
          disabled={disabled}
          allowSameDateSelection
        />
      </LocalizationProvider>

      <FormHelperText error={!!error}>
        {error ? error : helperText ? rcTranslate(helperText) : " "}
      </FormHelperText>
    </div>
  );
};
export default DatePickerInput;
