import React, {useCallback, useEffect, useState} from 'react';
import {IDynamicListOptionItem, IOptionItemProps} from "../../../../../models/dynamic-lists";
import {FieldTypes, IFormFields} from "../../../../../models/form";
import useTranslations from "../../../../../hooks/useTranslations";
import Form from "../../../../../components/Container/Form/Form";
import "./OptionItem.scss";
import {optionItemValidation} from "../../../../../utils/formValidation/optionItem";
import {CONSTANTS} from "../../../../../utils/constants";
import {AuthActions} from "../../../../../store/entities/auth/auth.actions";
import {formatIdNumberDigit} from "../../../../../utils/formValidation/ValidationHelperFunctions";
import {useDispatch} from "react-redux";
import {ConfigurationActions} from "../../../../../store/entities/configuration/configuration.actions";
import {UpdateDynamicListOptionBody} from "../../../../../store/entities/configuration/type";
import Alert from "../../../../../components/UI/Alert/Alert";

const OptionItem = (props: IOptionItemProps) => {
    const {rcTranslate} = useTranslations();
    const dispatch = useDispatch();
    const [show, setShow] = useState<boolean>(false);
    let successMessageTimeout: any;
    const fields: IFormFields = {
        initialValues: {
            nameHE: props.item.nameHE ?? "",
            nameEN: props.item.nameEN ?? "",
            isActive: props.item.isActive ?? false
        },
        formData: [
            {
                type: FieldTypes.PLAIN_TEXT,
                id: "nameHE",
                label: props.item.nameHE,
                className: "optionItem__formItem"
            },
            {
                type: FieldTypes.PLAIN_TEXT,
                id: "nameEN",
                label: props.item.nameEN,
                className: "optionItem__formItem"
            },
            {
                type: FieldTypes.CHECK_BOX,
                id: "isActive",
                label: "configuration.listManagement.listOptions.isActive",
                className: "optionItem__checkboxItem"
            }
        ]
    }

    useEffect(() => {
        return () => {
            clearTimeout(successMessageTimeout);
            setShow(false);
        };
    }, [successMessageTimeout, dispatch]);

    const saveItem = async (
        values: UpdateDynamicListOptionBody,
        setSubmitting: (value: boolean) => void
    ) => {
        let res: any;
        setSubmitting(false);
        res = await dispatch(ConfigurationActions.updateDynamicListOption(props.listId, props.item.id, values));
        if (res?.isSucceeded) {
            setShow(true);
            dispatch(ConfigurationActions.getDynamicListOptions(props.listId));
            successMessageTimeout = setTimeout(() => {
                setShow(false);
            }, 5000)
        }
    };

    return (
        <>
            <Form
                fields={fields}
                formClassName="optionFormWrapper"
                formValidation={optionItemValidation}
                onSubmit={saveItem}
                isDisabled={true}
                submitButtonTitle={"button.save"}/>
            {show && (
                <Alert
                    message={
                        rcTranslate("configuration.ListManagement.updateSuccess")
                    }
                    color="success"
                />
            )}
        </>
    );
};

export default OptionItem;
