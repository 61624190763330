import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import { useAppSelector } from "../../../store";
import { PermissionsActions } from "../../../store/entities/permissions/permissions.actions";
import { RolesActions } from "../../../store/entities/roles/roles.actions";
import { UsersActions } from "../../../store/entities/users/users.actions";
import { AuthActions } from "../../../store/entities/auth/auth.actions";
import { UserPermissionsBody } from "../../../store/entities/users/type";
import { CONSTANTS } from "../../../utils/constants";
import { PERMISSIONS } from "../../../utils/permissions";
import { FieldTypes, IFormFields } from "../../../models/form";
import { updateUserPermissionsValidation } from "../../../utils/formValidation/usersManagement";
import Form from "../../../components/Container/Form/Form";
import Alert from "../../../components/UI/Alert/Alert";
import useTranslations from "../../../hooks/useTranslations";
import Legend from "../../../components/UI/Legend/Legend";
import BackButton from "../../../components/UI/BackButton/BackButton";
import { permission as IPermission } from "../../../store/entities/permissions/type";
import { IPage } from "../../../models/page";
import { ILoggedUserData } from "../../../store/entities/auth/type";

const UpdateUserPermissions = ({ permission, nextRoute }: IPage) => {
  const [rolesData, setRolesData] = useState<any[]>([]);
  const [rolePermissionIds, setRolePermissionIds] = useState<number[]>([]);
  const [allPermissions, setAllPermissions] = useState<Partial<IPermission>[]>(
    []
  );
  const [permissionsIds, setPermissionsIds] = useState<number[]>([]);
  const [userPermissionsIds, setUserPermissionsIds] = useState<number[]>([]);
  const [show, setShow] = useState<boolean>(false);
  const { rcTranslate } = useTranslations();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userId = "" } = useParams();
  const location: any = useLocation();

  const userDetails = location?.state?.user;

  let navigationTimeout: any;

  const { permissionGroups, permissions } = useAppSelector(
    (store) => store.permissions
  );
  const { userPermissions } = useAppSelector((store) => store.users);
  const { role } = useAppSelector((store) => store.roles);
  const {
    appPermissions,
    loggedUserData
  }: { appPermissions: string[]; loggedUserData: ILoggedUserData } =
    useAppSelector((store) => store.auth);

  useEffect(() => {
    if (permission !== undefined && !permission) {
      navigate(nextRoute);
    }
  }, [permission, nextRoute, navigate]);

  // Clear role details first, then get user permissions and details to get userRoleId
  useEffect(() => {
    dispatch(PermissionsActions.getPermissionGroups());
    dispatch(PermissionsActions.getPermissions());

    if (userId) {
      dispatch(UsersActions.getUserPermissions(userId));
    }
  }, [dispatch, userId]);

  // After we get userRoleId, we find the roleDetails to get its permissions
  useEffect(() => {
    if (userDetails) {
      dispatch(
        RolesActions.getRoleDetails(userDetails?.leadingRoleId as string)
      );
    }
  }, [dispatch, userDetails]);

  // Then we build the permissions tree, and disable clicking permissions that are already inside role permissions
  useEffect(() => {
    if (!permissions.length || !permissionGroups.length || !role) return;

    // Check if permission is already in rolePermissions and disable its checkbox
    let newPermissions: IPermission[] = [...permissions];
    newPermissions = newPermissions.map((item) => ({
      ...item,
      disabled: role?.permissionKeys?.includes(item.key)
    }));

    // Give all permissionGroups their permissions and hide all permissionGroups checkboxes
    let treeData = permissionGroups?.map((group: any) => {
      const groupPermissions = newPermissions.filter(
        (permission) => permission.permissionGroupId === group.id
      );

      if (!groupPermissions.length) return { ...group, children: [] };

      return { ...group, children: groupPermissions };
    });

    // Give parent groups their children groups
    treeData = treeData?.map((group: any) => {
      if (!group.parentId) {
        const childrenGroups = permissionGroups.filter(
          (item: any) => group.id === item.parentId
        );

        if (!childrenGroups.length) {
          group = { ...group, childrenGroups: [] };
        } else {
          group = { ...group, childrenGroups };
        }
      }

      return group;
    });

    // Remove groups where parentId is null
    treeData = treeData.filter((treeItem: any) => !treeItem.parentId);

    setRolesData(treeData);

    const allPermissionsValues = newPermissions.map((i) => ({
      id: i.id,
      key: i.key
    }));

    setAllPermissions(allPermissionsValues);

    const rolePermissionIdsValues: number[] = newPermissions
      .filter((i) => i.disabled)
      ?.map((item) => item.id);

    setRolePermissionIds(rolePermissionIdsValues);

    newPermissions = newPermissions.filter((i) => !i.disabled);

    setPermissionsIds(newPermissions.map((permission) => permission.id));
  }, [permissionGroups, permissions, role]);

  // Find user permissions and role permissions, by their permission keys, and combine their ids into an array
  useEffect(() => {
    if (!permissions.length || !role) return setUserPermissionsIds([]);

    let userPermissionIds: any[] = [];

    if (userPermissions.length) {
      userPermissionIds = permissions.filter((i: any) =>
        userPermissions.includes(i.key)
      );

      if (userPermissionIds.length) {
        userPermissionIds = userPermissionIds.map((item) => item.id);
      }
    }

    const foundRoles: IPermission[] = permissions.filter((i) =>
      role?.permissionKeys?.includes(i.key)
    );

    const foundRolesIds = foundRoles.map((i) => i.id);

    if (foundRolesIds) {
      userPermissionIds = [...userPermissionIds, ...foundRolesIds];
    }

    userPermissionIds = Array.from(new Set(userPermissionIds));

    setUserPermissionsIds(userPermissionIds);
  }, [permissions, role, userPermissions]);

  const fields: IFormFields = {
    initialValues: {
      permissionIds: userPermissionsIds ?? []
    },
    formData: [
      {
        type: FieldTypes.TREE_VIEW,
        id: "permissionIds",
        label: "",
        items: rolesData,
        checkableIds: permissionsIds
      }
    ]
  };

  const legendItems = [
    {
      symbol: <CheckBoxIcon color="primary" />,
      description: "legend.legendItems.activeCheckbox"
    },
    {
      symbol: <CheckBoxIcon />,
      description: "legend.legendItems.disabledCheckbox"
    }
  ];

  const handleSubmit = async (
    values: UserPermissionsBody,
    setSubmitting: (value: boolean) => void
  ) => {
    // New permissions to save to Redux if we update logged in user's permissions
    let newAppPermissions: (string | undefined)[] = [];

    if (loggedUserData?.userId === userId) {
      newAppPermissions = allPermissions
        .filter((i) => values.permissionIds.includes(i.id as number))
        ?.map((item) => item.key);
    }

    // Before submitting we need to take out the permission ids that are assigned with the user's role so we won't make them as user's permissions
    const data = { ...values };

    data.permissionIds = data.permissionIds.filter(
      (id: number) => !rolePermissionIds.includes(id)
    );

    const res: any = await dispatch(
      UsersActions.updateUserPermissions(userId, data)
    );

    setSubmitting(false);

    if (res?.isSucceeded) {
      // Saving new permissions to Redux
      if (newAppPermissions?.length) {
        dispatch(
          AuthActions.updateAppPermissions(newAppPermissions as string[])
        );
      }

      setShow(true);

      navigationTimeout = setTimeout(() => {
        navigate(
          "/" +
            CONSTANTS.ROUTES.USERS_MANAGEMENT.BASE +
            "/" +
            CONSTANTS.ROUTES.USERS_MANAGEMENT.CHILDREN.USERS_LIST
        );
      }, 5000);
    }
  };

  useEffect(() => {
    return () => {
      clearTimeout(navigationTimeout);
      setShow(false);
      setPermissionsIds([]);
      setUserPermissionsIds([]);
      setRolesData([]);
      dispatch(PermissionsActions.clearPermissions());
      dispatch(RolesActions.clearRoleDetails());
    };
  }, [navigationTimeout, dispatch]);

  return (
    <>
      <BackButton />

      <Form
        fields={fields}
        formTitle={[
          "users.userPermissions.managePermissions",
          [
            {
              label: "userFullName",
              value: userDetails?.firstName + " " + userDetails?.lastName
            }
          ]
        ]}
        formValidation={updateUserPermissionsValidation}
        onSubmit={handleSubmit}
        submitButtonTitle="button.save"
        isDisabled={
          !appPermissions.includes(PERMISSIONS.UPDATE_USER_PERMISSIONS)
        }
      />

      <Legend items={legendItems} />

      {show && (
        <Alert
          message={rcTranslate("users.userPermissions.updateSuccess")}
          color="success"
        />
      )}
    </>
  );
};

export default UpdateUserPermissions;
