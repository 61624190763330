import MUIDialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { useAppSelector } from "../../../store";
import { IDialog } from "../../../models/dialog";
import useTranslations from "../../../hooks/useTranslations";
import Button from "../Button/Button";

import "./Dialog.scss";

export default function Dialog({
  onConfirm,
  onClose,
  title,
  body,
  confirmTitle = "dialog.confirmTitle",
  closeTitle = "dialog.closeTitle",
  disabled = false,
  className = "",
  showCloseButton=true
}: IDialog) {
  const { appLanguage } = useAppSelector((store) => store.general);
  const { rcTranslate } = useTranslations();

  return (
    <MUIDialog
      onClose={onClose}
      aria-labelledby="customized-dialog-title"
      open={true}
      className={`dialogComponent ${className}`}
      sx={{
        "& .MuiDialogTitle-root": {
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: appLanguage === "hebrew" ? "row-reverse" : "row",
          direction: appLanguage === "hebrew" ? "rtl" : "ltr"
        },
        "& .MuiDialogContent-root": {
          direction: appLanguage === "hebrew" ? "rtl" : "ltr"
        },
        "& .MuiDialogActions-root": {
          display: "flex",
          justifyContent: appLanguage === "hebrew" ? "flex-start" : "flex-end",
          flexDirection: appLanguage === "hebrew" ? "row" : "row-reverse"
        }
      }}
    >
      <DialogTitle sx={{ m: 0, p: 1 }}>
        {onClose && (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        )}

        {rcTranslate(title)}
      </DialogTitle>

      <DialogContent dividers>
        {typeof body === "string" ? rcTranslate(body) : body}
      </DialogContent>

      <DialogActions>
        {onConfirm && (
          <Button
            title={confirmTitle}
            onClick={onConfirm}
            disabled={disabled}
          />
        )}

        {onClose &&showCloseButton&& (
          <Button
            className="appDialog__cancelButton"
            title={closeTitle}
            onClick={onClose}
          />
        )}
      </DialogActions>
    </MUIDialog>
  );
}
