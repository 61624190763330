import { useDispatch } from "react-redux";
import { Pagination as MUIPagination } from "@mui/material";

import { useAppSelector } from "../../../store";
import { TableActions } from "../../../store/entities/table/table.actions";
import useTranslations from "../../../hooks/useTranslations";

import "./Pagination.scss";

const Pagination = () => {
 
  
  const { totalRows,currentPage, pageSize } = useAppSelector(
    (state) => state.table
  );
  const { rcTranslate } = useTranslations();
  const dispatch = useDispatch();
  
  return (
    <div className="paginationWrapper">
      <div />

      <MUIPagination
        className="pagination"
        count={Math.ceil((totalRows as number) / pageSize)}
        page={currentPage}
        onChange={async (_event, value) => {
          await dispatch(TableActions.setTableData({ nextPageNumber: value,currentPage:value }));

        }}
      />
      <div className="pagination__totalRows">{`${rcTranslate(
        "table.totalRows"
      )} ${totalRows}`}</div>
    </div>
  );
};

export default Pagination;
