import { IAutoCompleteItem } from "./autoComplete";
import { IListItem } from "./Input";
import { IRadioItem } from "./radio";
import { ITreeViewItem } from "./treeView";

export interface IForm {
  fields: IFormFields;
  formTitle?: string | any[];
  formValidation: any;
  children?: any;
  onSubmit?: (values: any, setSubmitting: (value: boolean) => void) => void;
  submitButtonTitle: string;
  showCancelButton?: boolean;
  showSubmitButton?: boolean;
  showSubmitIconButton?: boolean;
  cancelButtonTitle?: string;
  onReset?: () => void;
  formClassName?: string;
  
  getFormikProps?: (values: any) => void;
  isDisabled?: boolean;
  submitButtonClassName?: string;
}

export interface IFormFields {
  initialValues: any;
  formData: (IFormField | IFormFieldWithChildren)[];
}

export interface IFormField {
  type: FieldTypes;
  id: string;
  label: string;
  className?: string;
  items?: IListItem[] | ITreeViewItem[] | IRadioItem[] | IAutoCompleteItem[];
  checkableIds?: number[];
  onClick?: () => void;
  component?: React.FC<any>;
  props?: any;
}

export interface IFormFieldWithChildren {
  type: FieldTypes.CHILDREN;
  id: string;
  fields: (IFormField | IFormFieldWithChildren)[];
  className?: string;
}

export enum FieldTypes {
  TEXT = "text",
  PLAIN_TEXT = "plain text",
  TEL = "tel",
  SELECT = "select",
  TREE_VIEW = "tree_view",
  DATE = "date",
  BUTTON = "button",
  SUBMIT_BUTTON = "submit_button",
  RADIO = "radio",
  SWITCH = "switch",
  TEXTFIELD = "textfield",
  CHILDREN = "children",
  SUBTITLE = "subtitle",
  COMPONENT = "component",
  AUTOCOMPLETE = "autoComplete",
  CHECK_BOX = "checkbox",
  MENU = "MENU",
  FORM_CONTROL = "form_control",
  PASSWORD="password"
}

export interface IFormikProps {
  key: string;
  values: any;
  errors: any;
  touched: any;
  setFieldTouched: (id: string, value: boolean) => void;
  isSubmitting: boolean;
  isValid: boolean;
  isValidating: boolean;
  handleChange: () => void;
  handleSubmit: () => void;
  handleReset: () => void;
  setFieldValue: (id: string, value: any) => void;
  setFieldError: (id: string, value: any) => void;
  className?: string;
  validateOnBlur:boolean
}

export interface IDateInput {
  onChange: (date: string | Date) => void;
  label: string;
  helperText?: string;
  value: Date;
  error: any;
  minDate?: Date;
  maxDate?: Date;
  disabled: boolean;
  getFullDayTime?: boolean;
  getStartOfDay?: boolean;
  showHours?: boolean;
  numOfDays?: number | null;
  id?:string;
}
