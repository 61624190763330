import { object, boolean, string, number, mixed } from "yup";
import { checkIsraeliPhone, namHERegex, nameENRegex, checkOnlyDigits } from "../helpers";

export const siteDetailsValidation = object().shape({
  isActive: boolean().required("שדה חובה"),
  nameHE: string()
    .matches(namHERegex, "הזן אותיות בעברית בלבד")
    .required("שדה חובה"),
  nameEN: string().matches(nameENRegex, "הזן אותיות באנגלית בלבד"),
  siteTypeId: number().typeError("שדה חובה").required("שדה חובה"),
  subSiteTypeId: number().typeError("שדה חובה").required("שדה חובה"),
  primaryPhoneNumber: string()
    .test("check phone number", " יש להזין מספר טלפון תקין 9 עד 10 ספרות ", (value) => {
      return value ? checkOnlyDigits(value) && value?.length >= 9 : false
    })
    .required("שדה חובה"),
    
  faxNumber: string()
  .test("check fax number", "יש להזין מספר פקס תקין 9 ספרות  ", (value) => {
    return value ?( checkOnlyDigits(value) && value?.length === 9):value===undefined ?true:false
  })
  ,
  lockerPrice: number()
    .typeError("יש להזין מספרים בלבד")
    .min(0.1, "יש להזין סכום גדול מ-0")
    .required("שדה חובה"),
  lockFee: number().typeError("יש להזין מספרים בלבד").required("שדה חובה"),
  cityId: number().typeError("שדה חובה").required("שדה חובה"),
  address: string().required("שדה חובה"),
  postalCode: number().typeError("יש להזין מספרים בלבד"),
  note: string(),
  commission: number().typeError("יש להזין מספרים בלבד"),
  commissionNote: string(),
  agreementPeriodDateFrom: mixed().test(
    "check agreementPeriodDateFrom value",
    "שדה חובה",
    function (value) {
      const agreementPeriodDateTo = this.parent.agreementPeriodDateTo;

      if (!value) return false;

      return new Date(value) > new Date(agreementPeriodDateTo)
        ? this.createError({
          message: "טווח התאריכים שגוי",
          path: "agreementPeriodDateFrom"
        })
        : true;
    }
  ),
  agreementPeriodDateTo: mixed().test(
    "check agreementPeriodDateTo value",
    "שדה חובה",
    function (value) {
      const agreementPeriodDateFrom = this.parent.agreementPeriodDateFrom;

      if (!value) return false;

      if (agreementPeriodDateFrom) {
        return new Date(value) < new Date(agreementPeriodDateFrom)
          ? this.createError({
            message: "טווח התאריכים שגוי",
            path: "agreementPeriodDateTo"
          })
          : true;
      }

      return true;
    }
  ),
  agreementPeriodNote: string(),
  rentalRouteId: number().typeError("שדה חובה").required("שדה חובה")
});

export const sitesListSearchValidation = object().shape({
  id: number().nullable(true),
  isActive: boolean(),
  name: string(),
  subSiteTypeId: number().nullable(true),
  siteTypeId: number().nullable(true),
  primaryPhoneNumber: string().test(
    "check phone number",
    "מספר הטלפון לא תקין",
    (value) => {
      return value ? checkIsraeliPhone(value) : true;
    }
  ),
  lockerPrice: number().typeError("יש להזין מספרים בלבד"),
  lockFee: number().typeError("יש להזין מספרים בלבד"),
  cityId: number().nullable(true),
  rentalRouteId: string().nullable(true),
  postalCode: string()
});

export const manageCompoundsValidation = object().shape({
  compoundNameId: number().typeError("שדה חובה").required("שדה חובה"),
  isActive: boolean().required("שדה חובה"),
  isLockedList: boolean().required("שדה חובה")
});
