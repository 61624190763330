import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";

import { useAppSelector, useAppDispatch } from "../../../store";
import { SitesActions } from "../../../store/entities/sites/sites.actions";
import { GeneralActions } from "../../../store/entities/general/general.actions";
import useTranslations from "../../../hooks/useTranslations";
import useGetGridData from "../../../hooks/useGetGridData";
import RcTable from "../../../components/UI/RcTable/RcTable";
import { GridColDef, GridRowsProp } from "@mui/x-data-grid";
import ActionButtons from "../../../components/UI/ActionButtons/ActionButtons";
import Title from "../../../components/UI/Title/Title";
import { IGridMode, ISortItem } from "../../../models/table";
import { getCorrectPhoneNumber } from "../../../utils/formatters";
import useUrlSearchParam from "../../../hooks/useUrlSearchParams";
import { FieldTypes, IFormFields } from "../../../models/form";
import Filters from "../../../components/Container/Filters/Filters";
import { sitesListSearchValidation } from "../../../utils/formValidation/sitesManagement";
import { ISite, ISubSiteType, ISiteType } from "../../../models/site";
import { ICity, IRentalRoute } from "../../../store/entities/general/type";
import {
  IAutoCompleteItem,
  IAutoCompleteValue,
} from "../../../models/autoComplete";
import StatusDot from "../../../components/UI/StatusDot/StatusDot";
import { CONSTANTS } from "../../../utils/constants";
import { PERMISSIONS } from "../../../utils/permissions";
import TableButtons from "../../../components/UI/TableButtons/TableButtons";

import "./ManageSites.scss";
import { IPage } from "../../../models/page";
import Dialog from "../../../components/UI/Dialog/Dialog";
import AutoComplete from "../../../components/Container/InputFields/AutoComplete/AutoComplete";
import Checkbox from "../../../components/UI/Checkbox/Checkbox";
import Alert from "../../../components/UI/Alert/Alert";
import UpdateStatusDialog from "../../Dialogs/UpdateStatusDialog";
import { TableActions } from "../../../store/entities/table/table.actions";
import { ILoggedUserData } from "../../../store/entities/auth/type";

const ManageSites = ({ permission, nextRoute }: IPage) => {
  const [rows, setRows] = useState<GridRowsProp>([]);
  const [siteTypesList, setSiteTypesList] = useState<IAutoCompleteItem[]>([]);
  const [subSiteTypesList, setSubSiteTypesList] = useState<IAutoCompleteItem[]>(
    []
  );
  const [selectedSiteTypeId, setSelectedSiteTypeId] = useState<number | null>(
    null
  );
  const [rentalRoutesList, setRentalRoutesList] = useState<IAutoCompleteItem[]>(
    []
  );
  const [cityList, setCityList] = useState<IAutoCompleteItem[]>([]);
  const [siteCodes, setSiteCodes] = useState<IAutoCompleteItem[]>([]);
  const [formikProps, setFormikProps] = useState<any>({});
  const [showDialogStatusOrder, setsShowDialogStatusOrder] =
    useState<boolean>(false);
  const [selectedStatus, setSelectedStatus] =
    useState<IAutoCompleteValue>(null);
  const [orderStatus, setOrderStatus] = useState<any>({});
  const [show, setShow] = useState<boolean>(false);
  const [showUpdateStatusDialog, setShowUpdateStatusDialog] =
    useState<boolean>(false);
  const [showUpdateStatusNote, setShowUpdateStatusNote] =
    useState<boolean>(false);
  const [updateStatusMessage, setUpdateStatusMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [selectedUpdateStatus, setSelectedUpdateStatus] =
    useState<IAutoCompleteValue>(null);

  const dispatch: any = useAppDispatch();
  const { rcTranslate } = useTranslations();
  const { onPageChange } = useGetGridData();
  const { getValueFromQuery, updateParams, params } = useUrlSearchParam();
  const navigate = useNavigate();

  const { queryParams, selectedList, nextPageNumber, currentPage } =
    useAppSelector((state) => state.table);
  const { sites, subSiteTypes, siteTypes, subSiteTypesById,siteListDetails } = useAppSelector(
    (state) => state.sites
  );
  const { appLanguage, cities, rentalRoutes } = useAppSelector(
    (state) => state.general
  );
  const {
    appPermissions,
    loggedUserData,
  }: { appPermissions: string[]; loggedUserData: ILoggedUserData } =
    useAppSelector((store) => store.auth);

  let showAlertTimeout: any;

  useEffect(() => {
    if (permission !== undefined && !permission) {
      navigate(nextRoute);
    }
  }, [permission, nextRoute, navigate]);

  useEffect(() => {
    if (permission) {
      if (currentPage !== 1) {
        dispatch(TableActions.setTableData({ currentPage: 1 }));
      }
      dispatch(SitesActions.getSites(params));
      dispatch(GeneralActions.getCities());
    }
  }, [dispatch, params, permission]);

  useEffect(() => {
    if (nextPageNumber) {
      const getData = async () => {
        await dispatch(
          SitesActions.getSites(undefined, undefined, false, nextPageNumber)
        );
        await dispatch(TableActions.setTableData({ nextPageNumber: null }));
      };
      getData();
    }
  }, [nextPageNumber]);

  useEffect(() => {
    if (permission) {
      dispatch(SitesActions.getSiteListDetails());
      dispatch(SitesActions.getSiteTypes());
      dispatch(SitesActions.getSubSiteTypes());
      dispatch(GeneralActions.getRentalRoutes());
    }
  }, [dispatch, permission]);

  // Remove sub site value if there is no site type selected
  useEffect(() => {
    if (
      !formikProps?.values?.siteTypeId &&
      formikProps?.values?.subSiteTypeId &&
      formikProps?.setFieldValue
    ) {
      formikProps?.setFieldValue("subSiteTypeId", null);
    }
  }, [formikProps]);

  const columns: GridColDef[] = [
    {
      field: "Id",
      headerName: rcTranslate("sites.filters.id"),
    },
    {
      field: "siteName",
      headerName: rcTranslate("sites.filters.name"),
    },
    {
      field: "siteTypeId",
      headerName: rcTranslate("sites.filters.siteTypeId"),
    },
    {
      field: "subSiteTypeId",
      headerName: rcTranslate("sites.filters.subSiteTypeId"),
    },
    {
      field: "cityId",
      headerName: rcTranslate("sites.filters.cityId"),
    },
    {
      field: "rentalRouteId",
      headerName: rcTranslate("sites.filters.rentalRouteId"),
    },
    {
      field: "primaryPhoneNumber",
      headerName: rcTranslate("sites.filters.primaryPhoneNumber"),
    },
    {
      field: "lockerPrice",
      headerName: rcTranslate("sites.filters.lockerPrice"),
    },
    {
      field: "lockFee",
      headerName: rcTranslate("sites.filters.lockFee"),
    },
    {
      field: "openForOrders",
      headerName: rcTranslate("sites.filters.openForOrders.label"),
    },
    {
      field: "actions",
      headerName: "",
      sortable: false,
      headerClassName: "hideColumnSeperator",
    },
  ];

  const navigateToSiteDetails = useCallback(
    (siteId: string, siteTypeId: number | undefined) => {
      navigate(
        "/" +
          CONSTANTS.ROUTES.SITES_MANAGEMENT.BASE +
          "/" +
          CONSTANTS.ROUTES.SITES_MANAGEMENT.CHILDREN.SITE_DETAILS +
          "/" +
          siteId,
        {
          state: {
            edit: true,
            siteTypeId: siteTypeId ?? null,
          },
        }
      );
    },
    [navigate]
  );
  const navigateToManageClosetWithSite = useCallback(
    (siteId: string, siteName: string) => {
      updateParams({ siteId });
      navigate(
        "/" +
          CONSTANTS.ROUTES.SITES_MANAGEMENT.BASE +
          "/" +
          CONSTANTS.ROUTES.SITES_MANAGEMENT.CHILDREN.CLOSETS_MANAGEMENT +
          "?siteId=" +
          siteId,
        { state: { siteName } }
      );
    },
    [navigate]
  );

  const navigateToManageSections = useCallback(
    (siteId: string, siteName: string) => {
      navigate(
        "/" +
          CONSTANTS.ROUTES.SITES_MANAGEMENT.BASE +
          "/" +
          CONSTANTS.ROUTES.SITES_MANAGEMENT.CHILDREN.COMPOUNDS_LIST +
          "/" +
          siteId,
        {
          state: {
            siteName,
          },
        }
      );
    },
    [navigate]
  );
  const navigateToManageOrders = useCallback(
    (siteId: string) => {
      updateParams({ siteId });

      navigate(
        "/" +
          CONSTANTS.ROUTES.ORDERS_MANAGEMENT.BASE +
          "/" +
          CONSTANTS.ROUTES.ORDERS_MANAGEMENT.CHILDREN.ORDERS_LIST +
          "/?siteId=" +
          siteId,
        {
          state: {
            updateSideBar: true,
            selectedSideBarTitle: "sidebar.ordersManagement.title",
            keepQuery: true,
          },
        }
      );
    },
    [navigate, updateParams]
  );
  const navigateToContantsAndAgents = useCallback(
    (siteId: string) => {
      // navigate(
      //   "/" +
      //     CONSTANTS.ROUTES.SITES_MANAGEMENT.BASE +
      //     "/" +
      //     CONSTANTS.ROUTES.SITES_MANAGEMENT.CHILDREN.site_DETAILS +
      //     "/" +
      //     siteId
      // );
    },
    [navigate]
  );

  useEffect(() => {
    if (siteTypes.length) {
      const newSiteTypes: IAutoCompleteItem[] = siteTypes.map((siteType) => ({
        id: siteType.id,
        label:
          appLanguage === "hebrew" ? siteType.nameHE : siteType.nameEN ?? "",
      }));

      setSiteTypesList(newSiteTypes);
    }
  }, [appLanguage, siteTypes]);

  useEffect(() => {
    if (cities.length) {
      let newCities: IAutoCompleteItem[] = cities.filter(
        (city: ICity) => city.isActive
      );

      newCities = cities.map((city: ICity) => ({
        id: city.id,
        label: appLanguage === "hebrew" ? city.nameHE : city.nameEN ?? "",
      }));

      setCityList(newCities);
    }
  }, [appLanguage, cities]);

  useEffect(() => {
    if (siteListDetails?.length && !siteCodes.length) {
      siteListDetails.sort((a, b) => (a.id > b.id ? 1 : -1));

      const newSitesCodes: IAutoCompleteItem[] = siteListDetails.map((site: ISite) => ({
        id: site.id,
        label: site.id?.toString(),
      }));

      setSiteCodes(newSitesCodes);
    }
  }, [appLanguage, siteCodes.length, siteListDetails]);

  useEffect(() => {
    if (rentalRoutes.length) {
      const newRentalRoutes: IAutoCompleteItem[] = rentalRoutes.map(
        (rentalRoute: IRentalRoute) => ({
          id: rentalRoute.id,
          label:
            appLanguage === "hebrew"
              ? rentalRoute.nameHE
              : rentalRoute.nameEN ?? "",
        })
      );

      setRentalRoutesList(newRentalRoutes);
    }
  }, [appLanguage, rentalRoutes]);

  // Every time site type is changed in the search select field, we need to get the correct sub site types
  useEffect(() => {
    if (
      (formikProps?.values && formikProps?.values?.siteTypeId) ||
      getValueFromQuery("siteTypeId", "number")
    ) {
      if (formikProps?.values?.siteTypeId === selectedSiteTypeId) return;

      dispatch(
        SitesActions.getSubSiteTypesById(
          formikProps?.values?.siteTypeId
            ? formikProps?.values?.siteTypeId?.toString()
            : getValueFromQuery("siteTypeId") ?? ""
        )
      );
    }
  }, [
    dispatch,
    formikProps?.values,
    formikProps?.values?.siteTypeId,
    getValueFromQuery,
    selectedSiteTypeId,
  ]);

  // Set sub site types list values
  useEffect(() => {
    if (!formikProps?.values?.siteTypeId) return;

    const newSubSiteTypes: IAutoCompleteItem[] = subSiteTypesById?.map(
      (subSiteType) => ({
        id: subSiteType.id,
        label:
          appLanguage === "hebrew"
            ? subSiteType.nameHE
            : subSiteType.nameEN ?? "",
      })
    );

    setSubSiteTypesList(newSubSiteTypes);

    setSelectedSiteTypeId(formikProps?.values?.siteTypeId);
  }, [
    appLanguage,
    formikProps?.values?.siteTypeId,
    getValueFromQuery,
    selectedSiteTypeId,
    subSiteTypesById,
  ]);

  useEffect(() => {
    if (!sites?.length) return setRows([]);

    const sitesRows = sites.map((site: ISite, index: number) => {
      const siteCity = () => {
        if (!site.cityId) return "";

        if (cities.length && site) {
          const foundCity = cities?.find(
            (city: ICity) => site.cityId === city.id
          );

          if (foundCity) {
            return appLanguage === "hebrew"
              ? foundCity.nameHE
              : foundCity.nameEN;
          }
        }

        return "";
      };

      const siteType = () => {
        if (!site.siteTypeId) return "";

        if (siteTypes.length && site) {
          const foundSiteType = siteTypes?.find(
            (item: ISiteType) => site.siteTypeId === item.id
          );

          if (foundSiteType) {
            return appLanguage === "hebrew"
              ? foundSiteType.nameHE
              : foundSiteType.nameEN ?? "";
          }
        }

        return "";
      };

      const subSiteType = () => {
        if (!site.subSiteTypeId) return "";

        if (subSiteTypes.length && site) {
          const foundSubSiteType = subSiteTypes?.find(
            (item: ISubSiteType) => site.subSiteTypeId === item.id
          );

          if (foundSubSiteType) {
            return appLanguage === "hebrew"
              ? foundSubSiteType.nameHE
              : foundSubSiteType.nameEN ?? "";
          }
        }

        return "";
      };

      const rentalRoute = () => {
        if (!site.rentalRouteId) return "";

        if (rentalRoutes.length && site) {
          const foundRentalRoute = rentalRoutes?.find(
            (item: IRentalRoute) => site.rentalRouteId === item.id
          );

          if (foundRentalRoute) {
            return appLanguage === "hebrew"
              ? foundRentalRoute.nameHE
              : foundRentalRoute.nameEN ?? "";
          }
        }

        return "";
      };

      const siteName =
        appLanguage === "hebrew" ? site.nameHE : site.nameEN ?? "";

      // On the siteObject we use "id" and "Id" on purpose, because "id" is removed from the table row automatically
      const siteObject = {
        id: index,
        status: (
          <StatusDot
            className="tableRow__statusDot"
            status={site.isActive ? "active" : "inactive"}
          />
        ),
        Id: site.id,
        siteName: siteName,
        siteTypeId: siteType(),
        subSiteTypeId: subSiteType(),
        cityId: siteCity(),
        rentalRouteId: rentalRoute(),
        primaryPhoneNumber: getCorrectPhoneNumber(site.primaryPhoneNumber),
        lockerPrice: site.lockerPrice,
        lockFee: site.lockFee,
        openForOrders: site.openForOrders ? <CheckIcon /> : "",
        actions: (
          <ActionButtons
            style={{ marginRight: "auto" }}
            hide={
              [
                PERMISSIONS.GET_SITE_DETAILS,
                PERMISSIONS.GET_COMPOUNDS_BY_SITE,
                PERMISSIONS.GET_ORDERS,
                PERMISSIONS.GET_CLOSETS,
                PERMISSIONS.UPDATE_SITE_OPEN_FOR_ORDERS,
              ].filter((v: string) => appPermissions.includes(v)).length === 0
            }
            menuItems={[
              {
                label: "sites.manageSites.rowActions.site_details",
                onClick: () =>
                  navigateToSiteDetails(site?.id?.toString(), site.siteTypeId),
                show: appPermissions.includes(PERMISSIONS.GET_SITE_DETAILS),
              },
              {
                label: "sites.manageSites.rowActions.manage_lockers",
                onClick: () =>
                  navigateToManageClosetWithSite(
                    site?.id?.toString(),
                    siteName
                  ),
                show: appPermissions.includes(PERMISSIONS.GET_CLOSETS),
              },
              {
                label: "sites.manageSites.rowActions.manage_sections",
                onClick: () =>
                  navigateToManageSections(site?.id?.toString(), siteName),
                show: appPermissions.includes(
                  PERMISSIONS.GET_COMPOUNDS_BY_SITE
                ),
              },
              {
                label: "sites.manageSites.rowActions.manage_orders",
                onClick: () => navigateToManageOrders(site?.id?.toString()),
                show: appPermissions.includes(PERMISSIONS.GET_ORDERS),
              },
              {
                label: "sites.manageSites.rowActions.contancts_and_agents",
                onClick: () =>
                  navigateToContantsAndAgents(site?.id?.toString()),
              },
              {
                label: "sites.manageSites.rowActions.open_close_to_orders",
                onClick: () =>
                  siteStatusForOrder(siteName, site.id, site?.openForOrders),
                show:
                  appPermissions.includes(
                    PERMISSIONS.UPDATE_SITE_OPEN_FOR_ORDERS
                  ) 
              },
            ]}
          />
        ),
      };

      return siteObject;
    });

    setRows(sitesRows);
  }, [
    appLanguage,
    cities,
    navigateToSiteDetails,
    navigateToManageClosetWithSite,
    navigateToManageSections,
    navigateToManageOrders,
    navigateToContantsAndAgents,
    sites,
    siteTypes,
    subSiteTypes,
    rentalRoutes,
    appPermissions,
  ]);

  const searchFields: IFormFields = {
    initialValues: {
      name: getValueFromQuery("name"),
      id: getValueFromQuery("id", "number"),
      siteName: getValueFromQuery("siteName"),
      cityId: getValueFromQuery("cityId", "number"),
      rentalRouteId: getValueFromQuery("rentalRouteId", "number"),
      primaryPhoneNumber: getValueFromQuery("primaryPhoneNumber"),
      lockerPrice: getValueFromQuery("lockerPrice"),
      lockFee: getValueFromQuery("lockFee"),
      openForOrders: getValueFromQuery("openForOrders"),
      isActive: getValueFromQuery("isActive"),
      siteTypeId: getValueFromQuery("siteTypeId", "number"),
      subSiteTypeId: getValueFromQuery("subSiteTypeId", "number"),
    },
    formData: [
      {
        type: FieldTypes.CHILDREN,
        id: "externalFieldsWrapper",
        fields: [
          {
            type: FieldTypes.AUTOCOMPLETE,
            id: "id",
            label: "sites.filters.id",
            items: siteCodes,
            props: { multiple: false },
          },
          {
            type: FieldTypes.TEXT,
            id: "siteName",
            label: "sites.filters.name",
          },
          {
            type: FieldTypes.AUTOCOMPLETE,
            id: "cityId",
            label: "sites.filters.cityId",
            items: cityList,
            props: { multiple: false },
          },
        ],
      },
      {
        type: FieldTypes.CHILDREN,
        id: "extendedFieldsWrapper",
        fields: [
          {
            type: FieldTypes.CHILDREN,
            id: "extendedFieldsContainer",
            fields: [
              {
                type: FieldTypes.CHILDREN,
                id: "extendedFields",
                className: "filters__extendedFields",
                fields: [
                  {
                    type: FieldTypes.AUTOCOMPLETE,
                    id: "rentalRouteId",
                    label: "sites.filters.rentalRouteId",
                    items: rentalRoutesList,
                    props: { multiple: false },
                  },
                  {
                    type: FieldTypes.TEL,
                    id: "primaryPhoneNumber",
                    label: "sites.filters.primaryPhoneNumber",
                    props: { inputProps: { maxLength: 10 } },
                  },
                  {
                    type: FieldTypes.TEXT,
                    id: "lockerPrice",
                    label: "sites.filters.lockerPrice",
                  },
                  {
                    type: FieldTypes.TEXT,
                    id: "lockFee",
                    label: "sites.filters.lockFee",
                  },
                  {
                    type: FieldTypes.SELECT,
                    id: "openForOrders",
                    label: "sites.filters.openForOrders.label",
                    items: [
                      {
                        key: "true",
                        value: "sites.filters.openForOrders.yes",
                      },
                      {
                        key: "false",
                        value: "sites.filters.openForOrders.no",
                      },
                    ],
                  },
                  {
                    type: FieldTypes.SELECT,
                    id: "isActive",
                    label: "sites.filters.isActive.label",
                    items: [
                      {
                        key: "true",
                        value: "sites.filters.isActive.yes",
                      },
                      {
                        key: "false",
                        value: "sites.filters.isActive.no",
                      },
                    ],
                  },
                  {
                    type: FieldTypes.AUTOCOMPLETE,
                    id: "siteTypeId",
                    label: "sites.filters.siteTypeId",
                    items: siteTypesList,
                    props: { multiple: false },
                  },
                  {
                    type: FieldTypes.AUTOCOMPLETE,
                    id: "subSiteTypeId",
                    label: "sites.filters.subSiteTypeId",
                    items: subSiteTypesList,
                    props: { multiple: false },
                  },
                ],
              },
              {
                type: FieldTypes.CHILDREN,
                id: "actionsContainer",
                className: "filters__extendedActions",
                fields: [
                  {
                    type: FieldTypes.BUTTON,
                    id: "closeExtendedSearch",
                    label: "searchFields.closeExtendedSearch",
                  },
                  {
                    type: FieldTypes.CHILDREN,
                    id: "submitButtonWrapper",
                    className: "filters__submitButtonWrapper",
                    fields: [
                      {
                        type: FieldTypes.SUBMIT_BUTTON,
                        id: "submitButton",
                        label: "",
                      },
                      {
                        type: FieldTypes.BUTTON,
                        id: "cleanSearch",
                        label: "searchFields.cleanSearch",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  };

  const getFormikProps = (values: any) => {
    setFormikProps(values);
  };

  const addSite = () => {
    navigate(
      "/" +
        CONSTANTS.ROUTES.SITES_MANAGEMENT.BASE +
        "/" +
        CONSTANTS.ROUTES.SITES_MANAGEMENT.CHILDREN.SITE_DETAILS
    );
  };

  const updateStatus = () => {
    if (selectedList?.length > 0) {
      setShowUpdateStatusNote(false);
      setShowUpdateStatusDialog(true);
      setSelectedUpdateStatus(null);
    } else {
      setShowUpdateStatusNote(true);
      setUpdateStatusMessage(rcTranslate("dialogUpdateStatus.note"));
    }
  };

  const generalButtons = [
    {
      title: "sites.manageSites.addSite",
      onClick: () => addSite(),
      show: appPermissions.includes(PERMISSIONS.ADD_SITE),
    },
  ];

  const tableButtons = [
    {
      title: "dialogUpdateStatus.updateStatus",
      onClick: () => updateStatus(),
      show: appPermissions.includes(PERMISSIONS.UPDATE_SITES_STATUS),
    },
  ];

  const handleSortChange = (model: ISortItem[]) => {
    model = model.map((i) =>
      i.Field === "Id"
        ? { ...i, Field: i.Field?.toLowerCase() }
        : i.Field === "siteName"
        ? { ...i, Field: appLanguage === "hebrew" ? "nameHE" : "nameEN" }
        : i
    );

    dispatch(SitesActions.getSites(queryParams, model));
  };

  const handleSelectChange = (value: IAutoCompleteValue) => {
    setSelectedStatus(value as number);
    value !== 1 && setOrderStatus({ ...orderStatus, sendNotification: false });
  };

  const siteStatusForOrder = (
    siteName: string,
    siteId: number,
    openForOrders: boolean
  ) => {
    setSelectedStatus(openForOrders ? 1 : -1);
    setsShowDialogStatusOrder(true);
    setOrderStatus({
      ...orderStatus,
      siteName,
      siteId,
      siteStatusBeforeChange: openForOrders,
    });
  };

  const renderSiteStatusForOrder = () => {
    return (
      <>
        <Title
          text={"sites.manageSites.dialog.subTitle"}
          parameters={[{ label: "siteName", value: orderStatus?.siteName }]}
          className="manageorders__wrapper"
        />

        <AutoComplete
          items={[
            {
              id: -1,
              label: "sites.manageSites.dialog.orderStatus.no",
            },
            {
              id: 1,
              label: "sites.manageSites.dialog.orderStatus.yes",
            },
          ]}
          multiple={false}
          onChange={handleSelectChange}
          label="sites.manageSites.dialog.orderStatus.label"
          id="StatusAutoComplete"
          value={selectedStatus}
        />
        {selectedStatus === 1 && !orderStatus.siteStatusBeforeChange && (
          <Checkbox
            label={rcTranslate("sites.manageSites.dialog.sendNotification")}
            value={orderStatus?.sendNotification}
            onChange={(value: any) => {
              setOrderStatus({ ...orderStatus, sendNotification: value });
            }}
            className="manageSites__checkBox"
          />
        )}
      </>
    );
  };

  const saveSiteStatusForOrder = async () => {
    const res: any = await dispatch(
      SitesActions.updateSiteOpenForOrder(orderStatus?.siteId, {
        openForOrders: selectedStatus === 1 ? true : false,
        sendNotification: orderStatus?.sendNotification ? true : false,
      })
    );

    if (res?.isSucceeded) {
      setShow(true);
      setSuccessMessage("sites.manageSites.dialog.success");

      await dispatch(SitesActions.getSites(queryParams));
      showAlertTimeout = setTimeout(() => {
        setShow(false);
        setOrderStatus({});
        setSelectedStatus(null);
      }, 2000);

      setsShowDialogStatusOrder(false);
    }
  };

  const saveUpdateStatus = async () => {
    const siteIds: number[] = selectedList.map((item: number) => {
      return sites?.find((site: ISite, index: number) => index === item)?.id;
    });

    const res: any = await dispatch(
      SitesActions.updateStatus({
        siteIds,
        IsActive: selectedUpdateStatus === 1 ? true : false,
      })
    );

    if (res?.isSucceeded) {
      setShow(true);
      setSuccessMessage("dialogUpdateStatus.updateSuccess");

      dispatch(TableActions.setSelectedList([]));
      dispatch(SitesActions.getSites(queryParams));

      showAlertTimeout = setTimeout(() => {
        setShow(false);
      }, 5000);

      setShowUpdateStatusDialog(false);
    }
  };

  return (
    <>
      <Title text="sites.manageSites.pageTitle" />

      <Filters
        fields={searchFields}
        filtersValidation={sitesListSearchValidation}
        getFormikProps={getFormikProps}
      />

      <TableButtons
        generalButtons={generalButtons}
        tableButtons={tableButtons}
      />

      {showUpdateStatusNote && (
        <p className="manageUsers__note">{updateStatusMessage}</p>
      )}

      <RcTable
        rows={rows}
        columns={columns}
        onPageChange={onPageChange}
        handleSortChange={handleSortChange}
        paginationMode={IGridMode.server}
        checkboxSelection={true}
        expandableRows={false}
        showDots
        tdWidths={[1, 1, 1, 1, 1, 1, 1, 1]}
      />

      {showDialogStatusOrder && (
        <Dialog
          className="manageSites__dialog"
          title={""}
          body={renderSiteStatusForOrder()}
          onClose={() => setsShowDialogStatusOrder(false)}
          onConfirm={saveSiteStatusForOrder}
          confirmTitle={"button.save"}
        />
      )}

      {showUpdateStatusDialog && (
        <UpdateStatusDialog
          selectedStatus={selectedUpdateStatus}
          setSelectedStatus={setSelectedUpdateStatus}
          saveUpdateStatus={saveUpdateStatus}
          setShowUpdateStatusDialog={setShowUpdateStatusDialog}
        />
      )}

      {show && <Alert message={successMessage} color="success" />}
    </>
  );
};
export default ManageSites;
