import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { useAppSelector } from "../store";
import { AuthActions } from "../store/entities/auth/auth.actions";

export const useRefreshToken = () => {
  const { isLoggedIn, loginDate, refreshToken } = useAppSelector(
    (store) => store.auth
  );
  const dispatch = useDispatch();

  const checkRefreshTokenExp = useCallback(async () => {
    if (isLoggedIn && (!loginDate || !refreshToken)) {
      return dispatch(AuthActions.logoutUser());
    }

    if (
      !isLoggedIn ||
      !loginDate ||
      !process.env.REACT_APP_REFRESH_TOKEN_EXP_IN_MINUTES ||
      !refreshToken
    )
      return;

    const minuteInMS = 60 * 1000;

    // We regenerate a new refresh token 1 minute before exp
    const refreshTokenExpInMS =
      parseInt(process.env.REACT_APP_REFRESH_TOKEN_EXP_IN_MINUTES) * minuteInMS;

    const loginDateInMS = new Date(loginDate).getTime();
    const futureDateInMS = loginDateInMS + refreshTokenExpInMS;

    const currentDateInMS = new Date().getTime();

    if (
      1 <= futureDateInMS - currentDateInMS &&
      futureDateInMS - currentDateInMS <= minuteInMS
    ) {
      dispatch(AuthActions.regenerateRefreshToken(refreshToken));
    }
  }, [isLoggedIn, loginDate, dispatch, refreshToken]);

  return { checkRefreshTokenExp };
};
