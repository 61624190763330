import { IApiError } from "../../../models/api";
import { ApiService } from "../../../services/api";
import { CONSTANTS } from "../../../utils/constants";
import { getErrorMessages } from "../../../utils/formatters";
import { generateAsyncActionNames } from "../../../utils/helpers";
import { AppDispatch } from "../../type";
import { TableActions } from "../table/table.actions";
import { GetWaitingListsPayload } from "./type";

const { get } = ApiService;

enum WaitingListsActionsEnum {
  GET_WAITING_LISTS = "GET_WAITING_LISTS",
}

export const WaitingListsActionsNames = {
  [WaitingListsActionsEnum.GET_WAITING_LISTS]: generateAsyncActionNames(
    WaitingListsActionsEnum.GET_WAITING_LISTS
  ),
};

const Actions = {
  [WaitingListsActionsEnum.GET_WAITING_LISTS]: {
    START: () => ({
      type: WaitingListsActionsNames.GET_WAITING_LISTS.START,
    }),
    FULFILLED: (data: GetWaitingListsPayload) => ({
      type: WaitingListsActionsNames.GET_WAITING_LISTS.FULFILLED,
      payload: data,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: WaitingListsActionsNames.GET_WAITING_LISTS.REJECTED,
      payload: error,
    }),
  },
};

const getWaitingLists =
  (
    params?: any,
    sort?: any,
    updateTable = true,
    pageNumber: number = CONSTANTS.GRIDS.FIRST_PAGE_NUMBER
  ) =>
  async (dispatch: AppDispatch, getState: any) => {
    const { pageSize } = getState()?.table;

    dispatch(Actions[WaitingListsActionsEnum.GET_WAITING_LISTS].START());

    const requestData = {
      params,
      headers: {
        "X-Pagination": JSON.stringify({
          PageNumber: pageNumber,
          PageSize: pageSize,
        }),
      },
    };

    // We add sorting only if there is a sort object passed to request
    if (sort && sort.length) {
      (requestData.headers as any)["X-Sorting"] = JSON.stringify({
        Sortings: sort,
      });
    }

    try {
      const { data }: any = await get(
        "WaitingList/GetWaitingLists",
        requestData
      );
      if (!data.isSucceeded) {
        throw Error(JSON.stringify(data.errors));
      }

      const waitingLists = data?.content?.waitingLists;

      dispatch(
        Actions[WaitingListsActionsEnum.GET_WAITING_LISTS].FULFILLED(
          waitingLists
        )
      );

      if (updateTable) {
        (dispatch as any)(
          TableActions.setTableData({
            totalRows:data?.content?.totalCount ,
            pageNumber: CONSTANTS.GRIDS.FIRST_PAGE_NUMBER,
          })
        );
      }
    } catch (error: any) {
      dispatch(
        Actions[WaitingListsActionsEnum.GET_WAITING_LISTS].REJECTED(
          getErrorMessages(error)
        )
      );
    }
  };

export const WaitingListsActions = {
  getWaitingLists,
};
