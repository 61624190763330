import { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useParams, useLocation, useNavigate } from "react-router-dom";

import Button from "../../../components/UI/Button/Button";
import Title from "../../../components/UI/Title/Title";
import AutoComplete from "../../../components/Container/InputFields/AutoComplete/AutoComplete";
import ActionButtons from "../../../components/UI/ActionButtons/ActionButtons";
import BackButton from "../../../components/UI/BackButton/BackButton";
import { FieldTypes, IFormFields } from "../../../models/form";
import { IListItem } from "../../../models/Input";
import { useAppSelector } from "../../../store";
import { GeneralActions } from "../../../store/entities/general/general.actions";
import { manageCompoundsValidation } from "../../../utils/formValidation/sitesManagement";
import Form from "../../../components/Container/Form/Form";
import { ICompound, ISiteCompound } from "../../../store/entities/general/type";
import Alert from "../../../components/UI/Alert/Alert";
import Dialog from "../../../components/UI/Dialog/Dialog";
import useTranslations from "../../../hooks/useTranslations";
import { GradesActions } from "../../../store/entities/grades/grades.actions";
import {
  IAutoCompleteValue,
  IAutoCompleteItem,
} from "../../../models/autoComplete";
import { ICompoundGrade } from "../../../store/entities/grades/type";
import { PERMISSIONS } from "../../../utils/permissions";

import "./ManageCompounds.scss";
import { IPage } from "../../../models/page";

interface ISelectedCompound extends ISiteCompound {
  compoundName: string;
}

const ManageCompounds = ({ permission, nextRoute }: IPage) => {
  const [formikProps, setFormikProps] = useState<any>({});
  const [siteCompoundList, setSiteCompoundList] = useState<ISiteCompound[]>([]);
  const [compoundNamesList, setCompoundNamesList] = useState<IListItem[]>([]);
  const [show, setShow] = useState<boolean>(false);
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [selectedCompound, setSelectedCompound] =
    useState<ISelectedCompound | null>(null);
  const [gradesList, setGradesList] = useState<IAutoCompleteItem[]>([]);
  const [selectedCompoundClasses, setSelectedCompoundClasses] = useState<
    number[]
  >([]);
  const [selectedCompoundId, setSelectedCompoundId] = useState<number | null>(
    null
  );

  const { compoundNames, siteCompounds, appLanguage } = useAppSelector(
    (store) => store.general
  );
  const { grades } = useAppSelector((store) => store.grades);
  const { appPermissions }: { appPermissions: string[] } = useAppSelector(
    (store) => store.auth
  );

  const dispatch = useDispatch();
  const location: any = useLocation();
  const navigate = useNavigate();
  const { rcTranslate } = useTranslations();
  const { siteId }: { siteId?: string } = useParams();
  const siteName = location?.state?.siteName ?? "";

  let showAlertTimeout: any;

  useEffect(() => {
    if (permission !== undefined && !permission) {
      navigate(nextRoute);
    }
  }, [permission, nextRoute, navigate]);

  useEffect(() => {
    if (siteId) {
      dispatch(GeneralActions.getCompoundNames());
      dispatch(GeneralActions.getCompoundBySite(siteId));
    }
  }, [dispatch, siteId]);

  useEffect(() => {
    if (
      siteCompounds?.length ||
      (!siteCompounds?.length && siteCompoundList?.length)
    ) {
      setSiteCompoundList(siteCompounds);
    }
  }, [siteCompounds, appLanguage]);

  useEffect(() => {
    if (compoundNames.length) {
      setCompoundNamesList(
        compoundNames.map((i: ICompound) => ({
          key: i.id,
          value: appLanguage === "hebrew" ? i.nameHE : i.nameEN ?? "",
        }))
      );
    }
  }, [compoundNames, appLanguage]);

  useEffect(() => {
    if (grades?.length) {
      const newGradesList = grades.map((i) => ({
        id: i.id,
        label: appLanguage === "hebrew" ? i.nameHE : i.nameEN ?? "",
      }));

      setGradesList(newGradesList);
    }
  }, [grades, appLanguage]);

  useEffect(() => {
    return () => {
      clearTimeout(showAlertTimeout);
      setShow(false);
      setShowDialog(false);
      setSuccessMessage("");
      setSelectedCompound(null);
      setSelectedCompoundClasses([]);
      setSiteCompoundList([]);
      setCompoundNamesList([]);
      setGradesList([]);
      dispatch(GeneralActions.setGeneralData({ siteCompounds: [] }));
    };
  }, [showAlertTimeout, dispatch]);

  const addCompound = () => {
    const newCompound: ISiteCompound = {
      compoundNameId: "",
      isActive: true,
      isLockedList: true,
    };

    setSiteCompoundList((prevState) => [newCompound, ...prevState]);
  };

  const getField = (item: ISiteCompound): IFormFields => {
    return {
      initialValues: {
        id: item?.id ?? null,
        compoundNameId: item?.compoundNameId,
        isActive: item.isActive,
        isLockedList: item.isLockedList,
      },
      formData: [
        {
          type: FieldTypes.SELECT,
          id: "compoundNameId",
          label: "sites.manageCompounds.fields.compoundName",
          items: compoundNamesList,
        },
        {
          type: FieldTypes.CHECK_BOX,
          id: "isLockedList",
          label: "sites.manageCompounds.fields.isLockedList",
        },
        {
          type: FieldTypes.CHECK_BOX,
          id: "isActive",
          label: "sites.manageCompounds.fields.isActive",
        },
      ],
    };
  };

  const mapCompoundClasses = async (item: any) => {
    await dispatch(GradesActions.getGrades());

    if (compoundNames.length) {
      const foundCompound = compoundNames.find(
        (i) => i.id === item.compoundNameId
      );

      if (foundCompound) {
        const compoundName =
          appLanguage === "hebrew"
            ? foundCompound.nameHE
            : foundCompound.nameEN ?? "";

        item = { ...item, compoundName };
      }
    }

    const res: any = await dispatch(GradesActions.getCompoundGrades(item.id));

    if (res.isSucceeded && res?.content?.grades) {
      const responseCompoundGrades: ICompoundGrade[] = res?.content?.grades;

      const compoundGradesIds = responseCompoundGrades.map((i) => i.id);

      if (grades.length) {
        const foundCompoundClasses = grades.filter((i) =>
          compoundGradesIds.includes(i.id)
        );

        if (foundCompoundClasses.length) {
          const foundCompoundClassesIds = foundCompoundClasses.map((i) => i.id);

          setSelectedCompoundClasses(foundCompoundClassesIds);
        }
      }
    }

    setSelectedCompound(item);
    setShowDialog(true);
  };

  const handleSelectChange = (value: IAutoCompleteValue) => {
    setSelectedCompoundClasses(value as number[]);
  };

  const renderAutoComplete = () => {
    return (
      <AutoComplete
        items={gradesList}
        onChange={handleSelectChange}
        label="sites.manageCompounds.autoCompleteLabel"
        id="compoundClassesAutoComplete"
        value={selectedCompoundClasses}
      />
    );
  };

  const closeDialog = () => {
    setShowDialog(false);
    setSelectedCompound(null);
    setSelectedCompoundClasses([]);
  };

  const saveCompoundClasses = async () => {
    if (!selectedCompound?.id) return;

    const res: any = await dispatch(
      GradesActions.updateCompoundGrades(selectedCompound?.id?.toString(), {
        gradeIds: selectedCompoundClasses,
      })
    );

    if (res?.isSucceeded) {
      setShow(true);
      setSuccessMessage("sites.manageCompounds.updateGradesSuccess");

      showAlertTimeout = setTimeout(() => {
        setShow(false);
        setSuccessMessage("");
      }, 5000);
    }

    setShowDialog(false);
    setSelectedCompound(null);
    setSelectedCompoundClasses([]);
  };

  const saveSiteCompound = async (
    values: any,
    setSubmitting: (value: boolean) => void
  ) => {
    let formValues = { ...values };

    let res: any;

    // If id exists - we update compound, else we add compound
    if (values.id) {
      let compoundId = values.id;
      delete formValues.id;

      if (siteId) {
        if (compoundId) {
          res = await dispatch(
            GeneralActions.updateCompound(compoundId.toString(), formValues)
          );

          if (res?.isSucceeded) {
            setSuccessMessage("sites.manageCompounds.updateSuccess");
          }
        }
      }
    } else {
      delete formValues.id;

      // This test should only be performed when adding a compound
      const existCompoundWithSelectedName = siteCompounds.find(
        (i) => i.compoundNameId === formValues?.compoundNameId
      );

      if (existCompoundWithSelectedName) {
        if (!formikProps?.setFieldError) return;

        return formikProps?.setFieldError(
          "compoundNameId",
          rcTranslate("sites.manageCompounds.alreadyTakenCompoundNameError")
        );
      }

      if (siteId) {
        res = await dispatch(GeneralActions.addCompound(siteId, formValues));
        if (res?.isSucceeded) {
          setSuccessMessage("sites.manageCompounds.addSuccess");
          if (siteId) {
            dispatch(GeneralActions.getCompoundBySite(siteId));
          }
        }
      }
    }

    setSubmitting(false);

    if (res?.isSucceeded) {
      setShow(true);

      showAlertTimeout = setTimeout(() => {
        setShow(false);
        setSuccessMessage("");
      }, 5000);
    }
  };

  const getFormikProps = useCallback((values: any) => {
    setFormikProps(values);
  }, []);

  const openDeleteCompoundDialog = (compoundId: number) => {
    setSelectedCompoundId(compoundId);
    setShowDelete(true);
  };

  const deleteCompound = async () => {
    setShowDelete(false);
    const res: any = await dispatch(
      GeneralActions.removeCompound((selectedCompoundId as number).toString())
    );

    if (res?.isSucceeded) {
      if (siteId) {
        dispatch(GeneralActions.getCompoundBySite(siteId));
      }
      setShow(true);
      setSuccessMessage("sites.manageCompounds.deleteCompoundSuccess");

      showAlertTimeout = setTimeout(() => {
        setShow(false);
        setSuccessMessage("");
      }, 5000);
    }
  };

  return (
    <>
      <BackButton />

      <Title
        text="sites.manageCompounds.pageTitle"
        parameters={[{ label: "siteName", value: siteName }]}
      />
      <div className="manageCompounds__wrapperButton">
        {appPermissions.includes(PERMISSIONS.ADD_COMPOUND) && (
          <Button
            className="manageCompounds__addCompoundButton"
            title="sites.manageCompounds.addCompound"
            onClick={addCompound}
          />
        )}
      </div>
      {siteCompoundList?.map((item: ISiteCompound, index: number) => (
        <div key={index} className="manageCompounds__compoundWrapper">
          <Form
            fields={getField(item)}
            formValidation={manageCompoundsValidation}
            onSubmit={saveSiteCompound}
            submitButtonTitle="button.save"
            formClassName="manageCompounds__form"
            submitButtonClassName="manageCompounds__submitButton"
            getFormikProps={getFormikProps}
            showSubmitButton={false}
            showSubmitIconButton={true}
            isDisabled={
              !appPermissions.includes(
                item?.id
                  ? PERMISSIONS.UPDATE_COMPOUND
                  : PERMISSIONS.ADD_COMPOUND
              )
            }
          />

          {item.id &&
          [PERMISSIONS.GET_GRADES, PERMISSIONS.REMOVE_COMPOUND].filter(
            (v: string) => appPermissions.includes(v)
          ).length > 0 ? (
            <ActionButtons
              menuItems={[
                {
                  label: "sites.manageCompounds.actions.classMapping",
                  onClick: () => mapCompoundClasses(item),
                  show:
                    [
                      PERMISSIONS.GET_GRADES,
                      PERMISSIONS.GET_GRADES_BY_COMPOUND,
                    ].filter((v: string) => appPermissions.includes(v))
                      .length === 2,
                },
                {
                  label: "sites.manageCompounds.actions.delete_compound",
                  onClick: () => item.id && openDeleteCompoundDialog(item.id),
                  show: appPermissions.includes(PERMISSIONS.REMOVE_COMPOUND),
                },
              ]}
            />
          ) : (
            <div className="manageCompounds__emptyActionsColumn" />
          )}
        </div>
      ))}

      {show && <Alert message={successMessage} color="success" />}

      {showDialog && selectedCompound && (
        <Dialog
          className="manageCompounds__dialog"
          title={`${rcTranslate("sites.manageCompounds.dialogTitle")} ${
            selectedCompound?.compoundName
          }`}
          body={renderAutoComplete()}
          onClose={closeDialog}
          onConfirm={saveCompoundClasses}
          confirmTitle={"button.save"}
          disabled={
            !appPermissions.includes(PERMISSIONS.UPDATE_COMPOUND_GRADES)
          }
        />
      )}

      {showDelete && (
        <Dialog
          onConfirm={deleteCompound}
          onClose={() => setShowDelete(false)}
          title="sites.manageCompounds.dialog.title"
          body="sites.manageCompounds.dialog.body"
          disabled={!appPermissions.includes(PERMISSIONS.REMOVE_COMPOUND)}
        />
      )}
    </>
  );
};

export default ManageCompounds;
