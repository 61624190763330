import * as React from "react";
import { useDispatch } from "react-redux";
import LogoutIcon from "@mui/icons-material/Logout";
import { IconButton } from "@mui/material";

import { AuthActions } from "../../../store/entities/auth/auth.actions";
import useTranslations from "../../../hooks/useTranslations";

import LockersLogo from "../../../assets/images/lockers_logo.png";
import classes from "./RcHeader.module.scss";

const RcHeader = () => {
  const { rcTranslate } = useTranslations();
  const dispatch = useDispatch();

  const logout = () => {
    dispatch(AuthActions.logoutUser());
  };

  return (
    <header
      id="header"
      className={classes.headerContent + " d-flex align-content-center d-print-none"}
    >
      <img className={classes.logo} src={LockersLogo} alt="lockerim logo" />

      <div
        className={
          classes.userDetailsWrapper +
          ` d-flex flex-end flex-row-reverse align-items-center flex-1`
        }
      >
        <IconButton onClick={logout}>
          <LogoutIcon className={classes.logout} />
        </IconButton>
      </div>
    </header>
  );
};

export default RcHeader;
