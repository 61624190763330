import { IApiError } from "../../../models/api";
import {
  IClosetWithSite,
  ISite,
  ISiteType,
  ISubSiteType,
  IUpdateClosetWithSite,
} from "../../../models/site";
import { ApiService } from "../../../services/api";
import { CONSTANTS } from "../../../utils/constants";
import { getErrorMessages } from "../../../utils/formatters";
import { generateAsyncActionNames } from "../../../utils/helpers";
import { AppDispatch } from "../../type";
import { TableActions } from "../table/table.actions";
import { GetLocksPayload, LocksState } from "./type";

const { get } = ApiService;

enum LocksActionsEnum {
  GET_LOCKS = "GET_LOCKS",
}

export const LocksActionsNames = {
  [LocksActionsEnum.GET_LOCKS]: generateAsyncActionNames(
    LocksActionsEnum.GET_LOCKS
  ),
};

const Actions = {
  [LocksActionsEnum.GET_LOCKS]: {
    START: () => ({
      type: LocksActionsNames.GET_LOCKS.START,
    }),
    FULFILLED: (data: GetLocksPayload) => ({
      type: LocksActionsNames.GET_LOCKS.FULFILLED,
      payload: data,
    }),
    REJECTED: (error: IApiError[] | null) => ({
      type: LocksActionsNames.GET_LOCKS.REJECTED,
      payload: error,
    }),
  },
};

const getLocks =
  (
    params?: any,
    sort?: any,
    updateTable = true,
    pageNumber: number = CONSTANTS.GRIDS.FIRST_PAGE_NUMBER
  ) =>
  async (dispatch: AppDispatch, getState: any) => {
    const { pageSize } = getState()?.table;

    dispatch(Actions[LocksActionsEnum.GET_LOCKS].START());

    const requestData = {
      params,
      headers: {
        "X-Pagination": JSON.stringify({
          PageNumber: pageNumber,
          PageSize: pageSize,
        }),
      },
    };

    // We add sorting only if there is a sort object passed to request
    if (sort && sort.length) {
      (requestData.headers as any)["X-Sorting"] = JSON.stringify({
        Sortings: sort,
      });
    }

    try {
      const { data }: any = await get("Lock/GetLocks", requestData);

      const locks = data?.content?.locks;

      dispatch(Actions[LocksActionsEnum.GET_LOCKS].FULFILLED(locks));

      if (updateTable) {
        (dispatch as any)(
          TableActions.setTableData({
            totalRows: data?.content?.totalCount,
            pageNumber: CONSTANTS.GRIDS.FIRST_PAGE_NUMBER,
          })
        );
      }
    } catch (error: any) {
      dispatch(
        Actions[LocksActionsEnum.GET_LOCKS].REJECTED(getErrorMessages(error))
      );
    }
  };

export const LocksActions = {
  getLocks,
};
