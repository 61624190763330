import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ConfigurationActions } from "../../../store/entities/configuration/configuration.actions";
import Alert from "../../../components/UI/Alert/Alert";
import Form from "../../../components/Container/Form/Form";
import { CONSTANTS } from "../../../utils/constants";
import { FieldTypes, IFormFields } from "../../../models/form";
import { ICloset, IClosetManagement } from "../../../models/closet";
import useTranslations from "../../../hooks/useTranslations";
import ActionButtons from "../../../components/UI/ActionButtons/ActionButtons";
import { closetManagementValidation } from "../../../utils/formValidation/closetManagement";

import "./Closet.scss";
import { useAppSelector } from "../../../store";
import { PERMISSIONS } from "../../../utils/permissions";

const Closet = (props: IClosetManagement) => {
  const { closetData, closets, setNewClosetId, newClosetId } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { rcTranslate } = useTranslations();

  const [formikProps, setFormikProps] = useState<any>({});
  const [show, setShow] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>("");

  const { selectedColumnId, selectedCellId } = useAppSelector(
    (state) => state.configuration
  );
  const { appPermissions }: { appPermissions: string[] } = useAppSelector(
    (state) => state.auth
  );

  let navigationTimeout: any;
  const edit = closetData?.id ? true : false;

  useEffect(() => {
    return () => {
      clearTimeout(navigationTimeout);
      setShow(false);
    };
  }, [navigationTimeout, dispatch]);

  const navigateToClosetDetails = useCallback(() => {
    navigate(
      "/" +
        CONSTANTS.ROUTES.CONFIGURATION.BASE +
        "/" +
        CONSTANTS.ROUTES.CONFIGURATION.CHILDREN.CLOSETS_MANAGEMENT +
        "/" +
        closetData?.id
    );
  }, [navigate, closetData]);

  const fields: IFormFields = {
    initialValues: {
      id: closetData?.id ?? "",
      title: closetData?.title ?? "",
      height: closetData?.height ?? "",
      width: closetData?.width ?? "",
      depth: closetData?.depth ?? "",
      setCount: closetData?.setCount ?? "",
      cellCount: closetData?.cellCount ?? "",
      isActive: closetData?.isActive ?? false
    },
    formData: [
      {
        type: FieldTypes.TEXT,
        id: "title",
        label: "configuration.closetConfiguration.fieldNames.title",
        props: { autoFocus: closetData?.id === newClosetId ? true : false }
      },
      {
        type: closetData?.height ? FieldTypes.FORM_CONTROL : FieldTypes.TEXT,
        id: "height",
        label: "configuration.closetConfiguration.fieldNames.height"
      },
      {
        type: closetData?.width ? FieldTypes.FORM_CONTROL : FieldTypes.TEXT,
        id: "width",
        label: "configuration.closetConfiguration.fieldNames.width",
      },
      {
        type: closetData?.depth ? FieldTypes.FORM_CONTROL : FieldTypes.TEXT,
        id: "depth",
        label: "configuration.closetConfiguration.fieldNames.depth"
      },
      {
        type: FieldTypes.FORM_CONTROL,
        id: "setCount",
        label: "configuration.closetConfiguration.fieldNames.totalColumns"
      },
      {
        type: FieldTypes.FORM_CONTROL,
        id: "cellCount",
        label: "configuration.closetConfiguration.fieldNames.totalCells"
      },
      {
        type: FieldTypes.CHECK_BOX,
        id: "isActive",
        label: "configuration.commonFieldNames.isActive"
      }
    ]
  };

  const getFormikProps = useCallback((values: any) => {
    setFormikProps(values);
  }, []);

  const resetData = () => {
    ////reset data
    selectedColumnId !== null &&
      dispatch(ConfigurationActions.updateSelectedColumnId(null));
    selectedCellId !== null &&
      dispatch(ConfigurationActions.updateSelectedCellId(null));
  };

  const updateClosetDetails = async (
    values: ICloset,
    setSubmitting: (value: boolean) => void
  ) => {
    let res: any;
    setSubmitting(false);
    const { setFieldError } = formikProps;

    let index = closets?.findIndex(
      (closet: ICloset) =>
        closet?.title === values?.title && closet?.id !== values?.id
    );
    if (index === -1) {
      if (edit) {
        const { title, isActive } = values;
        res = await dispatch(
          ConfigurationActions.updateCloset(values.id ?? "", {
            title,
            isActive
          })
        );
        setAlertMessage("configuration.closetConfiguration.editSuccess");
      } else {
        res = await dispatch(ConfigurationActions.addCloset(values));
        setNewClosetId(res?.content?.closetFormationId);
        setAlertMessage("configuration.closetConfiguration.addSuccess");
      }
    } else {
      setFieldError(
        "title",
        rcTranslate(
          "configuration.closetConfiguration.alreadyTakenClosetNameError"
        )
      );
    }

    if (res?.isSucceeded) {
      await dispatch(ConfigurationActions.getClosetsLists());
      setShow(true);
    }

    navigationTimeout = setTimeout(() => {
      setShow(false);
    }, 5000);
  };

  return (
    <>
      <div key={closetData?.id} className="manageCloset__compoundWrapper">
        <Form
          formClassName="manageCloset__form"
          fields={fields}
          formValidation={closetManagementValidation}
          onSubmit={updateClosetDetails}
          submitButtonTitle="button.save"
          submitButtonClassName="manageCloset__submitButton"
          showSubmitButton={false}
          showSubmitIconButton={true}
          getFormikProps={getFormikProps}
          isDisabled={
            !appPermissions.includes(
               PERMISSIONS.UPDATE_CLOSET_FORMATION 
            )
          }
        />
        <div className="manageCloset__actions">
          {closetData?.id && (
            <ActionButtons
              key={closetData?.id}
              hide={
                [PERMISSIONS.GET_CLOSET_FORMATION_DETAILS].filter((v: string) =>
                  appPermissions.includes(v)
                ).length === 0
              }
              menuItems={[
                {
                  label:
                    "configuration.closetConfiguration.fieldNames.actions.closetDetails",
                  onClick: () => {
                    navigateToClosetDetails();
                    resetData();
                  },
                  show: appPermissions.includes(PERMISSIONS.GET_CLOSET_FORMATION_DETAILS)
                }
              ]}
            />
          )}
        </div>
      </div>

      {show && <Alert message={rcTranslate(alertMessage)} color="success" />}
    </>
  );
};

export default Closet;
