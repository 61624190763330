import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import TableButtons from "../../../components/UI/TableButtons/TableButtons";
import SelectInput from "../../../components/Container/InputFields/SelectInput/SelectInput";
import useTranslations from "../../../hooks/useTranslations";
import { useAppSelector } from "../../../store";
import { GeneralActions } from "../../../store/entities/general/general.actions";
import { ConfigurationActions } from "../../../store/entities/configuration/configuration.actions";
import { ICity } from "../../../models/city";
import { IDynamicListOptionItem } from "../../../models/dynamic-lists";
import City from "./City";
import Title from "../../../components/UI/Title/Title";
import { CONSTANTS } from "../../../utils/constants";
import { IAutoCompleteItem } from "../../../models/autoComplete";
import { PERMISSIONS } from "../../../utils/permissions";

import "./City.scss";
import { IPage } from "../../../models/page";

const CitiesManagement = ({
  permission,
  nextRoute
}: IPage) => {
  const { rcTranslate } = useTranslations();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [citiesList, setCitiesList] = useState<ICity[]>([]);
  const [districtList, setDistrictList] = useState<IAutoCompleteItem[]>([]);
  const [newCityId, setNewCitytId] = useState<number>(-1);
  const [selectedSortValue, setSelectedSortValue] = useState<string>("");

  const { cities } = useAppSelector((state) => state.general);
  const { appLanguage } = useAppSelector((store) => store.general);
  const { dynamicListOptionsByKey } = useAppSelector(
    (state) => state.configuration
  );
  const { appPermissions }: { appPermissions: string[] } = useAppSelector(
    (store) => store.auth
  );

  useEffect(() => {
    if (permission !== undefined && !permission) {
      navigate(nextRoute);
    }
  }, [permission, nextRoute, navigate]);

  useEffect(() => {
    dispatch(GeneralActions.getCities());
  }, [dispatch]);

  useEffect(() => {
    cities?.length && setCitiesList(cities);
  }, [cities]);

  useEffect(() => {
    dispatch(
      ConfigurationActions.getDynamicListOptionsByKey(
        CONSTANTS.DYNAMIC_LIST.KEYS.DISTRICTS
      )
    );
  }, [dispatch]);

  useEffect(() => {
    if (dynamicListOptionsByKey.length) {
      const districts: IAutoCompleteItem[] = dynamicListOptionsByKey.map(
        (district: IDynamicListOptionItem) => ({
          id: district?.id,
          label:
            appLanguage === "hebrew" ? district.nameHE : district.nameEN ?? ""
        })
      );
      setDistrictList(districts);
    }
  }, [appLanguage, dynamicListOptionsByKey]);

  // Every time sort item is selected we sort cities by the selected item
  useEffect(() => {
    if (selectedSortValue) {
      let newCitiesList = [...citiesList];

      newCitiesList = newCitiesList.sort((a: any, b: any) => {
        const isBooleanValue = typeof a[selectedSortValue] === "boolean";

        if (selectedSortValue === "district") {
          const foundDistrictA = districtList.find(
            (i) => i.id === a?.districtId
          );
          const foundDistrictB = districtList.find(
            (i) => i.id === b?.districtId
          );

          if (foundDistrictA && foundDistrictB) {
            return foundDistrictA?.label < foundDistrictB?.label ? -1 : 1;
          }
        }

        return isBooleanValue
          ? b[selectedSortValue] - a[selectedSortValue]
          : a[selectedSortValue] < b[selectedSortValue]
          ? -1
          : 1;
      });

      setCitiesList(newCitiesList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSortValue]);

  const generalButtons = [
    {
      title: "configuration.cityConfiguration.addCity",
      onClick: () => addCity(),
      show: appPermissions.includes(PERMISSIONS.ADD_CITY)
    }
  ];

  const sortItems = [
    {
      key: "nameHE",
      value: "configuration.cityConfiguration.fieldNames.nameHE"
    },
    {
      key: "nameEN",
      value: "configuration.cityConfiguration.fieldNames.nameEN"
    },
    {
      key: "district",
      value: "configuration.cityConfiguration.fieldNames.district"
    },
    {
      key: "isActive",
      value: "configuration.cityConfiguration.fieldNames.isActive"
    },
    {
      key: "isPopular",
      value: "configuration.cityConfiguration.fieldNames.isPopular"
    }
  ];

  const addCity = () => {
    let cities = [...citiesList];
    cities.splice(0, 0, {
      id: "",
      nameHE: "",
      nameEN: "",
      districtId: "",
      isActive: false,
      isPopular: false
    });
    setCitiesList(cities);
  };

  return (
    <>
      <Title text={rcTranslate("configuration.cityConfiguration.pageTitle")} />

      <div className="manageCities__actionsWrapper">
        <SelectInput
          items={sortItems}
          value={selectedSortValue}
          onChange={(e) => setSelectedSortValue(e.target.value as any)}
          className="manageCities__sortInput"
          label={rcTranslate("configuration.cityConfiguration.sortCities")}
        />

        <TableButtons generalButtons={generalButtons} tableButtons={[]} />
      </div>

      {citiesList?.map((city: ICity, index: number) => (
        <City
          key={index}
          cityData={city}
          districtList={districtList}
          cities={cities}
          setNewCitytId={setNewCitytId}
          newCityId={newCityId}
        />
      ))}
    </>
  );
};
export default CitiesManagement;
