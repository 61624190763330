import { object, array, boolean, string, number, mixed } from "yup";
import { checkIsraeliPhone, emailRegex } from "../helpers";

export const updateUserPermissionsValidation = object().shape({
  permissionIds: array()
    .test("check if permissions are filled", function (value) {
      return !value || !value.length
        ? this.createError({
            message: "חובה לבחור אופציה אחת לפחות",
            path: "permissionIds"
          })
        : true;
    })
    .required("שדה חובה")
});

export const userDetailsValidation = object().shape({
  isActive: boolean().required("שדה חובה"),
  firstName: string().required("שדה חובה"),
  lastName: string().required("שדה חובה"),
  email: string().email("יש להזין מייל תקין").required("שדה חובה").matches(emailRegex,"יש להזין מייל תקין"),
  phoneNumber: string()
    .test("check phone number", "מספר הפלאפון לא תקין", (value) => {
      return value ? checkIsraeliPhone(value) : false;
    })
    .required("שדה חובה"),
  leadingRoleId: string().typeError("שדה חובה").required("שדה חובה"),
  identificationNumber: number()
    .typeError("יש להזין מספרים בלבד")
    .required("שדה חובה"),
  CityId: number().typeError("שדה חובה").required("שדה חובה"),
  IsAllSitesAuthorized: boolean().required("שדה חובה"),
  UserSites: array().required("שדה חובה")
});

export const manageUsersSearchValidation = object().shape({
  isActive: boolean(),
  firstName: string(),
  lastName: string(),
  email: string().email("יש להזין מייל תקין").matches(emailRegex,"יש להזין מייל תקין"),
  phoneNumber: string().test(
    "check phone number",
    "מספר הפלאפון לא תקין",
    (value) => {
      return value ? checkIsraeliPhone(value) : true;
    }
  ),
  leadingRoleId: string().nullable(true),
  identificationNumber: number().typeError("יש להזין מספרים בלבד"),
  cityId: number().nullable(true),
  isAllSitesAuthorized: boolean(),
  userSites: array(),
  fromCreatedDate: mixed().test(
    "check fromCreatedDate value",
    function (value) {
      const toCreatedDate = this.parent.toCreatedDate;

      if (!value || !toCreatedDate) return true;

      return new Date(value) > new Date(toCreatedDate)
        ? this.createError({
            message: "טווח התאריכים שגוי",
            path: "fromCreatedDate"
          })
        : true;
    }
  ),
  toCreatedDate: mixed().test("check toCreatedDate value", function (value) {
    const fromCreatedDate = this.parent.fromCreatedDate;

    if (!value) return true;

    if (fromCreatedDate) {
      return new Date(value) < new Date(fromCreatedDate)
        ? this.createError({
            message: "טווח התאריכים שגוי",
            path: "toCreatedDate"
          })
        : true;
    }

    return true;
  })
});
