import React from "react";

import useTranslations from "../../../hooks/useTranslations";
import { IButton } from "../../../models/button";

import "./Button.scss";

const Button = ({
  title,
  onClick = () => {},
  type = "button",
  className = "",
  disabled = false,
  isCancelButton = false,
  outlined = false
}: IButton) => {
  const { rcTranslate } = useTranslations();

  return (
    <button
      className={`buttonComponent btn btn-primary ${
        outlined ? "buttonComponent__outlined" : ""
      } ${className} ${!isCancelButton ? " btn-full" : ""}`}
      type={type}
      onClick={onClick}
      disabled={disabled}
    >
      {rcTranslate(title)}
    </button>
  );
};

export default Button;
