import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { DataGrid, GridSortModel, GridSelectionModel, GridRowHeightParams } from "@mui/x-data-grid";

import { useAppSelector } from "../../../store";
import { TableActions } from "../../../store/entities/table/table.actions";
import useTranslations from "../../../hooks/useTranslations";
import { ITable, IGridMode } from "../../../models/table";
import TableRow from "../TableRow/TableRow";
import Pagination from "../Pagination/Pagintaion";

import "./RcTable.scss";
import { useUIState } from "../../../hooks/useUIState";
import { CONSTANTS } from "../../../utils/constants";

const RcTable: React.FC<ITable> = React.memo((props) => {
  const [expanded, setExpanded] = useState<string | false>(false);

  const { totalRows, pageSize, sortModel, pageNumber, selectedList } =
    useAppSelector((state) => state.table);
  const dispatch = useDispatch();
  const { rcTranslate } = useTranslations();
  const { isLoading } = useUIState();

  const loading = props.loading || false;
  const sortingMode = props.sortingMode || IGridMode.client;
  const paginationMode = props.paginationMode || IGridMode.client;

  ////reset selected indexes
  useEffect(() => {
    return () => {
      dispatch(TableActions.setSelectedList([]));
      dispatch(
        TableActions.setTableData({
          expendedRowId: null,
        })
      );
    };
  }, [dispatch]);

  const onPageChangeHandler = (page: number) => {
    if (props.onPageChange) {
      props.onPageChange(page);
    }
  };

  const onSortModelChangeHandler = (model: GridSortModel) => {
    const formattedModel = model?.map((item: any) => {
      const { field, sort }: { field: string; sort: "asc" | "desc" } = item;

      return { Field: field, IsDescending: sort === "asc" ? false : true };
    });
    if (props.handleSortChange) {
      props.handleSortChange(formattedModel);
    }
    dispatch(TableActions.setTableData({ sortModel: model }));
  };

  const getPageRows = () => {
    const { rows } = props;

    if (rows?.length <= pageSize) {
      return rows;
    }

    const pageStartRowNumber = ((pageNumber as number) - 1) * pageSize;
    const pageEndRowNumber = (pageNumber as number) * pageSize;

    return rows?.slice(pageStartRowNumber, pageEndRowNumber);
  };

  if (isLoading) {
    return <div className="table__empty"></div>;
  }

  if (!props.rows || props.rows.length === 0) {
    return <div className="table__empty">{rcTranslate("table.noResults")}</div>;
  }

  return (
    <div
      className={`tableWrapper ${props.className ?? ""} ${
        props.checkboxSelection && props.showDots
          ? "tableWrapper__withCheckboxes__showDots"
          : props.checkboxSelection && props.expandableRows
          ? "tableWrapper__withCheckboxes__expandableRows"
          : props.checkboxSelection
          ? "tableWrapper__withCheckboxes"
          : props.showDots
          ? "tableWrapper__showDots"
          : ""
      }`}
    >
      <DataGrid
        rows={getPageRows()}
        sortingOrder={["desc", "asc"]}
        sx={{
          ".MuiDataGrid-footerContainer": {
            justifyContent: "center",
            direction: "rtl",
          },
          ".Mui-resizeTriggers, .Mui-resizeTriggers > div, .contract-trigger:before":
            {
              position: expanded ? "initial" : "absolute",
              overflow: "scroll",
            },
        }}
        autoHeight
        initialState={{
          columns: {
            columnVisibilityModel: props.columnVisibilityModel ?? {},
          },
        }}
        columns={props.columns}
        disableColumnMenu={true}
        sortingMode={sortingMode}
        loading={loading}
        pageSize={pageSize}
        components={{
          Pagination,
          Row: (rowData) => (
            <TableRow
              expandable={props.expandableRows ?? false}
              row={props.rows[rowData?.rowId]}
              expanded={expanded}
              setExpanded={setExpanded}
              rowsExpendedContent={props.rowsExpendedContent}
              checkboxSelection={props.checkboxSelection}
              showDots={props.showDots}
              selectionModel={selectedList}
              hideFooter={props?.hideFooter ?? false}
              tdWidths={props.tdWidths}
              {...rowData}
            />
          ),
        }}
        getRowId={(row) => row.id ?? Math.random()}
        rowCount={totalRows as number}
        paginationMode={paginationMode}
        sortModel={sortModel}
        onPageChange={(page) => onPageChangeHandler(page)}
        onSortModelChange={onSortModelChangeHandler}
        checkboxSelection={props.checkboxSelection}
        onSelectionModelChange={(newSelectionModel) => {
          dispatch(TableActions.setSelectedList(newSelectionModel));
        }}
        selectionModel={selectedList}
        hideFooter={props?.hideFooter ?? false}
      />
    </div>
  );
});

export default RcTable;
