import { getNewErrorsArray } from "../../../utils/formatters";
import { GeneralActionsNames } from "../general/general.actions";
import { OrdersActionsNames } from "./orders.actions";
import { OrdersState, OrdersAction } from "./type";

const initialState: OrdersState = {
  isLoading: false,
  error: null,
  orders: [],
  orderDetails: null,
  orderHistory: [],
  orderExtendedDetails: null
};

const usersReducer = (
  state: OrdersState = initialState,
  action: OrdersAction
): OrdersState => {
  switch (action.type) {
    case OrdersActionsNames.GET_ORDERS.START:
      return {
        ...state,
        error: null,
        isLoading: true,
        orders: []
      };
    case OrdersActionsNames.GET_ORDERS.FULFILLED:
      return {
        ...state,
        isLoading: false,
        orders: action.payload
      };
    case OrdersActionsNames.GET_ORDERS.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case OrdersActionsNames.GET_ORDER_HISTORY.START:
      return {
        ...state,
        error: null,
        isLoading: true
      };
    case OrdersActionsNames.GET_ORDER_HISTORY.FULFILLED:
      return {
        ...state,
        isLoading: false,
        orderHistory: action.payload
      };
    case OrdersActionsNames.GET_ORDER_HISTORY.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case OrdersActionsNames.GET_ORDER_EXTENDED_DETAILS.START:
      return {
        ...state,
        error: null,
        isLoading: true
      };
    case OrdersActionsNames.GET_ORDER_EXTENDED_DETAILS.FULFILLED:
      return {
        ...state,
        isLoading: false,
        orderExtendedDetails: action.payload
      };
    case OrdersActionsNames.GET_ORDER_EXTENDED_DETAILS.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };case OrdersActionsNames.SET_ORDERS_DATA.START:
      return {
        ...state,
        error: null,
        isLoading: true
      };
    case OrdersActionsNames.SET_ORDERS_DATA.FULFILLED:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    case OrdersActionsNames.SET_ORDERS_DATA.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
      case OrdersActionsNames.RELEASE_LOCKER.START:
      return {
        ...state,
        error: null,
        isLoading: true
      };
    case OrdersActionsNames.RELEASE_LOCKER.FULFILLED:
      return {
        ...state,
        isLoading: false,
      };
    case OrdersActionsNames.RELEASE_LOCKER.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
      case OrdersActionsNames.ORDER_CANCELLATION.START:
      return {
        ...state,
        error: null,
        isLoading: true
      };
    case OrdersActionsNames.ORDER_CANCELLATION.FULFILLED:
      return {
        ...state,
        isLoading: false,
      };
    case OrdersActionsNames.ORDER_CANCELLATION.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case GeneralActionsNames.CLEAR_ERRORS.FULFILLED:
      return {
        ...state,
        error: getNewErrorsArray(state.error, action.payload)
      };
  }
  return state;
};

export default usersReducer;
