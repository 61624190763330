import { IApiError } from "../models/api";
import { useAppSelector } from "../store";

export const useUIState = () => {
  const store = useAppSelector((store) => store) as any;

  const isLoadingArray = Object.keys(store).map(
    (item) => store[item].isLoading ?? false
  );

  const isLoading = isLoadingArray.some((item) => item === true);

  let errorsArray: (IApiError | null)[] = Object.keys(store).map(
    (item) => store[item].error ?? null
  );
  errorsArray = errorsArray.flat();
  errorsArray = errorsArray.filter((i) => i !== null);

  const errors = errorsArray.some((item) => !!item);

  return { isLoading, errors, errorsArray };
};
