import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Grid } from "@mui/material";
import { useAppSelector } from "../../../store";
import { ConfigurationActions } from "../../../store/entities/configuration/configuration.actions";
import { ColumnsToCloset } from "../../../models/closet";
import { CellToCloset } from "../../../store/entities/configuration/type";
import { ICell } from "../../../models/cell";

import './ClosetDisplay.scss'
import { ICosetDisplay } from "../../../models/closetDisplay";

const ClosetDisplay = (props: ICosetDisplay) => {
    const { closetId, closetDetails, columnToCloset } = props

    const { cellFormations } = useAppSelector((state) => state.configuration);
    const { selectedColumnId } = useAppSelector((state) => state.configuration);
    const { selectedCellId } = useAppSelector((state) => state.configuration);

    const dispatch = useDispatch();

    let closetHeight = Number(closetDetails?.height) / 3 + "px";
    let closetWidth = Number(closetDetails?.width) / 3 + "px"

    useEffect(() => {
        dispatch(ConfigurationActions.getCellsLists());
    }, [dispatch]);

    return (
        <Grid className="closetDisplay_wrapper" >

            <div >
                <div className="closetDisplay__widthValue"> {Number(closetDetails?.width ?? 0)} </div>
                <div style={{ width: closetWidth }} className="closetDisplay__widthCloset" />
            </div>

            <div className="closetDisplay__heightCloset" style={{ height: closetHeight }}>
                <div className="closetDisplay__heightValue"> {Number(closetDetails?.height ?? 0)}</div>
            </div>

            <Grid className="closetDisplay__wrapperCloset" style={{ height: closetHeight, width: closetWidth }}>
                {columnToCloset?.map((column: ColumnsToCloset, index: number) => {
                    return (
                        <div key={index + 1}>

                            <Grid key={index} style={{ display: "flex",
                                                flexDirection: "column-reverse"}}>

                                {column?.cells?.map((cell: CellToCloset, i: number) => {
                                    let currentCell = cellFormations?.find((item: ICell) => item?.id === cell?.cellFormationId)
                                    return (
                                        currentCell? <div key={i}
                                            className={selectedCellId?.columnNumber === cell?.setNumber &&
                                                selectedCellId?.rowNumber === cell?.rowNumber ? "closetDisplay__selectedCell" :
                                                !selectedCellId && selectedColumnId === column.setNumber ? "closetDisplay__selectedCell"
                                                    : "closetDisplay__cell"}
                                            style={{
                                                width: Number(currentCell?.width) / 3 + "px",
                                                height: Number(currentCell?.height) / 3 + "px"
                                            }}>
                                            {currentCell?.title+ "  "+cell?.position}
                                        </div>
                                        :""
                                    )
                                })}
                            </Grid>
                            <div>{index+1}</div>
                        </div>)
                })}
            </Grid>

        </Grid>
    );
};
export default ClosetDisplay;