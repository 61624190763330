import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { ConfigurationActions } from "../../../store/entities/configuration/configuration.actions";
import Alert from "../../../components/UI/Alert/Alert";
import Form from "../../../components/Container/Form/Form";
import { ICell, ICellManagement } from "../../../models/cell";
import { FieldTypes, IFormFields } from "../../../models/form";
import { cellDetailsValidation } from "../../../utils/formValidation/cellTemplateDetails";
import useTranslations from "../../../hooks/useTranslations";
import { useAppSelector } from "../../../store";
import { PERMISSIONS } from "../../../utils/permissions";


import "./Cell.scss";

const Cell = (props: ICellManagement) => {
    const { cellData, cellsLists, setNewCellId } = props

    const [formikProps, setFormikProps] = useState<any>({});
    const [show, setShow] = useState<boolean>(false);
    const [alertMessage, setAlertMessage] = useState<string>("");

    const { appPermissions }: { appPermissions: string[] } = useAppSelector(
        (store) => store.auth
    );

    const { rcTranslate } = useTranslations();
    const dispatch = useDispatch();

    const edit = cellData?.id ? true : false;
    let navigationTimeout: any;


    const fields: IFormFields = {
        initialValues: {
            id: cellData?.id ?? "",
            title: cellData?.title ?? "",
            height: cellData?.height ?? "",
            width: cellData?.width ?? "",
            isActive: cellData?.isActive ?? false,
        },
        formData: [
            {
                type: FieldTypes.TEXT,
                id: "title",
                label: "configuration.cellConfiguration.fieldNames.title",
            },
            {
                type: cellData?.height ? FieldTypes.FORM_CONTROL : FieldTypes.TEXT,
                id: "height",
                label: "configuration.commonFieldNames.height",
            },
            {
                type: cellData?.width ? FieldTypes.FORM_CONTROL : FieldTypes.TEXT,
                id: "width",
                label: "configuration.commonFieldNames.width",
            },
            {
                type: FieldTypes.CHECK_BOX,
                id: "isActive",
                label: "configuration.commonFieldNames.isActive",
            },
        ]
    };

    const getFormikProps = useCallback((values: any) => {
        setFormikProps(values);
    }, []);

    const updateCellDetails = async (values: ICell, setSubmitting: (value: boolean) => void) => {
        let sizeError: boolean = false, uniqueError: boolean = false
        const { setFieldError } = formikProps
        setSubmitting(false);
        let res: any
        cellsLists.forEach((cell: ICell) => {
            if (cell?.title === values?.title && cell?.id !== values?.id) {
                setFieldError("title", rcTranslate("configuration.cellConfiguration.alreadyTakenCellNameError"))
                uniqueError = true
            }
            if ((Number(cell?.width) === Number(values?.width) && Number(cell?.height) === Number(values?.height)) && cell?.id !== values?.id) {
                sizeError = true
                setFieldError("width", rcTranslate("configuration.cellConfiguration.alreadyTakenCellSizeError"))
                setFieldError("height", rcTranslate("configuration.cellConfiguration.alreadyTakenCellSizeError"))
            }
        });
        if (!uniqueError && !sizeError) {
            if (edit) {
                const { title, isActive } = values
                res = await dispatch(ConfigurationActions.updateCell(values?.id, { title, isActive }))
                setAlertMessage("configuration.cellConfiguration.editSuccess")
            }
            else {
                res = await dispatch(ConfigurationActions.addCell(values))
                setNewCellId(res?.content?.cellFormationId)
                setAlertMessage("configuration.cellConfiguration.addSuccess")
            }

            if (res?.isSucceeded) {
                setShow(true)
                await dispatch(ConfigurationActions.getCellsLists());
            }
        }

        navigationTimeout = setTimeout(() => {
            setShow(false);
        }, 5000);
    }


    return (
        <>
            <div className="manageCells__compoundWrapper">
                <Form
                    formClassName="manageCells__form"
                    fields={fields}
                    formValidation={cellDetailsValidation}
                    onSubmit={updateCellDetails}
                    submitButtonTitle="button.save"
                    showSubmitButton={false}
                    showSubmitIconButton={true}
                    submitButtonClassName="manageCells__submitButton"
                    getFormikProps={getFormikProps}
                    isDisabled={
                        !appPermissions.includes(
                            edit
                                ? PERMISSIONS.UPDATE_CELL_FORMATION
                                : PERMISSIONS.ADD_CELL_FORMATION
                        )
                    }

                />
            </div>
            {show && (
                <Alert
                    message={
                        rcTranslate(alertMessage)
                    }
                    color="success"
                />
            )}
        </>
    );
};
export default Cell;
