import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { CustomersActions } from "../../../store/entities/customers/customers.actions";
import { CONSTANTS } from "../../../utils/constants";
import { PERMISSIONS } from "../../../utils/permissions";
import { useAppSelector } from "../../../store";
import useTranslations from "../../../hooks/useTranslations";
import phoneExtensions from "../../../assets/data/phoneExtensions.json";
import { FieldTypes, IFormFields } from "../../../models/form";
import { customerDetailsValidation } from "../../../utils/formValidation/customerDetails";
import Form from "../../../components/Container/Form/Form";
import BackButton from "../../../components/UI/BackButton/BackButton";
import { getHebrewDate } from "../../../utils/formatters";
import Alert from "../../../components/UI/Alert/Alert";

import "./CustomerDetails.scss";
import { IPage } from "../../../models/page";

const CustomerDetails = ({ permission, nextRoute }: IPage) => {
  const [show, setShow] = useState<boolean>(false);
  const [formikProps, setFormikProps] = useState<any>({});
  const [mailSubscriptionDate, setMailSubscriptionDate] = useState<
    string | Date
  >("");

  const { customer } = useAppSelector((store) => store.customers);
  const { appPermissions }: { appPermissions: string[] } = useAppSelector(
    (store) => store.auth
  );

  const { rcTranslate } = useTranslations();
  const navigate = useNavigate();
  const { customerId = "" } = useParams();
  const dispatch = useDispatch();

  let navigationTimeout: any;

  useEffect(() => {
    if (permission !== undefined && !permission) {
      navigate(nextRoute);
    }
  }, [permission, nextRoute, navigate]);

  useEffect(() => {
    if (customerId) {
      dispatch(CustomersActions.getCustomerDetails(customerId));
    }
  }, [dispatch, customerId]);

  useEffect(() => {
    if (customer?.mailSubscriptionDate) {
      setMailSubscriptionDate(getHebrewDate(customer?.mailSubscriptionDate));
    }
  }, [customer?.mailSubscriptionDate]);

  useEffect(() => {
    return () => {
      clearTimeout(navigationTimeout);
      setShow(false);
      dispatch(CustomersActions.setCustomersData({ customer: null }));
    };
  }, [navigationTimeout, dispatch]);

  const fields: IFormFields = {
    initialValues: {
      firstName: customer?.firstName ?? "",
      lastName: customer?.lastName ?? "",
      identificationNumber: customer?.identificationNumber ?? "",
      phoneExt: customer?.phoneNumber ? customer?.phoneNumber?.slice(0, 3) : "",
      phoneNumber: customer?.phoneNumber
        ? customer?.phoneNumber?.slice(3, 10)
        : "",
      email: customer?.email ?? "",
      mailSubscription:
        customer?.mailSubscription !== undefined
          ? customer?.mailSubscription
          : false,
      created: customer?.created ?? "",
      mailSubscriptionDate: customer?.mailSubscriptionDate ?? "",
    },
    formData: [
      {
        type: FieldTypes.PLAIN_TEXT,
        id: "created",
        label:
          rcTranslate("customers.customerDetails.fieldNames.created") +
            " " +
            getHebrewDate(customer?.created as string) ?? "",
        className: "customerDetails__created",
      },
      {
        type: FieldTypes.TEXT,
        id: "firstName",
        label: rcTranslate("customers.customerDetails.fieldNames.firstName"),
      },
      {
        type: FieldTypes.TEXT,
        id: "lastName",
        label: rcTranslate("customers.customerDetails.fieldNames.lastName"),
      },
      {
        type: FieldTypes.TEXT,
        id: "identificationNumber",
        props: {
          inputProps: { maxLength: 9 },
          disabled: customer?.identificationNumber ? true : false,
        },
        label: rcTranslate("customers.customerDetails.fieldNames.idNum"),
      },
      {
        type: FieldTypes.CHILDREN,
        id: "phoneWrapper",
        fields: [
          {
            type: FieldTypes.TEL,
            id: "phoneNumber",
            label: rcTranslate(
              "customers.customerDetails.fieldNames.phoneNumber"
            ),
            props: { inputProps: { maxLength: 7 } },
            className: "customerDetails__phoneNumber",
          },
          {
            type: FieldTypes.SELECT,
            id: "phoneExt",
            label: "",
            className: "customerDetails__phoneExt",
            items: phoneExtensions,
          },
        ],
        className: "customerDetails__phoneWrapper",
      },
      {
        type: FieldTypes.TEXT,
        id: "email",
        label: rcTranslate("customers.customerDetails.fieldNames.email"),
      },
      {
        type: FieldTypes.SUBTITLE,
        id: "subtitle1",
        label: rcTranslate(
          "customers.customerDetails.subtitles.general_settings"
        ),
      },
      {
        type: FieldTypes.CHILDREN,
        id: "generalSettingsWrapper",
        fields: [
          {
            type: FieldTypes.SWITCH,
            id: "mailSubscription",
            label: rcTranslate(
              "customers.customerDetails.fieldNames.mailSubscription"
            ),
            className: "customerDetails__mailSubscriptionSwitch",
          },
          {
            type: FieldTypes.PLAIN_TEXT,
            id: "mailSubscriptionDate",
            label:
              mailSubscriptionDate &&
              rcTranslate(
                "customers.customerDetails.fieldNames.lastSavedDate"
              ) +
                " " +
                mailSubscriptionDate,
            className: "customerDetails__lastSavedDate",
          },
        ],
        className: "customerDetails__generalSettingsWrapper",
      },
    ],
  };

  useEffect(() => {
    if (customer?.mailSubscriptionDate) {
      setMailSubscriptionDate(getHebrewDate(customer?.mailSubscriptionDate));
    }
  }, [formikProps?.values?.mailSubscription]);

  const updateCustomerDetails = async (
    values: any,
    setSubmitting: (value: boolean) => void
  ) => {
    let formValues = { ...values };

    if (formValues?.email === "") {
      formValues.email = null;
    }

    formValues = {
      ...formValues,
      phoneNumber: formValues.phoneExt + formValues.phoneNumber,
    };

    delete formValues.phoneExt;

    if (
      mailSubscriptionDate &&
      formikProps?.values?.mailSubscription !== customer?.mailSubscription
    ) {
      setMailSubscriptionDate("");
    }

    const res: any = await dispatch(
      CustomersActions.updateCustomerDetails(customerId, formValues)
    );

    setSubmitting(false);

    if (res?.isSucceeded) {
      setShow(true);

      navigationTimeout = setTimeout(() => {
        navigate(
          "/" +
            CONSTANTS.ROUTES.CUSTOMERS.BASE +
            "/" +
            CONSTANTS.ROUTES.CUSTOMERS.CHILDREN.CUSTOMER_LIST
        );
      }, 5000);
    }
  };

  const getFormikProps = useCallback((values: any) => {
    setFormikProps(values);
  }, []);

  return (
    <>
      <BackButton />

      <Form
        fields={fields}
        formTitle={["customers.customerDetails.pageTitle"]}
        formValidation={customerDetailsValidation}
        onSubmit={updateCustomerDetails}
        submitButtonTitle="button.save"
        getFormikProps={getFormikProps}
        isDisabled={
          !appPermissions.includes(PERMISSIONS.UPDATE_CUSTOMER_DETAILS)
        }
      />

      {show && (
        <Alert
          message={rcTranslate("customers.customerDetails.updateSuccess")}
          color="success"
        />
      )}
    </>
  );
};

export default CustomerDetails;
