import { getNewErrorsArray } from "../../../utils/formatters";
import { AuthActionsNames } from "./auth.actions";
import { AuthAction, AuthState } from "./type";

const {
  token = null,
  refreshToken = null,
  loginDate = null
} = localStorage.getItem("lockerim_userData")
  ? JSON.parse(localStorage.getItem("lockerim_userData") as string)
  : {};

const appPermissions = localStorage.getItem("lockerim_appPermissions");
const loggedUserData = localStorage.getItem("lockerim_loggedUserData");

const initialState: AuthState = {
  isLoading: false,
  error: null,
  isLoggedIn: !!localStorage.getItem("lockerim_userData"),
  token: token,
  refreshToken: refreshToken,
  loginDate: loginDate,
  appPermissions: appPermissions ? JSON.parse(appPermissions) : [],
  loggedUserData: loggedUserData ? JSON.parse(loggedUserData) : []
};

const authReducer = (
  state: AuthState = initialState,
  action: AuthAction
): AuthState => {
  switch (action.type) {
    case AuthActionsNames.LOGIN.START:
    case AuthActionsNames.REGENERATE_REFRESH_TOKEN.START:
      return {
        ...state,
        error: null,
        isLoading: true
      };
    case AuthActionsNames.LOGIN.FULFILLED:
    case AuthActionsNames.REGENERATE_REFRESH_TOKEN.FULFILLED:
      // We also save current date to know when to refresh token
      const currentDate = new Date();

      localStorage.setItem(
        "lockerim_userData",
        JSON.stringify({ ...action.payload, loginDate: currentDate })
      );

      return {
        ...state,
        isLoading: false,
        refreshToken: action.payload.refreshToken,
        token: action.payload.token,
        loginDate: currentDate
      };
    case AuthActionsNames.LOGIN.REJECTED:
    case AuthActionsNames.REGENERATE_REFRESH_TOKEN.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case AuthActionsNames.LOGOUT.START:
      localStorage.removeItem("lockerim_userData");
      localStorage.removeItem("lockerim_appPermissions");
      localStorage.removeItem("lockerim_loggedUserData");
      localStorage.removeItem("lockerim_queryParams");

      return {
        ...state,
        error: null,
        token: null,
        refreshToken: null,
        loginDate: null,
        appPermissions: [],
        loggedUserData: null,
        isLoggedIn: false,
        isLoading: true
      };
    case AuthActionsNames.LOGOUT.FULFILLED:
      return {
        ...state,
        isLoading: false
      };
    case AuthActionsNames.LOGOUT.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case AuthActionsNames.GET_APP_PERMISSIONS_AFTER_LOGIN.START:
      return {
        ...state,
        error: null,
        isLoading: true
      };
    case AuthActionsNames.GET_APP_PERMISSIONS_AFTER_LOGIN.FULFILLED:
      localStorage.setItem(
        "lockerim_appPermissions",
        JSON.stringify(action.payload.appPermissions)
      );
      localStorage.setItem(
        "lockerim_loggedUserData",
        JSON.stringify(action.payload.loggedUserData)
      );

      return {
        ...state,
        ...action.payload,
        isLoading: false,
        isLoggedIn: true
      };
    case AuthActionsNames.GET_APP_PERMISSIONS_AFTER_LOGIN.REJECTED:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case AuthActionsNames.CHANGE_EXPIRED_PASSWORD.START:
      return {
        ...state,
        error: null,
        isLoading: true
      };
    case AuthActionsNames.CHANGE_EXPIRED_PASSWORD.FULFILLED:
      return {
        ...state,
        isLoading: false
      };
    case AuthActionsNames.CHANGE_EXPIRED_PASSWORD.REJECTED:
      return {
        ...state,
        isLoading: false
      };
    case AuthActionsNames.CLEAR_ERRORS.FULFILLED:
      return {
        ...state,
        error: getNewErrorsArray(state.error, action.payload)
      };
    case AuthActionsNames.FIRST_LOGIN.START:
      return {
        ...state,
        error: null,
        isLoading: true
      };
    case AuthActionsNames.FIRST_LOGIN.FULFILLED:
      return {
        ...state,
        isLoading: false
      };
    case AuthActionsNames.FIRST_LOGIN.REJECTED:
      return {
        ...state,
        isLoading: false
      };
    case AuthActionsNames.FORGOT_PASSWORD_LINK.START:
      return {
        ...state,
        error: null,
        isLoading: true
      };
    case AuthActionsNames.FORGOT_PASSWORD_LINK.FULFILLED:
      return {
        ...state,
        isLoading: false
      };
    case AuthActionsNames.FORGOT_PASSWORD_LINK.REJECTED:
      return {
        ...state,
        isLoading: false
      };
    case AuthActionsNames.CHANGE_PASSWORD.START:
      return {
        ...state,
        error: null,
        isLoading: true
      };
    case AuthActionsNames.CHANGE_PASSWORD.FULFILLED:
      return {
        ...state,
        isLoading: false
      };
    case AuthActionsNames.CHANGE_PASSWORD.REJECTED:
      return {
        ...state,
        isLoading: false
      };
    case AuthActionsNames.UPDATE_APP_PERMISSIONS.START:
      return {
        ...state,
        error: null,
        isLoading: true
      };
    case AuthActionsNames.UPDATE_APP_PERMISSIONS.FULFILLED:
      localStorage.setItem(
        "lockerim_appPermissions",
        JSON.stringify(action.payload)
      );

      return {
        ...state,
        appPermissions: action.payload,
        isLoading: false
      };
    case AuthActionsNames.UPDATE_APP_PERMISSIONS.REJECTED:
      return {
        ...state,
        isLoading: false
      };
  }
  return state;
};

export default authReducer;
