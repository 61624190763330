import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import useTranslations from "../../../hooks/useTranslations";
import Form from "../../../components/Container/Form/Form";
import Alert from "../../../components/UI/Alert/Alert";
import { GeneralActions } from "../../../store/entities/general/general.actions";
import { cityManagementValidation } from "../../../utils/formValidation/cityManagement";
import { ICity, ICityManagement } from "../../../models/city";
import { FieldTypes, IFormFields } from "../../../models/form";
import { useAppSelector } from "../../../store";
import { PERMISSIONS } from "../../../utils/permissions";


import './City.scss'
const City = (props: ICityManagement) => {
  const { cityData, districtList, cities, setNewCitytId, newCityId } = props
  const { rcTranslate } = useTranslations();
  const dispatch = useDispatch()

  const [formikProps, setFormikProps] = useState<any>({});
  const [show, setShow] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>("")

  const { appPermissions }: { appPermissions: string[] } = useAppSelector(
    (store) => store.auth
  );

  const edit = cityData?.id ? true : false;
  let navigationTimeout: any;

  const fields: IFormFields = {
    initialValues: {
      id: cityData?.id ?? "",
      nameHE: cityData?.nameHE ?? "",
      nameEN: cityData?.nameEN ?? "",
      districtId: cityData?.districtId,
      isActive: cityData?.isActive ?? false,
      isPopular: cityData?.isPopular ?? false
    },
    formData: [
      {
        type: FieldTypes.TEXT,
        id: "nameHE",
        label: "configuration.cityConfiguration.fieldNames.nameHE",
        props: { autoFocus: cityData?.id === newCityId ? true : false }

      },
      {
        type: FieldTypes.TEXT,
        id: "nameEN",
        label: "configuration.cityConfiguration.fieldNames.nameEN",
      },
      {
        type: FieldTypes.AUTOCOMPLETE,
        id: "districtId",
        label: "configuration.cityConfiguration.fieldNames.district",
        items: districtList,
        props: { multiple: false }

      },
      {
        type: FieldTypes.CHECK_BOX,
        id: "isActive",
        label: "configuration.cityConfiguration.fieldNames.isActive",

      },
      {
        type: FieldTypes.CHECK_BOX,
        id: "isPopular",
        label: "configuration.cityConfiguration.fieldNames.isPopular"
      },
    ]
  };

  const getFormikProps = useCallback((values: any) => {
    setFormikProps(values);
  }, []);

  const updateCityDetails = async (
    values: ICity,
    setSubmitting: (value: boolean) => void
  ) => {
    let res: any
    setSubmitting(false);
    const { setFieldError } = formikProps



    if (edit) {
      res = await dispatch(GeneralActions.updateCity(values.id ?? "", values))
      setAlertMessage("configuration.cityConfiguration.editSuccess")
    }
    else {
      let index = cities?.findIndex((city: ICity) => city?.nameEN === values?.nameEN || city?.nameHE === values?.nameHE)
      if (index === -1) {
        res = await dispatch(GeneralActions.addCity(values))
        setNewCitytId(res?.content?.cityId)
        setAlertMessage("configuration.cityConfiguration.addSuccess")
      }
      else {
        setFieldError(cities[index].nameEN === values?.nameEN && "nameEN", rcTranslate("configuration.cityConfiguration.alreadyTakenCityNameError"))
        setFieldError(cities[index].nameHE === values?.nameHE && "nameHE", rcTranslate("configuration.cityConfiguration.alreadyTakenCityNameError"))
      }
    }
    if (res?.isSucceeded) {
      dispatch(GeneralActions.getCities());
      setShow(true);
    }

    navigationTimeout = setTimeout(() => {
      setShow(false);
    }, 5000);
  };

  return (
    <>
      <div key={cityData?.id} className="manageCities__compoundWrapper">
        <Form
          formClassName="manageCities__form"
          fields={fields}
          formValidation={cityManagementValidation}
          onSubmit={updateCityDetails}
          submitButtonTitle="button.save"
          getFormikProps={getFormikProps}
          submitButtonClassName="manageCities__submitButton"
          showSubmitButton={false}
          showSubmitIconButton={true}
          isDisabled={
            !appPermissions.includes(
              edit ? PERMISSIONS.UPDATE_CITY : PERMISSIONS.ADD_CITY
            )
          }
        />
      </div>
      {show && (
        <Alert
          message={
            rcTranslate(alertMessage)
          }
          color="success"
        />
      )}
    </>
  );
};

export default City;
