import React, { ChangeEvent, SyntheticEvent, useEffect, useState } from "react";
import { Formik } from "formik";
import { SelectChangeEvent } from "@mui/material/Select";
import { ITreeViewItem } from "../../../models/treeView";
import { IListItem } from "../../../models/Input";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { IconButton } from "@mui/material";

import useTranslations from "../../../hooks/useTranslations";

import {
  IForm,
  IFormikProps,
  IFormField,
  IFormFieldWithChildren,
  FieldTypes
} from "../../../models/form";
import SelectInput from "../InputFields/SelectInput/SelectInput";
import TextInput from "../../UI/TextInput/TextInput";
import Button from "../../UI/Button/Button";

import "./Form.scss";
import Switch from "../../UI/Switch/Switch";
import DatePickerInput from "../InputFields/DatePickerInput/DatePickerInput";
import TreeView from "../../UI/TreeView/TreeView";
import Radio from "../../UI/Radio/Radio";
import { IRadioItem } from "../../../models/radio";
import AutoComplete from "../InputFields/AutoComplete/AutoComplete";
import { IAutoCompleteItem } from "../../../models/autoComplete";
import ActionButtons from "../../UI/ActionButtons/ActionButtons";
import { isEqual } from "lodash";
import Title from "../../UI/Title/Title";
import Checkbox from "../../UI/Checkbox/Checkbox";
import { FormControlLabel } from "@mui/material";

const Form = ({
  fields,
  formTitle,
  formValidation,
  onSubmit,
  onReset,
  submitButtonTitle,
  showCancelButton = false,
  showSubmitButton = true,
  showSubmitIconButton = false,
  cancelButtonTitle = "",
  isDisabled = false,
  submitButtonClassName = "",
  formClassName,
  getFormikProps
}: IForm) => {
  const { rcTranslate } = useTranslations();
  const [disable, setDisable] = useState<boolean>(true);




  const handleSubmit = (values: any, actions: any) => {
    onSubmit && onSubmit(values, actions.setSubmitting);
    actions.setSubmitting(false);
  };

  const handleReset = () => {
    onReset && onReset();
  };

  return (
    <Formik
      initialValues={fields.initialValues}
      validationSchema={formValidation}
      onSubmit={handleSubmit}
      enableReinitialize={true}
      onReset={handleReset}
    >
      {({
        values,
        errors,
        touched,
        isSubmitting,
        handleSubmit,
        handleReset,
        setFieldValue,
        setFieldError,
        setFieldTouched
      }: IFormikProps) => {
       
        const handleValueChange = (e: ChangeEvent<HTMLInputElement>) => {
          const { value, id } = e.target;
          setFormikProps(id, value);
        };
        const handleOnBlur = (e: ChangeEvent<HTMLInputElement>) => {
          const { value, id } = e.target;
          setFormikProps(id, value);
        };

        const setFormikProps = (id: string, value: any) => {
          setFieldTouched(id, true);
          setFieldValue(id, value);
         
          // Send formik props to children every time a field value is changed
          getFormikProps &&
            getFormikProps({
              values: { ...values, [id]: value },
              errors,
              touched,
              handleSubmit,
              setFieldError,
              setFieldValue,
              setFieldTouched
            });
        };

        const getFieldProps = (id: string, props: any) => {
          let otherProps: any = {};

          // if (id === "toCreatedDate") {
          //   otherProps.minDate = null;

          //   if (values?.fromCreatedDate) {
          //     otherProps.minDate = new Date(values?.fromCreatedDate);
          //   }

          //   // Disable "toCreatedDate" if "fromCreatedDate" has no value
          //   otherProps.disabled = false;

          //   if (!values?.fromCreatedDate) {
          //     otherProps.disabled = true;
          //   }
          // }


          if (id === "password") {
            otherProps.type = "password";
          }

          if (id === "compoundId") {
            // Disable "compoundId" if "siteId" has no value
            otherProps.disabled = false;

            if (!values?.siteId || props.disabled) {
              otherProps.disabled = true;
            }
          }

          if (id === "subSiteTypeId") {
            // Disable "subSiteTypeId" if "siteTypeId" has no value
            otherProps.disabled = false;

            if (!values?.siteTypeId) {
              otherProps.disabled = true;
            }
          }

          return { ...props, ...otherProps };
        };

        const renderField = (field: IFormField | IFormFieldWithChildren) => {
          if (field.type === FieldTypes.CHILDREN) {
            field = field as IFormFieldWithChildren;

            return (
              <div key={field.id} className={field.className}>
                {field.fields.map((item: IFormField | IFormFieldWithChildren) =>
                  renderField(item)
                )}
              </div>
            );
          }

          field.label = rcTranslate(field.label);

          if (field.type === FieldTypes.SUBTITLE) {
            return (
              <div
                key={field.id}
                className={`form__subtitle ${field.className ?? ""}`}
              >
                {field.label}
              </div>
            );
          }

          if (field.type === FieldTypes.SELECT) {
            return (
              <SelectInput
                key={field.id}
                id={field.id}
                items={field.items as IListItem[]}
                onChange={(e: SelectChangeEvent<any>) => {
                  setFormikProps(field.id, e.target.value);
                }}
                label={field.label}
                value={values[field.id] || ""}
                error={
                  errors[field.id] && touched[field.id]
                    ? errors[field.id]
                    : null
                }
                className={field.className}
                {...getFieldProps(field.id, field.props)}
              />
            );
          }

          if (field.type === FieldTypes.AUTOCOMPLETE) {
            return (
              <AutoComplete
                key={field.id}
                id={field.id}
                items={field.items as IAutoCompleteItem[]}
                onChange={(value: any) => {
                  setFormikProps(field.id, value);
                }}
                label={field.label}
                value={values[field.id] || ""}
                error={
                  errors[field.id] && touched[field.id]
                    ? errors[field.id]
                    : null
                }
                className={field.className}
                {...getFieldProps(field.id, field.props)}
              />
            );
          }

          if (field.type === FieldTypes.RADIO) {
            return (
              <Radio
                key={field.id}
                id={field.id}
                items={field.items as IRadioItem[]}
                onChange={(value) => {
                  setFormikProps(field.id, value);
                }}
                label={field.label}
                value={values[field.id] ?? ""}
                error={
                  errors[field.id] && touched[field.id]
                    ? errors[field.id]
                    : null
                }
                className={field.className}
              />
            );
          }

          if (field.type === FieldTypes.TREE_VIEW) {
            
            return (
              <TreeView
                key={field.id}
                id={field.id}
                items={field.items as ITreeViewItem[]}
                onChange={(value) => {
                  setFormikProps(field.id, value);
                }}
                label={field.label}
                value={values[field.id] || ""}
                error={
                  errors[field.id] && touched[field.id]
                    ? errors[field.id]
                    : null
                }
                checkableIds={field.checkableIds}
                className={field.className}
              />
            );
          }

          if (field.type === FieldTypes.SWITCH) {
            return (
              <Switch
                key={field.id}
                label={field.label}
                onChange={(value) => {
                  setFormikProps(field.id, value);
                }}
                className={field.className}
                value={values[field.id]}
              />
            );
          }

          if (field.type === FieldTypes.CHECK_BOX) {
            return (
              <Checkbox
                key={field.id}
                label={field?.label}
                value={values[field.id]}
                onChange={(value: any) => {
                  setFormikProps(field.id, value);
                }}
                className={field.className}
              />
            );
          }

          if (field.type === FieldTypes.MENU) {
            return (
              <ActionButtons
                key={field.id}
                menuItems={field?.props?.menuItems}
              />
            );
          }

          if (field.type === FieldTypes.BUTTON) {
            return (
              <button
                key={field.id}
                className={field.className ?? ""}
                onClick={field.onClick}
                type="button"
              >
                {field.label}
              </button>
            );
          }

          if (field.type === FieldTypes.SUBMIT_BUTTON) {
            return (
              <Button
                className={field.className ?? ""}
                title={submitButtonTitle}
                type="submit"
                disabled={isSubmitting}
                key={field.id}
              />
            );
          }

          if (field.type === FieldTypes.DATE) {
            return (
              <DatePickerInput
                id={field.id}
                key={field.id}
                label={field.label}
                onChange={(value: string) => {
                  setFormikProps(field.id, value);
                }}
                value={values[field.id] || ""}
                error={
                  errors[field.id] && touched[field.id]
                    ? errors[field.id]
                    : null
                }
                {...getFieldProps(field.id, field.props)}
              />
            );
          }

          if (field.type === FieldTypes.PLAIN_TEXT) {
            return (
              <div key={field.id} className={field.className}>
                {field.label}
              </div>
            );
          }

          if (field.type === FieldTypes.COMPONENT) {
            return field.component
              ? field.component({
                key: field.id,
                values,
                errors,
                touched,
                isSubmitting,
                handleSubmit,
                setFieldTouched,
                setFieldValue,
                setFieldError,
                className: field.className ?? ""
              } as IFormikProps)
              : null;
          }

          if (field.type === FieldTypes.FORM_CONTROL) {
            return <FormControlLabel 
              key={field.id}
              control={<div>&nbsp;&nbsp;{values[field.id]}</div>}
              label={rcTranslate(field.label ?? "")}
              labelPlacement="start"
            />
          }
          
          return (
            <TextInput
              key={field.id}
              id={field.id}
              label={field.label}
              multiline={field.type === FieldTypes.TEXTFIELD}
              onChange={handleValueChange}
              onBlur={(e: any) => {
                setFormikProps(field.id, e.target.value);
              }}
              type={field.type === FieldTypes.PASSWORD?"password":field.type}
              value={values[field.id] ?? ""}
              error={
                errors[field.id] && touched[field.id] ? errors[field.id] : null
              }
              autoFocus={field?.props?.autoFocus}
              className={field.className}
              {...getFieldProps(field.id, field.props)}
            />
          );
        };



        const onSubmitForm = (
          e: SyntheticEvent<HTMLFormElement>,
          handleSubmit: () => void
        ) => {
          e.preventDefault();

          handleSubmit();
        };

        return (
          <div className={`formWrapper ${formClassName ?? ""}`}>
            {formTitle && (
              <Title
                text={formTitle.length > 1 ? formTitle[0] : formTitle}
                parameters={formTitle.length > 1 ? formTitle[1] : null}
              />
            )}

            <form
              onSubmit={(e) => onSubmitForm(e, handleSubmit)}
              onReset={handleReset}
            >
              {fields.formData.map((field) => renderField(field))}

              {showSubmitButton && (
                <Button
                  className={`form__button form__submitButton ${submitButtonClassName}`}
                  title={submitButtonTitle}
                  type="submit"
                  disabled={isSubmitting || (isDisabled && disable)}
                  key="submit"
                />
              )}

              {showSubmitIconButton && (
                <IconButton
                  className={`${submitButtonClassName}`}
                  type="submit"
                  disabled={isSubmitting || (isDisabled && disable)}
                >
                  <SaveOutlinedIcon
                    fontSize="large"
                    sx={{
                      color: isDisabled ? "lightgray" : "#52555F"
                    }}
                  />
                </IconButton>
              )}

              {showCancelButton && (
                <Button
                  className="form__button form__cancelButton"
                  title={cancelButtonTitle}
                  type="reset"
                  isCancelButton={true}
                  key="reset"
                />
              )}
            </form>
          </div>
        );
      }
      }
    </Formik>
  );
};

export default Form;
