import { Navigate, Outlet, useLocation } from "react-router-dom";
import * as React from "react";
import {IRouteConfig} from "../../../models/route";

const GuardedRoute = (props: {routes: IRouteConfig[]}) => {
    const isAllowed: boolean = true; // check if have permission
    const location  = useLocation();

    const nextRoute = props.routes.find( (route) => route.path === location.pathname)?.nextRoute;
    return (isAllowed ? <Outlet/> :  <Navigate to={nextRoute || '/'}/>);
};
export default GuardedRoute;

